import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useTranslation } from '../../i18n';
import useLicenses from '../../hooks/licenses/useLicenses';
import useDashboard from '../../hooks/useDashboard/useDashboard';
import useSection from '../../hooks/useSection/useSection';
import useMaintenanceConfig from '../../hooks/useMaintenanceConfig/useMaintenanceConfig';
import ApplicationSections from '../../utils/sections/sections';
import { PAYMENT_METHODS } from '../../utils/constants/payments';
import { showPopUp } from '../../redux/actions/popUp.actions';
import { SESSION_STORAGE_KEYS } from '../../utils/constants/browserStorage';
import Spinner from '../Misc/Spinner';
import MobileMenu from '../organisms/MobileMenu/MobileMenu';
import ApplicationTopMenu from '../organisms/ApplicationTopMenu/ApplicationTopMenu';
import InvalidPaymentMethodBanner from '../organisms/InvalidPaymentMethodBanner/InvalidPaymentMethodBanner';
import ContactLegalInformation from '../molecules/ContactLegalInformation/ContactLegalInformation';
import { getLicensesByType } from '../../utils/functions/licenses';
import InfoBanner from '../molecules/InfoBanner/InfoBanner';

const Dashboard = () => {
  const dispatch = useDispatch();
  const client = useSelector((redux) => redux.client);
  const subscription = useSelector((redux) => redux.subscription);
  const user = useSelector((redux) => redux.user);
  const [mobileMenuOpened, setMobileMenu] = useState(false);
  const { lang } = useTranslation();
  const { maintenanceConfig } = useMaintenanceConfig();
  const maintenanceBannerConfig = maintenanceConfig.clientBanner;
  const { loading, saveSession, updateLastVisit } = useDashboard();
  const { section } = useSection();

  const { licenses, licensesSent, licensesUsed } = useLicenses();
  const { malwareProtectionLicenses, webProtectionLicenses } =
    getLicensesByType(licenses, licensesSent, licensesUsed);
  const {
    emailProtection = 0,
    malwareProtection = 0,
    webProtection = 0,
  } = licenses;

  useEffect(() => {
    if (window.FS) {
      window.FS.identify(client.id, {
        displayName: client?.company?.name || 'null',
      });
    }

    if (client) {
      saveSession();
      updateLastVisit();

      if (!client?.company?.website) {
        dispatch(showPopUp('welcomePopUp'));
      }
    }
  }, []);

  const userIsPartner = () => {
    const clientIdFromSessionStorage = sessionStorage.getItem(
      SESSION_STORAGE_KEYS.CLIENT_ID
    );
    if (client?.partner || clientIdFromSessionStorage) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    const metadata = {
      language: lang,
      name: `${user?.firstName} ${user?.lastName}`,
      email: user?.email,
      country: process.env.REACT_APP_ENVIRONMENT,
      userName: `${user?.firstName} ${user?.lastName}`,
      userEmail: user?.email,
      userEmailDomain: client?.atEmail,
      userDetails: userIsPartner() ? `partner: ${client.licensee}` : '-',
      companyName: client?.company?.name,
      companyCif: client?.company?.cif,
      companyWebsite: client?.company?.website,
      subscriptionType: subscription?.product,
      subscriptionStartDate:
        subscription?.startTime.toDate().toLocaleDateString('es-ES') || '-',
      subscriptionEndDate:
        subscription?.endTime.toDate().toLocaleDateString('es-ES') || '-',
      subscriptionLicenses: `Total licencias dispositivo:${malwareProtection} Total licencias navegación:${webProtection} Total licencias seguridad correo:${emailProtection}`,
      subscriptionSentLicenses: `Total licencias enviadas dispositivo:${malwareProtectionLicenses.sent} Total licencias enviadas navegación:${webProtectionLicenses.sent}`,
      subscriptionInstalledLicenses: `Total licencias instaladas dispositivo:${malwareProtectionLicenses.used} Total licencias instaladas navegación:${webProtectionLicenses.used}`,
      hash: 'f88628668a083c77dd68e5e43b05f1adf7bcce95e8ed381d3daf8c2f7dc15857',
    };

    if (window.Tawk_API && window.Tawk_API.onLoaded) {
      window.Tawk_API.setAttributes(metadata, (error) => {
        if (error) console.log('Tawk_API.setAttributes error', error);
      });
    }
  }, [client, subscription, lang]);

  const closeMenu = () => {
    if (mobileMenuOpened) setMobileMenu(false);
  };

  const hasSubNavbar = () =>
    [].concat(...Object.values(ApplicationSections)).includes(section) ||
    section === 'settings';

  const createSectionClassname = () => {
    let sectionClassName = 'section';
    if (mobileMenuOpened) {
      sectionClassName += ' dataObfuscation';
    }
    if (hasSubNavbar()) {
      sectionClassName += ' has_subnavbar';
    }
    return sectionClassName;
  };

  return (
    <>
      {loading && <Spinner loading={loading} />}
      <div className="clientDashboard">
        <ApplicationTopMenu
          mobileMenuOpened={mobileMenuOpened}
          setMobileMenu={setMobileMenu}
        />
        <MobileMenu
          mobileMenuOpened={mobileMenuOpened}
          setMobileMenu={setMobileMenu}
        />
        <div
          role="button"
          tabIndex="0"
          className={createSectionClassname()}
          onKeyDown={closeMenu}
          onClick={closeMenu}>
          <div className="dashboard-body-container">
            {client?.status.paymentMethod === PAYMENT_METHODS.INVALID && (
              <InvalidPaymentMethodBanner />
            )}

            {maintenanceBannerConfig?.enabled && (
              <InfoBanner
                type="warn"
                text={maintenanceBannerConfig?.paragraphs[lang]}
              />
            )}

            <Outlet />
          </div>

          <ContactLegalInformation
            color="transparent"
            size="section"
            separator
          />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
