import React from 'react';
import { faCartShopping } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SCBillingHistory from './BillingHistory.style';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import { useTranslation } from '../../../i18n';
import Label from '../../atoms/Label/Label';
import TableContainer from '../../molecules/TableContainer/TableContainer';
import InfoBanner from '../../molecules/InfoBanner/InfoBanner';
import { getDateFormat, getDateInMs } from '../../../utils/format/dataFormat';
import {
  PAYMENT_PERIODICITY,
  PAYMENT_REASONS,
  PAYMENT_STATUS,
} from '../../../utils/constants/payments';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import useFilterTable from '../../../hooks/useFilterTable/useFilterTable';
import usePayments from '../../../hooks/usePayments/usePayments';
import Tooltip from '../../atoms/Tooltip/Tooltip';

/**
 * Method that returns the concept to display in the table according to paymentReason and periodicity
 */
export const getPaymentConceptKey = ({ paymentReason, periodicity }) => {
  const translationConcepts = 'profile.billingHistory.paymentConcepts';

  switch (paymentReason) {
    case PAYMENT_REASONS.SUBSCRIPTION_UPDATE:
      return `${translationConcepts}.subscriptionUpdate`;
    case PAYMENT_REASONS.SUBSCRIPTION_RENEWAL:
      return periodicity === PAYMENT_PERIODICITY.YEARLY
        ? `${translationConcepts}.yearlyRenewal`
        : `${translationConcepts}.monthlyRenewal`;
    case PAYMENT_REASONS.SUBSCRIPTION:
      return periodicity === PAYMENT_PERIODICITY.YEARLY
        ? `${translationConcepts}.yearlySubscription`
        : `${translationConcepts}.monthlySubscription`;
    default:
      return '-';
  }
};

const BillingHistory = () => {
  const { payments } = usePayments();
  const i18n = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { currentLocale } = useInternationalization();

  const lastPaymentStatus = payments?.[0]?.status;

  const PAYMENT_STATUS_COLOR = {
    [PAYMENT_STATUS.SUCCESSFUL]: 'green',
    [PAYMENT_STATUS.FAILED]: 'red',
  };

  const COLUMNS = [
    {
      name: i18n.t('profile.billingHistory.concept'),
      options: {
        ellipsisContent: true,
        width: 130,
      },
    },
    {
      name: i18n.t('profile.billingHistory.startDate'),
    },
    {
      name: i18n.t('profile.billingHistory.malwareLicenses'),
    },
    {
      name: i18n.t('profile.billingHistory.webLicenses'),
    },
    {
      name: i18n.t('profile.billingHistory.mailboxLicenses'),
    },
    // {
    //   name: i18n.t('profile.billingHistory.amount'),
    // },
    {
      name: i18n.t('profile.billingHistory.state'),
    },
  ];

  const mapperFn = (payment) => {
    const { malwareProtection, webProtection, emailProtection } =
      payment.licenses ?? {};
    const paymentConcept = i18n.t(getPaymentConceptKey(payment));
    return {
      concept: {
        type: 'component',
        value: (
          <Tooltip tooltip={<p>{paymentConcept}</p>}>
            <p>{paymentConcept}</p>
          </Tooltip>
        ),
        sortValue: paymentConcept,
      },
      startDate: {
        type: 'string',
        value: payment?.createdAt
          ? getDateFormat(payment?.createdAt?.toDate())
          : '-',
        sortValue: getDateInMs(payment?.createdAt?.toDate()),
      },
      malwareLicenses: {
        type: 'component',
        value: <Label value={malwareProtection} width="100px" />,
        sortValue: malwareProtection,
      },
      webLicenses: {
        type: 'component',
        value: <Label value={webProtection} width="100px" />,
        sortValue: webProtection,
      },
      mailboxLicenses: {
        type: 'component',
        value: <Label value={emailProtection} width="100px" />,
        sortValue: emailProtection,
      },
      // amount: {
      //   type: 'component',
      //   value: (
      //     <Label
      //       value={getCurrencyFormat(
      //         payment.subtotal,
      //         undefined,
      //         currentLocale
      //       )}
      //     />
      //   ),
      //   sortValue: payment.subtotal,
      // },
      state: {
        type: 'component',
        value: (
          <Label
            value={i18n.t(
              `profile.billingHistory.paymentStatus.${payment.status}`
            )}
            color={PAYMENT_STATUS_COLOR[payment.status]}
          />
        ),
        sortValue: i18n.t(
          `profile.billingHistory.paymentStatus.${payment.status}`
        ),
      },
      onClick:
        payment.status === PAYMENT_STATUS.SUCCESSFUL
          ? () => {
              dispatch(showPopUp('paymentDetails', payment));
            }
          : undefined,
    };
  };

  const handleErrorPaymentOnClick = () => {
    navigate('/client/settings/my-account', {
      state: { targetId: 'payment-method-card' },
    });
  };

  const PAGE_SIZE = 10;

  const sortOptions = {
    activeSortedColumnName: i18n.t('profile.billingHistory.concept'),
    activeSortedColumnDirection: undefined,
    activeSortedColumnIndex: 0,
    onSort: (data) => {
      setSort(data);
    },
  };

  const { setSort, rows, length } = useFilterTable(
    PAGE_SIZE,
    mapperFn,
    payments,
    undefined,
    sortOptions
  );

  return (
    <SCBillingHistory>
      <SectionCard
        headerTitle={i18n.t('profile.billingHistory.title')}
        headerIcon={faCartShopping}>
        <div className="billing-history-container">
          {lastPaymentStatus === PAYMENT_STATUS.FAILED && (
            <InfoBanner
              type="error"
              text={[i18n.t('profile.billingHistory.lastPaymentError')]}
              onClickButton={handleErrorPaymentOnClick}
              buttonText={i18n.t('profile.billingHistory.updatePaymentMethod')}
            />
          )}

          <TableContainer
            emptyMessage={i18n.t('profile.billingHistory.emptyMessage')}
            columns={COLUMNS}
            rows={rows}
            autoWidth={false}
            hasRowIcon
            pageSize={PAGE_SIZE}
            totalCount={length}
            sortOptions={sortOptions}
          />
        </div>
      </SectionCard>
    </SCBillingHistory>
  );
};

export default BillingHistory;
