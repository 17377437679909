import { faWrench } from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  faCircle1,
  faCircle2,
  faCopy,
  faCheck,
  faArrowUpRightFromSquare,
  faMailbox,
  faRadiation,
} from '@fortawesome/pro-solid-svg-icons';
import axios from 'axios';
import { useTranslation } from '../../i18n';
import SectionLayout from '../../components/layouts/SectionLayout/SectionLayout';
import SCMailboxSecuritySection from './MailboxSecuritySection.style';
import { showPopUp } from '../../redux/actions/popUp.actions';
import Tool4StepsTutorial from '../../components/organisms/Tool4StepsTutorial/Tool4StepsTutorial';
import InfoBanner from '../../components/molecules/InfoBanner/InfoBanner';
import SectionCard from '../../components/molecules/SectionCard/SectionCard';
import Icon from '../../components/atoms/Icon/Icon';
import Button from '../../components/atoms/Button/Button';
import { toBase64 } from '../../utils/functions/base64';
import AnyDoubts from '../../components/molecules/AnyDoubts/AnyDoubts';
import SectionHeader from '../../components/molecules/SectionHeader/SectionHeader';
import SpinnerText from '../../components/atoms/SpinnerText/SpinnerText';
import { hasEmailProtection } from '../../utils/functions/emailProtection';
import QuarantineEmails from '../../components/organisms/QuarantineEmails/QuarantineEmails';
import useInitEmailProtection from '../../hooks/useQuarantineEmails/useQuarantineEmails';
import KPICard from '../../components/molecules/KPICard/KPICard';
import useLicenses from '../../hooks/licenses/useLicenses';
import useManagedThreatCounters, {
  COUNTER_TYPES,
} from '../../hooks/useManagedThreatCounters/useManagedThreatCounters';
import MailboxExtendLicensesWarning from '../../components/molecules/MailboxExtendLicensesWarning/MailboxExtendLicensesWarning';

const MailboxSecuritySection = () => {
  const client = useSelector((redux) => redux.client);
  const emailProtectionTutorial = useSelector(
    (redux) => redux.emailProtectionTutorial
  );
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const {
    loading: loadingQuarantineEmails,
    init,
    areMXRecordsInitialized,
  } = useInitEmailProtection();
  const { licenses } = useLicenses();
  const { counters } = useManagedThreatCounters();
  const [stepMxRecords, setStepMxRecords] = useState(1);
  const [mxRecords, setMxRecords] = useState({
    areYouResponsible: undefined,
    areYouAbleToSetDNS: undefined,
  });
  const [isWarnDomain, setIsWarnDomain] = useState();
  const [isCopy, setIsCopy] = useState(false);

  const quarantineEmailsAvailable = hasEmailProtection();
  const protectedMailboxes = client?.status?.protectedMailboxes?.quantity ?? 0;
  const totalMailboxes = licenses?.emailProtection ?? 0;

  useEffect(async () => {
    if (
      client &&
      (!client.finishedTutorials ||
        (client.finishedTutorials && !client.finishedTutorials.mailboxSecurity))
    ) {
      dispatch(showPopUp('tutorial', 'mailboxSecurity'));
    }

    axios
      .get(`/warn-email-providers/${toBase64(client.atEmail)}`)
      .then((res) =>
        setIsWarnDomain(res.data.code === 'auth/domain-warnlisted')
      )
      .catch((error) => {
        console.log(error);
        console.log(error.response);
        console.log(error.data);
      });
  }, []);

  const onClickOnCopy = (e) => {
    e.preventDefault();
    setIsCopy(!isCopy);
    navigator.clipboard.writeText(client?.domainVerificationToken);
  };

  const handleButtonNext = (e) => {
    e.preventDefault();
    if (stepMxRecords > 0) {
      if (
        mxRecords &&
        (!mxRecords.areYouResponsible || !mxRecords.areYouAbleToSetDNS)
      ) {
        dispatch(
          showPopUp('SentEmailTo', {
            type: 'mailboxSecurity',
          })
        );
      } else {
        setStepMxRecords(stepMxRecords + 1);
      }
    }
  };

  const handleButtonPrevious = (e) => {
    e.preventDefault();
    if (stepMxRecords > 1) {
      setStepMxRecords(stepMxRecords - 1);
    }
  };

  return (
    <SectionLayout>
      <SCMailboxSecuritySection>
        <div className="section-organism">
          <SectionHeader />
        </div>

        <div className="section-organism">
          <Tool4StepsTutorial />
        </div>

        {isWarnDomain && (
          <div className="section-organism">
            <InfoBanner
              type="warn"
              text={[i18n.t('mailbox-security.warnEmailText')]}
            />
          </div>
        )}

        <div className="section-organism multi-column">
          <KPICard
            value={protectedMailboxes}
            total={totalMailboxes}
            title={i18n.t('controlPanel.mailboxProtection.protectedMailboxes')}
            icon={faMailbox}
            lastUpdateAt={client?.status?.protectedMailboxes?.updatedAt?.toDate()}
            {...(protectedMailboxes > totalMailboxes && {
              extraContent: <MailboxExtendLicensesWarning />,
            })}
          />
          <KPICard
            value={
              counters?.[COUNTER_TYPES.EMAIL_PROTECTION_EMAILS_IN_QUARANTINE]
            }
            title={i18n.t(
              'controlPanel.mailboxSecurity.quarantinedEmailsLast30Days'
            )}
            icon={faRadiation}
            lastUpdateAt={client?.emailLastUpdatedAt?.toDate()}
          />
        </div>

        {isWarnDomain === false && (
          <>
            {!quarantineEmailsAvailable ? (
              <>
                {!client?.protection?.emailInitializedAt &&
                !areMXRecordsInitialized ? (
                  <div className="section-organism">
                    <InfoBanner
                      type="warn"
                      text={[
                        i18n.t('mailbox-security.activateMailboxSecurityText'),
                      ]}
                      onClickButton={init}
                      buttonText={
                        loadingQuarantineEmails ? (
                          <SpinnerText text={i18n.t('common.loading')} />
                        ) : (
                          i18n.t(
                            'mailbox-security.activateMailboxSecurityButton'
                          )
                        )
                      }
                      buttonDisabled={loadingQuarantineEmails}
                    />
                  </div>
                ) : (
                  <div className="section-organism tutorial-main-container">
                    <SectionCard
                      headerIcon={faWrench}
                      headerTitle={i18n.t(
                        'mailbox-security.configureEmailMxRecords.title'
                      )}>
                      {stepMxRecords === 1 && (
                        <div className="step-1-container">
                          <b>
                            {i18n.t(
                              'mailbox-security.configureEmailMxRecords.step1'
                            )}
                          </b>

                          <div className="questions">
                            <div className="question-container">
                              <div className="question">
                                <Icon iconName={faCircle1} size="medium" />
                                <p>
                                  {i18n.t(
                                    'mailbox-security.configureEmailMxRecords.areYouResponsible'
                                  )}
                                </p>
                              </div>

                              <div className="question-actions">
                                <Button
                                  text={i18n.t(
                                    'mailbox-security.configureEmailMxRecords.yes'
                                  )}
                                  onClick={() => {
                                    setMxRecords({
                                      ...mxRecords,
                                      areYouResponsible: true,
                                    });
                                  }}
                                  color={
                                    mxRecords.areYouResponsible === true
                                      ? 'bluishGrey'
                                      : 'white'
                                  }
                                  size="small"
                                />

                                <Button
                                  text={i18n.t(
                                    'mailbox-security.configureEmailMxRecords.no'
                                  )}
                                  onClick={() =>
                                    setMxRecords({
                                      ...mxRecords,
                                      areYouResponsible: false,
                                    })
                                  }
                                  color={
                                    mxRecords.areYouResponsible === false
                                      ? 'bluishGrey'
                                      : 'white'
                                  }
                                  size="small"
                                />
                              </div>
                            </div>

                            <div className="question-container">
                              <div className="question">
                                <Icon iconName={faCircle2} size="medium" />
                                <p>
                                  {i18n.t(
                                    'mailbox-security.configureEmailMxRecords.areYouAbleToSetDNS'
                                  )}
                                </p>
                              </div>

                              <div className="question-actions">
                                <Button
                                  text={i18n.t(
                                    'mailbox-security.configureEmailMxRecords.yes'
                                  )}
                                  onClick={() =>
                                    setMxRecords({
                                      ...mxRecords,
                                      areYouAbleToSetDNS: true,
                                    })
                                  }
                                  color={
                                    mxRecords.areYouAbleToSetDNS === true
                                      ? 'bluishGrey'
                                      : 'white'
                                  }
                                  size="small"
                                />

                                <Button
                                  text={i18n.t(
                                    'mailbox-security.configureEmailMxRecords.no'
                                  )}
                                  onClick={() =>
                                    setMxRecords({
                                      ...mxRecords,
                                      areYouAbleToSetDNS: false,
                                    })
                                  }
                                  color={
                                    mxRecords.areYouAbleToSetDNS === false
                                      ? 'bluishGrey'
                                      : 'white'
                                  }
                                  size="small"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {stepMxRecords === 2 && (
                        <div className="step-2-container">
                          <b>
                            {i18n.t(
                              'mailbox-security.configureEmailMxRecords.step2'
                            )}
                          </b>
                          <div className="email-protection-tutorial">
                            <p className="text">
                              {i18n.t(
                                'mailbox-security.configureEmailMxRecords.configureText',
                                {
                                  domain: client.atEmail,
                                }
                              )}
                            </p>
                            <p className="text">
                              {i18n.t(
                                'mailbox-security.configureEmailMxRecords.emailProtectionTutorialTitle'
                              )}
                            </p>
                            <p className="text">
                              <Button
                                onClick={() => {
                                  window.open(
                                    emailProtectionTutorial?.[i18n.lang],
                                    '_blank'
                                  );
                                }}
                                size="mid"
                                color="white"
                                icon={faArrowUpRightFromSquare}
                                text={i18n.t(
                                  'mailbox-security.configureEmailMxRecords.linkTitle'
                                )}
                              />
                            </p>
                            <p className="text">
                              <span>
                                {i18n.t(
                                  'mailbox-security.configureEmailMxRecords.makeSureMessage'
                                )}{' '}
                              </span>
                            </p>
                            <div onClick={onClickOnCopy} className="token">
                              <span>{client?.domainVerificationToken}</span>
                              <Icon
                                iconName={isCopy ? faCheck : faCopy}
                                size="medium"
                                color={
                                  isCopy ? 'var(--green)' : 'var(--bluish-grey)'
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </SectionCard>

                    <div className="buttons-container">
                      {stepMxRecords === 1 && (
                        <Button
                          width="340px"
                          onClick={handleButtonNext}
                          color="bluishGrey"
                          text={i18n.t(
                            'mailbox-security.configureEmailMxRecords.next'
                          )}
                        />
                      )}

                      {stepMxRecords === 2 && (
                        <>
                          <Button
                            width="340px"
                            onClick={handleButtonPrevious}
                            color="white"
                            text={i18n.t(
                              'mailbox-security.configureEmailMxRecords.back'
                            )}
                          />
                          <Button
                            width="340px"
                            onClick={() => {
                              document.documentElement.scrollTo({
                                top: 0,
                                left: 0,
                              });
                              dispatch(
                                showPopUp('notification', {
                                  notificationType: 'success',
                                  title: i18n.t(
                                    'mailbox-security.tutorialFinishedPopUp.title'
                                  ),
                                  text: i18n.t(
                                    'mailbox-security.tutorialFinishedPopUp.content'
                                  ),
                                  subtext: i18n.t(
                                    'mailbox-security.tutorialFinishedPopUp.subContent'
                                  ),
                                })
                              );
                              setMxRecords({
                                ...mxRecords,
                                areYouResponsible: undefined,
                                areYouAbleToSetDNS: undefined,
                              });
                              setStepMxRecords(1);
                            }}
                            color="bluishGrey"
                            text={i18n.t(
                              'mailbox-security.configureEmailMxRecords.done'
                            )}
                          />
                        </>
                      )}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="section-organism">
                <QuarantineEmails />
              </div>
            )}
          </>
        )}

        <AnyDoubts
          tutorialOnClick={() =>
            dispatch(showPopUp('tutorial', 'mailboxSecurity'))
          }
        />
      </SCMailboxSecuritySection>
    </SectionLayout>
  );
};

export default MailboxSecuritySection;
