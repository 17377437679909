import { css } from '@emotion/core';
import styled from '@emotion/styled';

const roundedIcon = (iconBackgroundColor) => css`
  padding: 10px;
  border-radius: 20px;
  background-color: ${iconBackgroundColor};
`;

const SCKPI = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  .icon-container {
    ${({ iconBackgroundColor }) =>
      iconBackgroundColor ? roundedIcon(iconBackgroundColor) : null};
  }

  .widget-texts {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    > h3 {
      font-family: var(--font1);
      font-size: 25px;
      color: #111927;
      font-weight: 600;

      > span {
        font-size: 16px;
        color: var(--bluish-grey);
        font-weight: 400;
      }
    }

    > p {
      font-size: 16px;
      color: var(--bluish-grey);
    }

    span {
      font-family: var(--font1);
      font-size: 16px;
      font-weight: 400;
      color: var(--bluish-grey);
    }

    .ant-skeleton-title {
      height: 30px;
    }

    .ant-skeleton-paragraph > li {
      height: 20px;
    }
  }
`;

export default SCKPI;
