import {
  faDisplay,
  faDollyBox,
  faEnvelopeOpen,
  faEnvelopesBulk,
  faFishingRod,
  faGlobe,
  faHouse,
  faMask,
  faPollH,
  faRadar,
  faRocketLaunch,
  faShieldPlus,
  faUserSecret,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faShieldHalved,
  faSquareArrowUpRight,
} from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from '../../../i18n';
import TopMenuItem from '../../atoms/TopMenuItem/TopMenuItem';
import TopMenuSubItem from '../../atoms/TopMenuSubItem/TopMenuSubItem';
import SettingsMenuButtons from '../../molecules/SettingsMenuButtons/SettingsMenuButtons';
import SCApplicationTopMenu from './ApplicationTopMenu.style';
import { ReactComponent as CyberGuardianLogo } from '../../../img/cyberguardian/cyberguardian-logo.svg';
import SubNavBar from '../../molecules/SubNavBar/SubNavBar';
import ApplicationSections from '../../../utils/sections/sections';
import useSection from '../../../hooks/useSection/useSection';
import { SESSION_STORAGE_KEYS } from '../../../utils/constants/browserStorage';

const ApplicationTopMenu = ({ mobileMenuOpened, setMobileMenu }) => {
  const client = useSelector((redux) => redux.client);
  const { section } = useSection();
  const navigate = useNavigate();
  const i18n = useTranslation();

  const { name: companyName, cif: companyCif } = client.company ?? {};

  const clientIdFromSessionStorage = sessionStorage.getItem(
    SESSION_STORAGE_KEYS.CLIENT_ID
  );

  const getCompanyNameAndCif = () => {
    const companyNameAndCif = [];

    if (companyName) {
      companyNameAndCif.push(companyName);
    }

    if (companyCif) {
      companyNameAndCif.push(companyCif);
    }

    return companyNameAndCif.join(' - ');
  };

  return (
    <SCApplicationTopMenu className={mobileMenuOpened ? 'dataObfuscation' : ''}>
      {(client.partner || clientIdFromSessionStorage) && (
        <div className="partner-navbar">
          <div>
            <p className="partner-client-description">
              <b>{i18n.t('partner.partnerClientNavbar.clientPanel')}</b>
            </p>
            {(Boolean(companyName) || Boolean(companyCif)) && (
              <p>
                <b>{getCompanyNameAndCif()}</b>
              </p>
            )}
            <div className="partner-dashboard-link">
              <a href="/partner">
                {i18n.t('partner.partnerClientNavbar.goBack')}
                <FontAwesomeIcon icon={faSquareArrowUpRight} />
              </a>
            </div>
          </div>
        </div>
      )}

      <div className="top-menu">
        <div className="logo-container">
          <CyberGuardianLogo
            onClick={() => {
              navigate('/client');
              setMobileMenu(false);
            }}
          />
        </div>
        <div className="menu-buttons">
          <TopMenuItem
            onClick={() => {
              navigate('/client');
            }}
            icon={faHouse}
            text={i18n.t('sections.home.name')}
            description={i18n.t('sections.home.description')}
            selected={!section}
          />
          <TopMenuItem
            onClick={() => {
              navigate('/client/control-panel');
            }}
            icon={faPollH}
            text={i18n.t('sections.controlPanel.name')}
            description={i18n.t('sections.controlPanel.description')}
            selected={section === 'control-panel'}
          />
          <TopMenuItem
            onClick={() => {
              navigate('/client/actions');
            }}
            icon={faRocketLaunch}
            text={i18n.t('sections.actions.name')}
            description={i18n.t('sections.actions.description')}
            selected={section === 'actions'}
          />
          <TopMenuItem
            icon={faShieldHalved}
            text={i18n.t('sections.protection.name')}
            description={i18n.t('sections.protection.description')}
            selected={ApplicationSections.internalSecurity.includes(section)}>
            <TopMenuSubItem
              icon={faShieldPlus}
              text={i18n.t('sections.protection.sendProtections.name')}
              description={i18n.t(
                'sections.protection.sendProtections.description'
              )}
              onClick={() =>
                navigate('/client/device-security/install-protections')
              }
            />
            <TopMenuSubItem
              icon={faDisplay}
              text={i18n.t('sections.protection.deviceSecurity.name')}
              description={i18n.t(
                'sections.protection.deviceSecurity.description'
              )}
              onClick={() => navigate('/client/device-security')}
            />
            <TopMenuSubItem
              icon={faRadar}
              text={i18n.t('sections.protection.managedThreats.name')}
              description={i18n.t(
                'sections.protection.managedThreats.description'
              )}
              onClick={() => navigate('/client/managed-threats')}
            />
            <TopMenuSubItem
              icon={faEnvelopesBulk}
              text={i18n.t('sections.protection.mailboxSecurity.name')}
              description={i18n.t(
                'sections.protection.mailboxSecurity.description'
              )}
              onClick={() => navigate('/client/mailbox-security')}
            />
          </TopMenuItem>
          <TopMenuItem
            icon={faGlobe}
            text={i18n.t('sections.exposure.name')}
            description={i18n.t('sections.exposure.description')}
            selected={ApplicationSections.internetExposure.includes(section)}>
            <TopMenuSubItem
              icon={faEnvelopeOpen}
              text={i18n.t('sections.exposure.emailSecurity.name')}
              description={i18n.t(
                'sections.exposure.emailSecurity.description'
              )}
              onClick={() => navigate('/client/configure-email-security')}
            />
            <TopMenuSubItem
              icon={faUserSecret}
              text={i18n.t('sections.exposure.dataFiltrations.name')}
              description={i18n.t(
                'sections.exposure.dataFiltrations.description'
              )}
              onClick={() => navigate('/client/data-alert')}
            />
            <TopMenuSubItem
              icon={faGlobe}
              text={i18n.t('sections.exposure.websiteSecurity.name')}
              description={i18n.t(
                'sections.exposure.websiteSecurity.description'
              )}
              onClick={() => navigate('/client/websitesecurity')}
            />
            <TopMenuSubItem
              icon={faMask}
              text={i18n.t('sections.exposure.domainImpersonation.name')}
              description={i18n.t(
                'sections.exposure.domainImpersonation.description'
              )}
              onClick={() => navigate('/client/domainimpersonation')}
            />
            <TopMenuSubItem
              icon={faDollyBox}
              text={i18n.t('sections.exposure.supplierSecurity.name')}
              description={i18n.t(
                'sections.exposure.supplierSecurity.description'
              )}
              onClick={() => navigate('/client/supplier-security')}
            />
          </TopMenuItem>
          <TopMenuItem
            onClick={() => {
              navigate('/client/phishingsimulator');
            }}
            icon={faFishingRod}
            text={i18n.t('sections.phishing.name')}
            description={i18n.t('sections.phishing.description')}
            selected={section === 'phishingsimulator'}
          />
        </div>
        <SettingsMenuButtons setMobileMenu={setMobileMenu} />
      </div>
      <div className="sub-navbar">
        <SubNavBar />
      </div>
    </SCApplicationTopMenu>
  );
};

export default ApplicationTopMenu;
