import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SUBSCRIPTIONS_PRODUCTS,
  SUBSCRIPTION_STATUS,
} from '../../../utils/constants/subscription';
import { useTranslation } from '../../../i18n';
import { finishTutorial } from '../../../redux/actions/client.actions';
import InfoBanner from '../../molecules/InfoBanner/InfoBanner';
import SCSubscriptionBanner from './SubscriptionBanner.style';
import usePaymentAccess from '../../../hooks/paymentAccess/usePaymentAccess';
import usePromoCode from '../../../hooks/usePromoCode/usePromoCode';
import {
  getCurrentSubscriptionDaysLeft,
  hasPaymentAccess,
} from '../../../utils/functions/subscriptions';
import { getTrialSubscription } from '../../../services/subscriptions/subscription.service';

const SubscriptionBanner = () => {
  const client = useSelector((redux) => redux.client);
  const subscription = useSelector((redux) => redux.subscription);

  const { handlePaymentAccess } = usePaymentAccess();
  const { promoCode } = usePromoCode(client.setupCode);

  const [displayAnalysisBanner, setDisplayAnalysisBanner] = useState(false);

  const i18n = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    getTrialSubscription(client.id).then((trialSubscription) => {
      const today = new Date();

      const subscriptionStartTime = trialSubscription
        ? trialSubscription?.startTime.toDate()
        : subscription?.startTime.toDate();

      const nextDayAccountCreation = new Date(subscriptionStartTime);
      nextDayAccountCreation.setDate(nextDayAccountCreation.getDate() + 1);

      setDisplayAnalysisBanner(nextDayAccountCreation > today);
    });
  }, []);

  const subscriptionDaysLeft = getCurrentSubscriptionDaysLeft(subscription);

  const getSubscriptionBanner = () => {
    let banner = <></>;

    const isPendingPayment =
      subscription?.status === SUBSCRIPTION_STATUS.ACTIVE &&
      subscriptionDaysLeft <= 0;

    const isTrialPendingPayment = isPendingPayment && promoCode?.isCardRequired;

    switch (subscription.product) {
      case SUBSCRIPTIONS_PRODUCTS.TRIAL:
      case SUBSCRIPTIONS_PRODUCTS.TRIAL_PROTECTION:
        if (isTrialPendingPayment) {
          banner = (
            <div className="section-organism">
              <InfoBanner
                text={[i18n.t('homeTrialSantanderPendingPayment.text')]}
              />
            </div>
          );
        } else {
          banner = (
            <>
              {subscriptionDaysLeft > 0 && (
                <div className="section-organism">
                  <InfoBanner
                    type={subscriptionDaysLeft <= 3 ? 'error' : 'defaultStyle'}
                    text={[
                      i18n.t('homeTrialBanner.text', {
                        trialDaysLeft: subscriptionDaysLeft,
                      }),
                    ]}
                    {...(hasPaymentAccess() && {
                      onClickButton: handlePaymentAccess,
                      buttonText: i18n.t(
                        'contractSubscription.payment.actions.signContract'
                      ),
                    })}
                  />
                </div>
              )}

              {!client?.finishedTutorials?.homeBannerAnalysisInformation &&
                displayAnalysisBanner && (
                  <div className="section-organism">
                    <InfoBanner
                      text={[i18n.t('homeBannerAnalysisInformation.text')]}
                      onClickButton={() =>
                        dispatch(
                          finishTutorial('homeBannerAnalysisInformation')
                        )
                      }
                      buttonText={i18n.t(
                        'homeBannerAnalysisInformation.buttonText'
                      )}
                    />
                  </div>
                )}
            </>
          );
        }
        break;
      case SUBSCRIPTIONS_PRODUCTS.DEFAULT:
        banner = (
          <div className="section-organism">
            <InfoBanner
              text={[i18n.t('homeDefaultBanner.text')]}
              {...(hasPaymentAccess() && {
                onClickButton: handlePaymentAccess,
                buttonText: i18n.t(
                  'contractSubscription.payment.actions.signContract'
                ),
              })}
            />
          </div>
        );
        break;
      case SUBSCRIPTIONS_PRODUCTS.PAYING:
        if (isPendingPayment) {
          banner = (
            <div className="section-organism">
              <InfoBanner
                text={[i18n.t('homePayingSantanderPendingPayment.text')]}
              />
            </div>
          );
        } else {
          banner = !client?.finishedTutorials?.homeBannerAnalysisInformation &&
            displayAnalysisBanner && (
              <div className="section-organism">
                <InfoBanner
                  text={[i18n.t('homeBannerAnalysisInformation.text')]}
                  onClickButton={() =>
                    dispatch(finishTutorial('homeBannerAnalysisInformation'))
                  }
                  buttonText={i18n.t(
                    'homeBannerAnalysisInformation.buttonText'
                  )}
                />
              </div>
            );
        }
        break;
      default:
        break;
    }

    return banner;
  };

  return (
    <SCSubscriptionBanner>
      {subscription && getSubscriptionBanner()}
    </SCSubscriptionBanner>
  );
};

export default SubscriptionBanner;
