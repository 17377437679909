import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  faBookOpenReader,
  faEye,
  faHouseChimneyWindow,
  faMemo,
  faTableCellsLarge,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { faShieldHalved } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from '../../../i18n';
import MobileMenuItem from '../../atoms/MobileMenuItem/MobileMenuItem';
import MobileMenuSubItem from '../../atoms/MobileMenuSubItem/MobileMenuSubItem';
import SCApplicationMenu from './MobileMenu.style';
import MobileMenuDropDownItem from '../../molecules/MobileMenuDropDownItem/MobileMenuDropDownItem';
import { ReactComponent as CyberGuardianLogo } from '../../../img/cyberguardian/cyberguardian-logo.svg';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import useSection from '../../../hooks/useSection/useSection';

const COMPOSED_SECTIONS = {
  internalSecurity: [
    'device-security',
    'device-vulnerabilities',
    'managed-threats',
    'mailbox-security',
  ],
  internetExposure: [
    'configure-email-security',
    'data-alert',
    'websitesecurity',
    'domainimpersonation',
    'supplier-security',
  ],
};

const MobileMenu = ({ mobileMenuOpened, setMobileMenu }) => {
  const i18n = useTranslation();
  const navigate = useNavigate();
  const { section, subSection } = useSection();
  const [droppedDownMenuItem, setDroppedDownMenuItem] = useState('');

  const handleDropDownClick = (item) => {
    if (droppedDownMenuItem === item) {
      setDroppedDownMenuItem('');
    } else {
      setDroppedDownMenuItem(item);
    }
  };

  // Open the drop down if applicable
  useEffect(() => {
    const sectionAux = Object.keys(COMPOSED_SECTIONS).find((key) =>
      COMPOSED_SECTIONS[key].includes(section)
    );

    if (!!sectionAux && !(droppedDownMenuItem === sectionAux)) {
      setDroppedDownMenuItem(sectionAux);
    }
  }, [section]);

  return (
    <SCApplicationMenu mobileOpened={mobileMenuOpened}>
      <div className="ApplicationMenuContent">
        {/* Top part of the menu */}
        <div className="top-app-menu-container">
          <div className="logo-settings-container">
            <CyberGuardianLogo
              style={{
                height: '50px',
                width: '125px',
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate('/client');
                setMobileMenu(false);
              }}
            />
            <div>
              <Button
                id="settings-button"
                color="icon"
                text={
                  <Icon
                    iconName={faXmark}
                    size="semiLarge"
                    color="var(--bluish-grey)"
                  />
                }
                onClick={() => {
                  setMobileMenu(false);
                }}
              />
            </div>
          </div>

          <div className="application-menu-items">
            {/* Home */}
            <MobileMenuItem
              onClick={() => {
                navigate('/client');
                setMobileMenu(false);
              }}
              icon={faHouseChimneyWindow}
              text={i18n.t('sidebar.home')}
              selected={!section}
              id="aplication-menu-home-section"
            />

            {/* Control Panel */}
            <MobileMenuItem
              onClick={() => {
                navigate('/client/control-panel');
                setMobileMenu(false);
              }}
              icon={faTableCellsLarge}
              text={i18n.t('sidebar.controlPanel')}
              selected={section === 'control-panel'}
              id="aplication-menu-controlPanel-section"
            />

            {/* Actions List */}
            <MobileMenuItem
              onClick={() => {
                navigate('/client/actions');
                setMobileMenu(false);
              }}
              icon={faMemo}
              text={i18n.t('sidebar.actionCenter')}
              selected={section === 'actions'}
              id="aplication-menu-actions-section"
            />

            {/* Internal Security */}
            <MobileMenuDropDownItem
              onClick={() => handleDropDownClick('internalSecurity')}
              icon={faShieldHalved}
              text={i18n.t('sidebar.internalSecurity')}
              opened={droppedDownMenuItem === 'internalSecurity'}
              id="aplication-menu-internalSecurity-section">
              {/* Device Security */}
              <MobileMenuSubItem
                onClick={() => {
                  navigate('/client/device-security/install-protections');
                  setMobileMenu(false);
                }}
                text={i18n.t('sidebar.sendProtections')}
                selected={
                  section === 'device-security' &&
                  subSection === 'install-protections'
                }
                id="aplication-subMenu-installProtections-section"
              />
              <MobileMenuSubItem
                onClick={() => {
                  navigate('/client/device-security');
                  setMobileMenu(false);
                }}
                text={i18n.t('sidebar.deviceSecurity')}
                selected={
                  section === 'device-security' &&
                  subSection !== 'install-protections'
                }
                id="aplication-subMenu-deviceSecurity-section"
              />

              {/* Managed Threats */}
              <MobileMenuSubItem
                onClick={() => {
                  navigate('/client/managed-threats');
                  setMobileMenu(false);
                }}
                text={i18n.t('sidebar.managedThreats')}
                selected={section === 'managed-threats'}
                id="aplication-subMenu-managedThreats-section"
              />

              {/* Mailbox Security */}
              <MobileMenuSubItem
                onClick={() => {
                  navigate('/client/mailbox-security');
                  setMobileMenu(false);
                }}
                text={i18n.t('sidebar.mailboxSecurity')}
                selected={section === 'mailbox-security'}
                id="aplication-subMenu-mailboxSecurity-section"
              />
            </MobileMenuDropDownItem>

            {/* Internet Exposure */}
            <MobileMenuDropDownItem
              onClick={() => {
                handleDropDownClick('internetExposure');
              }}
              icon={faEye}
              text={i18n.t('sidebar.internetExposure')}
              opened={droppedDownMenuItem === 'internetExposure'}
              id="aplication-menu-internetExposure-section">
              <MobileMenuSubItem
                onClick={() => {
                  navigate('/client/configure-email-security');
                  setMobileMenu(false);
                }}
                text={i18n.t('sidebar.emailSecurity')}
                selected={section === 'configure-email-security'}
                id="aplication-subMenu-emailSecurity-section"
              />

              {/* Data Filtrations */}
              <MobileMenuSubItem
                onClick={() => {
                  navigate('/client/data-alert');
                  setMobileMenu(false);
                }}
                text={i18n.t('sidebar.dataFiltrations')}
                selected={section === 'data-alert'}
                id="aplication-subMenu-dataFiltrations-section"
              />

              {/* Web Page Security */}
              <MobileMenuSubItem
                onClick={() => {
                  navigate('/client/websitesecurity');
                  setMobileMenu(false);
                }}
                text={i18n.t('sidebar.webPageSecurity')}
                selected={section === 'websitesecurity'}
                id="aplication-subMenu-websitesecurity-section"
              />

              {/* Possible Impersonations */}
              <MobileMenuSubItem
                onClick={() => {
                  navigate('/client/domainimpersonation');
                  setMobileMenu(false);
                }}
                text={i18n.t('sidebar.possibleImpersonations')}
                selected={section === 'domainimpersonation'}
                id="aplication-subMenu-domainimpersonation-section"
              />

              {/* Supplier Security */}
              <MobileMenuSubItem
                onClick={() => {
                  navigate('/client/supplier-security');
                  setMobileMenu(false);
                }}
                text={i18n.t('sidebar.vendorSecurity')}
                selected={section === 'supplier-security'}
                id="aplication-subMenu-supplierSecurity-section"
              />
            </MobileMenuDropDownItem>

            {/* Phishing Formation */}
            <MobileMenuItem
              onClick={() => {
                navigate('/client/phishingsimulator');
                setMobileMenu(false);
              }}
              icon={faBookOpenReader}
              text={i18n.t('sidebar.phishingFormation')}
              selected={section === 'phishingsimulator'}
              id="aplication-menu-phishingFormation-section"
            />
          </div>
        </div>
      </div>
    </SCApplicationMenu>
  );
};

export default MobileMenu;
