import useLicenses from '../../../hooks/licenses/useLicenses';
import handleUnauthorizedAccess from '../../../utils/restrictedAccessBehaviour/restrictedAccessBehaviour';

function MailboxSecurityRouteGuard({ children }) {
  const { licenses } = useLicenses();

  const allowAccess = licenses.emailProtection > 0;

  return allowAccess ? children : handleUnauthorizedAccess();
}

export default MailboxSecurityRouteGuard;
