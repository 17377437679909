export const AUTH_USER = 'AUTH_USER';
export const INITIATE_FIREBASE = 'INITIATE_FIREBASE';
export const GET_CLIENT = 'GET_CLIENT';
export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_IN = 'SIGN_IN';
export const GET_MONITORED_DOMAINS = 'GET_MONITORED_DOMAINS';
export const ADD_EMAIL_RESULTS = 'ADD_EMAIL_RESULTS';
export const SHOW_POP_UP = 'SHOW_POP_UP';
export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const GET_PAYMENTMETHODS = 'GET_PAYMENTMETHODS';
export const REMOVE_EXPLICIT_EMAIL_RESULTS = 'REMOVE_EXPLICIT_EMAIL_RESULTS';
export const GET_MONITORED_SUPPLIERS = 'GET_MONITORED_SUPPLIERS';
export const GET_DNS_RECORDS = 'GET_DNS_RECORDS';
export const GET_TEAM_MEMBERS = 'GET_TEAM_MEMBERS';
export const GET_MANAGED_THREAT_COUNTERS = 'GET_MANAGED_THREAT_COUNTERS';
export const GET_MY_USER_PREFERENCES = 'GET_MY_USER_PREFERENCES';
export const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
export const GET_PHISHING_TRAININGS = 'GET_PHISHING_TRAININGS';
export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const GET_LICENSES_COUNTER = 'GET_LICENSES_COUNTER';
export const GET_PROTECTED_DEVICES = 'GET_PROTECTED_DEVICES';
export const GET_PAYMENTS = 'GET_PAYMENTS';
export const GET_IMPERSONATIONS = 'GET_IMPERSONATIONS';
export const GET_EMAIL_PROTECTION_TUTORIAL_DATA =
  'GET_EMAIL_PROTECTION_TUTORIAL_DATA';
export const SET_PARTNER_CONFIG = 'SET_PARTNER_CONFIG';
export default AUTH_USER;
