import { useEffect, useState } from 'react';
import deviceProtectionService from '../../services/deviceProtection/deviceProtection.service';

const usePartnerClientLicensesCounter = ({ clientId }) => {
  const [licensesCounter, setLicensesCounter] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchPartnerClientLicensesCounter = async () => {
    if (clientId) {
      try {
        const resp =
          await deviceProtectionService.getPartnerClientLicensesCounter(
            clientId
          );
        setLicensesCounter(resp.data?.data);
      } catch (e) {
        setError(e);
      }
    }
  };

  useEffect(() => {
    if (clientId) {
      setLoading(true);
      setError(false);

      fetchPartnerClientLicensesCounter().finally(() => {
        setLoading(false);
      });
    }
  }, [clientId]);

  return {
    licensesCounter,
    loading,
    error,
    fetchPartnerClientLicensesCounter,
  };
};

export default usePartnerClientLicensesCounter;
