import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from 'antd';
import SCKPI from './KPI.style';

const KPI = ({
  value = 0,
  total,
  title,
  icon,
  iconBackgroundColor,
  loading,
}) => {
  return (
    <SCKPI iconBackgroundColor={iconBackgroundColor}>
      {!loading ? (
        <>
          <FontAwesomeIcon
            className="icon-container"
            icon={icon}
            fontSize="30px"
            color="var(--partner-primary)"
          />

          <div className="widget-texts">
            <h3>
              {value}
              {Boolean(total) && <span> / {total}</span>}
            </h3>
            <p>{title}</p>
          </div>
        </>
      ) : (
        <>
          <Skeleton.Avatar active size={53} />
          <div className="widget-texts">
            <Skeleton active paragraph={false} />
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          </div>
        </>
      )}
    </SCKPI>
  );
};

export default KPI;
