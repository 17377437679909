import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faCheck, faCopy } from '@fortawesome/pro-regular-svg-icons';
import { faDesktop, faServer } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from '../../../i18n';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import installationTokensService from '../../../services/installationTokens/installationTokens.service';
import { getDateFormat } from '../../../utils/format/dataFormat';
import Label from '../../atoms/Label/Label';
import Button from '../../atoms/Button/Button';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import deviceProtectionService from '../../../services/deviceProtection/deviceProtection.service';
import { ErrorKey } from '../../../track';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';
import useLicenses from '../../../hooks/licenses/useLicenses';

const SCLicensesSentByEmployeePopUp = styled.div`
  h2 {
    font-weight: 500;
    font-family: var(--font1);
    font-size: 25px;
  }

  p {
    font-size: 14px;
    color: inherit;
  }

  .title-container {
    .copy-email-container {
      display: flex;
      gap: 5px;
      justify-content: center;
      align-items: center;

      > button {
        padding: 2px 4px;
        background-color: transparent;
        outline: none;

        :disabled {
          cursor: auto;
        }
      }
    }
  }

  .devices-card-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .device-card {
      color: var(--bluish-grey);
      background-color: var(--light-grey);
      padding: 15px;
      border-radius: 10px;

      display: flex;
      flex-direction: column;
      gap: 10px;

      .device-type {
        display: flex;
        gap: 10px;
        align-items: center;

        > p {
          font-weight: 600;
          line-height: 1;
        }
      }

      table {
        font-family: var(--font2);
        width: 100%;
        border-collapse: collapse;
        font-size: 14px;
        table-layout: fixed;

        td,
        th {
          text-align: left;
          padding: 5px 10px;
        }

        th {
          font-weight: 600;
          vertical-align: top;
        }

        .installation-token-column {
          width: 37.5%;
        }

        .token-actions-container {
          display: flex;
          gap: 5px;

          > button {
            min-height: 30px;
            font-size: 14px;

            svg {
              width: 15px;
              height: 15px;
            }
          }

          .copy-button {
            min-width: 100px;
          }

          .revoke-button {
            min-width: 130px;
          }
        }
      }
    }
  }
`;

const DEVICE_TYPES = {
  DESKTOP: 'DESKTOP',
  SERVER: 'SERVER',
  ANDROID: 'ANDROID',
  IOS: 'IOS',
};

const sortInstallationTokens = (a, b) => {
  const protectionsOrder = ({ malware, web }) => {
    if (malware && !web) return 0;
    if (!malware && web) return 1;
    return 2;
  };

  return protectionsOrder(a.protections) - protectionsOrder(b.protections);
};

const LicensesSentByEmployeePopUp = ({ teamMemberData }) => {
  const { hashedEmail, email } = teamMemberData;
  const { id: clientId } = useSelector((redux) => redux.client);
  const { licensesMemberSent, licensesMemberUsed } = useLicenses();
  const dispatch = useDispatch();
  const i18n = useTranslation();
  const [installationTokens, setInstallationTokens] = useState({});
  const [isLoadingRevokeToken, setIsLoadingRevokeToken] = useState({});
  const [isLoadingCopy, setIsLoadingCopy] = useState({});
  const [iosToken, setIosToken] = useState();

  const memberLicensesSent = licensesMemberSent?.[hashedEmail];
  const memberLicensesUsed = licensesMemberUsed?.[hashedEmail];

  const androidLicensesSent =
    memberLicensesSent?.android?.malwareProtection ?? 0;
  const androidLicensesUsed =
    memberLicensesUsed?.android?.malwareProtection ?? 0;
  const iosLicensesSent = memberLicensesSent?.ios?.malwareProtection ?? 0;
  const iosLicensesUsed = memberLicensesUsed?.ios?.malwareProtection ?? 0;

  useEffect(() => {
    const getInstallationTokens = async () => {
      const tokens =
        await installationTokensService.getTeamMemberInstallationTokens({
          clientId,
          teamMemberId: hashedEmail,
        });

      const orderedTokens = tokens.sort(sortInstallationTokens);
      const installationTokensByType = Object.groupBy(
        orderedTokens,
        ({ type }) => type
      );

      setInstallationTokens(installationTokensByType);

      if (iosLicensesSent - iosLicensesUsed > 0) {
        const iosTokenResp = await deviceProtectionService.getIosToken();
        setIosToken(iosTokenResp.data.data);
      }
    };

    getInstallationTokens();
  }, []);

  const devices = [
    {
      type: DEVICE_TYPES.DESKTOP,
      title: i18n.t('deviceSecurity.licensesSentByEmployeeModal.desktop'),
      icon: faDesktop,
      tokens: installationTokens[DEVICE_TYPES.DESKTOP],
    },
    {
      type: DEVICE_TYPES.SERVER,
      title: i18n.t('deviceSecurity.licensesSentByEmployeeModal.server'),
      icon: faServer,
      tokens: installationTokens[DEVICE_TYPES.SERVER],
    },
    {
      type: DEVICE_TYPES.ANDROID,
      title: 'Android',
      icon: faAndroid,
      tokens: [
        {
          licensesSent: androidLicensesSent,
          licensesUsed: androidLicensesUsed,
          protections: { malware: true, web: false },
        },
      ],
    },
    {
      type: DEVICE_TYPES.IOS,
      title: 'iOS',
      icon: faApple,
      tokens: [
        {
          licensesSent: iosLicensesSent,
          licensesUsed: iosLicensesUsed,
          protections: { malware: true, web: false },
          value: iosToken,
        },
      ],
    },
  ];

  const handleCopy = (value) => {
    setIsLoadingCopy(() => ({
      [value]: true,
    }));

    navigator.clipboard.writeText(value);

    setTimeout(() => {
      setIsLoadingCopy(() => ({
        [value]: false,
      }));
    }, 2000);
  };

  const getExpirationDate = (expirationDate) => {
    const date = getDateFormat(expirationDate);
    const hours = String(expirationDate?.getHours()).padStart(2, '0');
    const minutes = String(expirationDate?.getMinutes()).padStart(2, '0');

    return i18n.t('deviceSecurity.licensesSentByEmployeeModal.expirationDate', {
      date,
      hours,
      minutes,
    });
  };

  const getProtectionType = ({ web, malware }) => {
    if (malware && web) {
      return i18n.t('deviceSecurity.licensesSentByEmployeeModal.full');
    }

    if (malware) {
      return i18n.t('deviceSecurity.licensesSentByEmployeeModal.malware');
    }

    return i18n.t('deviceSecurity.licensesSentByEmployeeModal.web');
  };

  const getSetupToken = (token) => {
    if (!token) {
      return null;
    }

    return `${clientId}${token}`;
  };

  const closeModal = () => {
    dispatch(showPopUp(null));
  };

  const handleRevokeToken = (token) => {
    setIsLoadingRevokeToken((prevState) => ({
      ...prevState,
      [token]: true,
    }));

    deviceProtectionService
      .revokeToken({ tokenValue: token })
      .then(closeModal)
      .catch(() => {
        ErrorKey('errors.not_found');
      })
      .finally(() => {
        setIsLoadingRevokeToken((prevState) => ({
          ...prevState,
          [token]: false,
        }));
      });
  };

  return (
    <ModalLayout>
      <SCLicensesSentByEmployeePopUp>
        <div className="title-container">
          <h2>
            {i18n.t(
              'deviceSecurity.licensesSentByEmployeeModal.licensesAssignedTo'
            )}
          </h2>

          <div className="copy-email-container">
            <h3>{email}</h3>
            <button
              type="button"
              aria-label="Copy email"
              onClick={() => {
                handleCopy(email);
              }}
              disabled={isLoadingCopy[email]}>
              <FontAwesomeIcon icon={isLoadingCopy[email] ? faCheck : faCopy} />
            </button>
          </div>
        </div>

        <div className="modal-card-content devices-card-container">
          {devices.map(({ type, title, icon, tokens = [] }) => {
            const availableLicenses = tokens?.reduce(
              (acc, { licensesSent, licensesUsed }) =>
                acc + (licensesSent - licensesUsed),
              0
            );

            if (availableLicenses === 0) {
              return null;
            }

            return (
              <div className="device-card">
                <div className="device-type">
                  <FontAwesomeIcon icon={icon} size="lg" />
                  <p>{title}</p>
                </div>

                <table>
                  <thead>
                    <tr>
                      <th>
                        {i18n.t(
                          'deviceSecurity.licensesSentByEmployeeModal.protection'
                        )}
                      </th>
                      <th>
                        {i18n.t(
                          'deviceSecurity.licensesSentByEmployeeModal.availableLicenses'
                        )}
                      </th>
                      <th>
                        {i18n.t(
                          'deviceSecurity.licensesSentByEmployeeModal.expiredAt'
                        )}
                      </th>
                      <th className="installation-token-column">
                        {i18n.t(
                          'deviceSecurity.licensesSentByEmployeeModal.installationToken'
                        )}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {tokens?.map(
                      ({
                        protections,
                        licensesSent,
                        licensesUsed,
                        expirationDate,
                        value,
                      }) => {
                        const setupToken =
                          type === DEVICE_TYPES.IOS
                            ? value
                            : getSetupToken(value);
                        const isLoadingTokenCopy = isLoadingCopy[setupToken];
                        const isLoadingRevoke = isLoadingRevokeToken[value];

                        if (licensesSent - licensesUsed === 0) {
                          return null;
                        }

                        return (
                          <tr>
                            <td>{getProtectionType(protections)}</td>
                            <td>
                              <Label
                                value={licensesSent - licensesUsed || 0}
                                width="100px"
                              />
                            </td>
                            <td>
                              {[
                                DEVICE_TYPES.DESKTOP,
                                DEVICE_TYPES.SERVER,
                              ].includes(type) &&
                                getExpirationDate(expirationDate?.toDate())}
                              {type === DEVICE_TYPES.ANDROID &&
                                i18n.t(
                                  'deviceSecurity.licensesSentByEmployeeModal.mobileInfo'
                                )}
                              {type === DEVICE_TYPES.IOS && 'N/A'}
                            </td>
                            <td className="token-actions-container">
                              {type === DEVICE_TYPES.ANDROID && 'N/A'}
                              {type !== DEVICE_TYPES.ANDROID && (
                                <>
                                  <Button
                                    className="copy-button"
                                    text={
                                      isLoadingTokenCopy
                                        ? i18n.t('common.copied')
                                        : i18n.t(
                                            'deviceSecurity.licensesSentByEmployeeModal.copy'
                                          )
                                    }
                                    size="fit"
                                    color="bluishGrey"
                                    onClick={() => {
                                      handleCopy(setupToken);
                                    }}
                                    disabled={isLoadingTokenCopy}
                                  />
                                  {type !== DEVICE_TYPES.IOS && (
                                    <Button
                                      className="revoke-button"
                                      text={
                                        isLoadingRevoke ? (
                                          <SpinnerText
                                            text={i18n.t('common.loading')}
                                          />
                                        ) : (
                                          i18n.t(
                                            'deviceSecurity.licensesSentByEmployeeModal.revoke'
                                          )
                                        )
                                      }
                                      size="fit"
                                      color="redOutline"
                                      onClick={() => handleRevokeToken(value)}
                                      disabled={isLoadingRevoke}
                                    />
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            );
          })}
        </div>

        <div className="modal-card-buttons">
          <Button
            size="full"
            color="white"
            text={i18n.t('common.back')}
            onClick={closeModal}
          />
        </div>
      </SCLicensesSentByEmployeePopUp>
    </ModalLayout>
  );
};

export default LicensesSentByEmployeePopUp;
