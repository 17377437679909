import React from 'react';
import { Form, Input, Select } from 'antd';
import SCCustomCard from './CustomCard.style';

const STEP_SUBSCRIBER = 'CGS007';

const SalesProcessCard = ({ leadOwners, leadSteps, step }) => {
  const formItems = [
    {
      label: 'Propietario',
      name: ['leadOwner'],
      type: 'select',
      options: leadOwners.map((leadOwner) => ({
        value: `${leadOwner.firstName} ${leadOwner.lastName}`,
        label: `${leadOwner.firstName} ${leadOwner.lastName}`,
      })),
    },
    {
      label: 'Estado',
      name: ['stepDescription'],
      type: 'select',
      options: leadSteps.map((leadStep) => ({
        value: leadStep.id,
        label: leadStep.description,
      })),
      disabled: step === STEP_SUBSCRIBER,
    },
    {
      label: 'Notas',
      name: ['notes'],
      type: 'textarea',
      rows: 4,
    },
  ];

  return (
    <SCCustomCard title="Proceso de Venta" size="small" bordered>
      <div className="content-grid">
        {formItems.map((item) => (
          <Form.Item
            label={
              <span>
                <strong>{item.label}</strong>
              </span>
            }
            name={item.name}
            {...(item.type === 'textarea' && { className: 'full-width' })}>
            {item.type === 'textarea' && (
              <Input.TextArea
                rows={item.rows}
                style={{ fieldSizing: 'content', marginBottom: '10px' }}
                size="small"
                maxLength={2000}
                showCount
                autoSize
              />
            )}
            {item.type === 'select' && (
              <Select
                size="small"
                options={item.options}
                disabled={item.disabled}
              />
            )}
            {item.type === 'input' && <Input size="small" />}
          </Form.Item>
        ))}
      </div>
    </SCCustomCard>
  );
};

export default SalesProcessCard;
