import React from 'react';
import Button from '../../atoms/Button/Button';
import SCEmptyCardContent from './EmptyCardContent.style';

const EmptyCardContent = ({
  text,
  buttonText,
  buttonIcon,
  buttonOnClick,
  ...props
}) => {
  return (
    <SCEmptyCardContent {...props}>
      <p>{text}</p>
      {buttonOnClick && (
        <Button
          icon={buttonIcon}
          text={buttonText}
          size="mid"
          color="white"
          onClick={buttonOnClick}
        />
      )}
    </SCEmptyCardContent>
  );
};

export default EmptyCardContent;
