import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';

import { showPopUp } from '../../../../redux/actions/popUp.actions';
import { useTranslation } from '../../../../i18n';
import Tool4StepsTutorial from '../../../organisms/Tool4StepsTutorial/Tool4StepsTutorial';
import SummaryCard from '../../../organisms/SummaryCard/SummaryCard';
import SectionLayout from '../../../layouts/SectionLayout/SectionLayout';
import ResultCampaignsTableCard from '../../../organisms/ResultCampaignsTableCard/ResultCampaignsTableCard';

import ProgressTutorial from '../../../organisms/ProgressTutorial/ProgressTutorial';
import SingleResultCampaignTableCard from '../../../organisms/SingleResultCampaignTableCard/SingleResultCampaignTableCard';
import BackButton from '../../../molecules/BackButton/BackButton';
import VisionByEmployeePhishingTableCard from '../../../organisms/VisionByEmployeePhishingTableCard/VisionByEmployeePhishingTableCard';
import AnyDoubts from '../../../molecules/AnyDoubts/AnyDoubts';
import useCampaign from '../../../../hooks/useCampaign/useCampaign';
import usePhishingTrainings from '../../../../hooks/phishingTrainings/usePhishingTrainings';

const PhishingSimulator: React.FC = () => {
  const { phishingTrainings } = usePhishingTrainings();

  const campaigns = useSelector((redux: any) => redux.campaigns);
  const teamMembers = useSelector((redux) => redux.teamMembers);
  const client = useSelector((state: any) => state.client);

  const { getCampaignTeamMemberSummary } = useCampaign();
  const i18n = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const campaignsTotal = getCampaignTeamMemberSummary(campaigns, teamMembers);

  useEffect(() => {
    if (!client?.finishedTutorials?.phishing) {
      dispatch(showPopUp('tutorial', 'phishing'));
    }
  }, []);

  const getTrainingsResult = () => {
    const result: { opened: any[]; completed: any[] } = {
      opened: [],
      completed: [],
    };

    if (Object.keys(phishingTrainings).length > 0) {
      Object.keys(phishingTrainings).forEach((key: any) => {
        if (phishingTrainings[key].opened) {
          result.opened.push(
            campaignsTotal.find((member) => member.email === key)?.email
          );
        }
        if (phishingTrainings[key].completed) {
          result.completed.push(
            campaignsTotal.find((member) => member.email === key)?.email
          );
        }
      });
    }
    return result;
  };

  const trainingResults = getTrainingsResult();

  const getActiveCampaignById = (identifier: any) => {
    const campaign = campaigns.find((item: any) => {
      return item.campaign_id == identifier;
    });

    if (campaign?.results) {
      campaign.results = campaign.results.map((campaignResult: any) => {
        const targetTeamMember = teamMembers?.find(
          (teamMember: any) => teamMember.email === campaignResult.email
        );
        return {
          ...campaignResult,
          ...(targetTeamMember && { first_name: targetTeamMember?.firstName }),
          ...(targetTeamMember && { last_name: targetTeamMember?.lastName }),
          tags: targetTeamMember?.tags ?? [],
          role: targetTeamMember?.role ?? [],
        };
      });
    }

    return campaign;
  };

  const activeCampaign = id ? getActiveCampaignById(id) : {};

  const getProgress = () => {
    if (!activeCampaign) return 0;
    if (!trainingResults) return 1;
    if (
      trainingResults &&
      trainingResults.completed &&
      trainingResults.opened
    ) {
      if (
        activeCampaign.results &&
        activeCampaign.results.length === trainingResults.completed.length
      )
        return 5;
      if (activeCampaign.results_sent_date) return 4;
    }
    if (activeCampaign.launch_date) {
      const readyDateInMSecs = Date.parse(activeCampaign.launch_date) + 172800;
      if (readyDateInMSecs < new Date().getTime()) return 2;
    }
    return 1;
  };

  return (
    <SectionLayout>
      {!id && (
        <>
          <div className="section-organism">
            <Tool4StepsTutorial />
          </div>

          <div className="section-organism">
            <SummaryCard />
          </div>

          <div className="section-organism">
            <ResultCampaignsTableCard phishingCampaigns={campaigns} />
          </div>

          <div className="section-organism">
            <VisionByEmployeePhishingTableCard
              displayedMembers={campaignsTotal}
              trainingResults={trainingResults}
            />
          </div>

          <AnyDoubts
            tutorialOnClick={() => dispatch(showPopUp('tutorial', 'phishing'))}
          />
        </>
      )}

      {id && activeCampaign && (
        <>
          <div className="section-back-button">
            <BackButton />
          </div>

          <div className="section-organism">
            <ProgressTutorial
              current={getProgress()}
              campaignName={i18n.t(
                `phishingSimulator.campaigns.campaingTypes.${
                  activeCampaign?.campaign_type?.split('-')[0]
                }`
              )}
            />
          </div>

          <div className="section-organism">
            <SummaryCard
              campaign={activeCampaign}
              trainingResults={trainingResults}
            />
          </div>

          <div className="section-organism">
            <SingleResultCampaignTableCard
              campaign={activeCampaign}
              trainingResults={trainingResults}
            />
          </div>
        </>
      )}
    </SectionLayout>
  );
};

export default PhishingSimulator;
