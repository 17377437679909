import React from 'react';
import { faMemoCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from 'antd';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCPurchasedLicenses from './PurchasedLicenses.style';
import { useTranslation } from '../../../i18n';
import LabelDetailGroup from '../LabelDetailGroup/LabelDetailGroup';

const PurchasedLicenses = ({ licenses = {}, isHeaderClickable, loading }) => {
  const {
    emailProtection = 0,
    malwareProtection = 0,
    webProtection = 0,
  } = licenses;
  const navigate = useNavigate();
  const i18n = useTranslation();

  const LABEL_DETAILS = [
    {
      name: i18n.t('controlPanel.purchasedLicenses.deviceProtection'),
      value: i18n.t('controlPanel.purchasedLicenses.nbLicenses', {
        licenses: malwareProtection,
      }),
    },

    {
      name: i18n.t('controlPanel.purchasedLicenses.webProtection'),
      value: i18n.t('controlPanel.purchasedLicenses.nbLicenses', {
        licenses: webProtection,
      }),
    },
    {
      name: i18n.t('controlPanel.purchasedLicenses.emailProtection'),
      value: i18n.t('controlPanel.purchasedLicenses.nbLicenses', {
        licenses: emailProtection,
      }),
    },
  ];

  return (
    <SCPurchasedLicenses>
      <SectionCard
        headerIcon={faMemoCircleInfo}
        headerTitle={i18n.t('controlPanel.purchasedLicenses.title')}
        headerButtonOnClick={
          isHeaderClickable
            ? () => navigate('/client/settings/billing')
            : undefined
        }>
        {!loading ? (
          <LabelDetailGroup labelDetails={LABEL_DETAILS} />
        ) : (
          <Skeleton active paragraph={{ rows: 3 }} />
        )}
      </SectionCard>
    </SCPurchasedLicenses>
  );
};

export default PurchasedLicenses;
