import styled from '@emotion/styled';

const SCMailboxProtectionCard = styled.div`
  h3 {
    font-size: 25px;
    font-weight: 600;
  }

  .mailbox-protection-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: space-between;

    .mailbox-data-container {
      display: flex;
      flex-direction: column;
      gap: 25px;

      .mailbox-status-container,
      .mailbox-protected-container {
        display: flex;
        gap: 20px;
      }

      .mailbox-protected-container .mailbox-protected-kpi {
        display: flex;
        flex-direction: column;
        gap: 5px;

        > h3 > span {
          font-size: 16px;
          color: var(--bluish-grey);
          font-weight: 400;
        }

        > p {
          font-size: 16px;
          color: var(--bluish-grey);
        }
      }
    }
  }
`;

export default SCMailboxProtectionCard;
