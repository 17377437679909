import styled from '@emotion/styled';
import { css } from '@emotion/core';

const backgroundColors = {
  icon: 'inherit',
  default: 'var(--black)',
  red: 'var(--red)',
  white: 'var(--light-grey-4)',
  bluishGrey: 'var(--bluish-grey)',
  defaultInfoBanner: 'var(--bluish-grey-gradient)',
  greenInfoBanner: 'var(--green-gradient)',
  orangeInfoBanner: 'var(--orange-gradient)',
  redInfoBanner: 'var(--red-gradient)',
  redOutline: 'var(--white)',
};

const fontColors = {
  icon: 'var(--bluish-grey)',
  default: 'var(--skull-white)',
  white: 'var(--bluish-grey)',
  bluishGrey: 'var(--light-grey-4)',
  defaultInfoBanner: 'var(--light-grey-4)',
  greenInfoBanner: 'var(--white)',
  orangeInfoBanner: 'var(--white)',
  redInfoBanner: 'var(--white)',
  redOutline: 'var(--red)',
};

const borders = {
  icon: 'none',
  default: 'none',
  white: '1px solid var(--light-grey-3)',
  redOutline: '1px solid var(--red)',
  // greenInfoBanner: '1px solid var(--green)',
  // orangeInfoBanner: '1px solid var(--orange)',
  // redInfoBanner: '1px solid var(--red)',
};

const hoverBackgroundColors = {
  icon: 'inherit',
  default: 'var(--black)',
  red: 'var(--red-hover)',
  white: 'var(--white)',
  bluishGrey: 'var(--bluish-grey-hover)',
  defaultInfoBanner: 'var(--bluish-grey-gradient)',
  greenInfoBanner: 'var(--green-gradient)',
  orangeInfoBanner: 'var(--orange-gradient)',
  redInfoBanner: 'var(--red-gradient)',
  redOutline: 'var(--white)',
};

const hoverTextColors = {
  icon: 'var(--bluish-grey)',
  default: 'var(--skull-white)',
  white: 'var(--bluish-grey)',
  defaultInfoBanner: 'var(--skull-white)',
  greenInfoBanner: 'var(--white)',
  orangeInfoBanner: 'var(--white)',
  redInfoBanner: 'var(--white)',
  redOutline: 'var(--red)',
};

const full = () => css`
  width: 100%;
  min-height: 50px;
  font-family: var(--font1);
  font-weight: 500;
`;

const large = () => css`
  min-height: 50px;
  font-family: var(--font1);
  font-weight: 500;
`;

const small = () => css`
  min-height: 35px;
  min-width: 100px;
  font-family: var(--font2);
  font-weight: 500;
`;

const mid = () => css`
  height: 35px;
  min-width: 210px;
  font-family: var(--font2);
  font-weight: 500;
`;

const customMid = () => css`
  height: 35px;
  min-width: 200px;
  font-family: var(--font2);
  font-weight: 500;
`;

const fit = () => css`
  min-height: 35px;
  font-family: var(--font2);
  font-weight: 500;
  min-width: fit-content;
`;

const landing = () => css`
  min-height: 49px;
  min-width: 190px;
  font-family: var(--font1);
  font-weight: 600;
`;

const buttonStyles = {
  large,
  small,
  mid,
  customMid,
  full,
  fit,
  landing,
};

const SCButton = styled.button`
  font-size: 16px;
  color: ${({ color }) => fontColors[color] || fontColors.default};
  border-radius: var(--btn-radius);
  border-style: none;
  background: ${({ color }) =>
    backgroundColors[color] || backgroundColors.default};
  display: inline-block;
  min-width: ${({ width }) => width || '60px'};
  height: fit-content;
  border: ${({ color }) => borders[color] || borders.default};
  padding: 0px 15px;
  cursor: pointer;
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
  ${({ size }) => buttonStyles[size] || buttonStyles.large};

  .icon-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  &:focus {
    outline: none !important;
  }

  &:hover {
    background: ${({ color }) =>
      hoverBackgroundColors[color] || hoverBackgroundColors.default};
    color: ${({ color }) => hoverTextColors[color] || hoverTextColors.default};
    transition: var(--transition-delay) background;
  }

  &[disabled] {
    opacity: 0.4;
    cursor: default;
    &:hover {
      // color: none;
      // background: none;
    }
  }

  & svg {
    margin: 0px;
  }
`;

export default SCButton;
