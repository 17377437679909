import styled from '@emotion/styled/macro';

const SCDetailView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  span {
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .content-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5px;
    row-gap: 2px;

    .full-width {
      grid-column: span 2;
    }

    .full-text {
      white-space: normal;
    }
  }

  .content-grid-triple-col {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 5px;
    row-gap: 2px;

    .column-span-3 {
      grid-column: span 3;
    }
  }

  @media (width < 1300px) {
    .content-grid {
      grid-template-columns: auto;

      .full-width {
        grid-column: auto;
      }
    }
  }
`;

export default SCDetailView;
