import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import {
  faBarsFilter,
  faCalendarClock,
  faChartSimple,
  faCompass,
  faEnvelopeCircleCheck,
  faEnvelopesBulk,
  faGauge,
  faLaptopMobile,
  faMailboxFlagUp,
  faUserGroup,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { Select, Skeleton, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SCPartnerDashboard from './PartnerDashboard.style';
import { SUBSCRIPTIONS_PRODUCTS } from '../../utils/constants/subscription';
import {
  getBarChartOptions,
  getDonutChartOptions,
} from '../../utils/chartFactory/chartFactory';
import { useTranslation } from '../../i18n';
import SectionCard from '../../components/molecules/SectionCard/SectionCard';
import usePartnerClients from '../../hooks/usePartnerClients/usePartnerClients';
import {
  PARTNER_CLIENT_SUBSCRIPTIONS_STATUS,
  getSubscriptionStatus,
} from '../PartnerClients/PartnerClients';
import { getDateFormat } from '../../utils/format/dataFormat';
import KPICard from '../../components/molecules/KPICard/KPICard';
import { getLicensesByType } from '../../utils/functions/licenses';
import KPI from '../../components/molecules/KPI/KPI';
import { PROTECTION_TYPES } from '../../utils/constants/licenses';
import useLicenses from '../../hooks/licenses/useLicenses';

const ChartSkeleton = () => (
  <Skeleton
    active
    title={false}
    paragraph={{ rows: 3, width: ['30%', '100%', '50%'] }}
  />
);

const WIDGET = {
  MALWARE_PROTECTION: 'MALWARE_PROTECTION',
  WEB_PROTECTION: 'WEB_PROTECTION',
  MAILBOX_PROTECTION: 'MAILBOX_PROTECTION',
  PROTECTION_LEVEL: 'PROTECTION_LEVEL',
  SUBSCRIPTION_STATE: 'SUBSCRIPTION_STATE',
  LICENSES_RANGE: 'LICENSES_RANGE',
};

const getOperatorFilterConfig = (licensesRangeFilter) => {
  return {
    MALWARE_PROTECTION_INSTALLED_LICENSES: {
      key: 'MALWARE_PROTECTION_INSTALLED_LICENSES',
      callback: ({ subscription, licensesCounter }) => {
        const { malwareProtectionLicenses } = getLicensesByType(
          subscription.licenses,
          licensesCounter.licensesSent,
          licensesCounter.licensesUsed
        );

        return malwareProtectionLicenses.used > 0;
      },
    },
    MALWARE_PROTECTION_SENT_NOT_INSTALLED_LICENSES: {
      key: 'MALWARE_PROTECTION_SENT_NOT_INSTALLED_LICENSES',
      callback: ({ subscription, licensesCounter }) => {
        const { malwareProtectionLicenses } = getLicensesByType(
          subscription.licenses,
          licensesCounter.licensesSent,
          licensesCounter.licensesUsed
        );

        return malwareProtectionLicenses.sentNotInstalled > 0;
      },
    },
    MALWARE_PROTECTION_AVAILABLE_TO_SEND: {
      key: 'MALWARE_PROTECTION_AVAILABLE_TO_SEND',
      callback: ({ subscription, licensesCounter }) => {
        const { malwareProtectionLicenses } = getLicensesByType(
          subscription.licenses,
          licensesCounter.licensesSent,
          licensesCounter.licensesUsed
        );

        return malwareProtectionLicenses.availableToSend > 0;
      },
    },
    WEB_PROTECTION_INSTALLED_LICENSES: {
      key: 'WEB_PROTECTION_INSTALLED_LICENSES',
      callback: ({ subscription, licensesCounter }) => {
        const { webProtectionLicenses } = getLicensesByType(
          subscription.licenses,
          licensesCounter.licensesSent,
          licensesCounter.licensesUsed
        );

        return webProtectionLicenses.used > 0;
      },
    },
    WEB_PROTECTION_SENT_NOT_INSTALLED_LICENSES: {
      key: 'WEB_PROTECTION_SENT_NOT_INSTALLED_LICENSES',
      callback: ({ subscription, licensesCounter }) => {
        const { webProtectionLicenses } = getLicensesByType(
          subscription.licenses,
          licensesCounter.licensesSent,
          licensesCounter.licensesUsed
        );

        return webProtectionLicenses.sentNotInstalled > 0;
      },
    },
    WEB_PROTECTION_AVAILABLE_TO_SEND: {
      key: 'WEB_PROTECTION_AVAILABLE_TO_SEND',
      callback: ({ subscription, licensesCounter }) => {
        const { webProtectionLicenses } = getLicensesByType(
          subscription.licenses,
          licensesCounter.licensesSent,
          licensesCounter.licensesUsed
        );

        return webProtectionLicenses.availableToSend > 0;
      },
    },
    MAILBOX_PROTECTION_ACTIVE: {
      key: 'MAILBOX_PROTECTION_ACTIVE',
      callback: ({ protection }) => protection.email,
    },
    MAILBOX_PROTECTION_PROTECTED: {
      key: 'MAILBOX_PROTECTION_PROTECTED',
      callback: ({ status }) => status.protectedMailboxes.quantity > 0,
    },
    MAILBOX_PROTECTION_EXCESS: {
      key: 'MAILBOX_PROTECTION_EXCESS',
      callback: ({ subscription, status }) =>
        subscription.licenses.emailProtection <
        status.protectedMailboxes.quantity,
    },
    PROTECTION_LEVEL_UNKNOWN: {
      key: 'PROTECTION_LEVEL_UNKNOWN',
      callback: ({ scoring }) => {
        const score = scoring.lastValue;
        return score === undefined || score === null || score > 10 || score < 0;
      },
    },
    PROTECTION_LEVEL_CRITICAL: {
      key: 'PROTECTION_LEVEL_CRITICAL',
      callback: ({ scoring }) => {
        const score = scoring.lastValue;
        return score !== null && score >= 0 && score < 2.5;
      },
    },
    PROTECTION_LEVEL_LOW: {
      key: 'PROTECTION_LEVEL_LOW',
      callback: ({ scoring }) => {
        const score = scoring.lastValue;
        return score >= 2.5 && score < 5;
      },
    },
    PROTECTION_LEVEL_MEDIUM: {
      key: 'PROTECTION_LEVEL_MEDIUM',
      callback: ({ scoring }) => {
        const score = scoring.lastValue;
        return score >= 5 && score < 7.5;
      },
    },
    PROTECTION_LEVEL_HIGH: {
      key: 'PROTECTION_LEVEL_HIGH',
      callback: ({ scoring }) => {
        const score = scoring.lastValue;
        return score >= 7.5 && score <= 10;
      },
    },
    SUBSCRIPTION_STATE_EXPIRED: {
      key: 'SUBSCRIPTION_STATE_EXPIRED',
      callback: ({ status }) =>
        status.active < new Date() ||
        status.serviceLevelIDs[0] === SUBSCRIPTIONS_PRODUCTS.DEFAULT,
    },
    SUBSCRIPTION_STATE_LESS_THAN_3_MONTHS: {
      key: 'SUBSCRIPTION_STATE_LESS_THAN_3_MONTHS',
      callback: ({ status }) => {
        const renewalDate = new Date(status.nextRenewalAt);
        return (
          status.serviceLevelIDs[0] === SUBSCRIPTIONS_PRODUCTS.PAYING &&
          renewalDate <= threeMonthsAfterNow
        );
      },
    },
    SUBSCRIPTION_STATE_MORE_THAN_3_MONTHS: {
      key: 'SUBSCRIPTION_STATE_MORE_THAN_3_MONTHS',
      callback: ({ status }) => {
        const renewalDate = new Date(status.nextRenewalAt);

        return (
          status.serviceLevelIDs[0] === SUBSCRIPTIONS_PRODUCTS.PAYING &&
          renewalDate > threeMonthsAfterNow
        );
      },
    },
    LICENSES_RANGE_MORE_THAN_50: {
      key: 'LICENSES_RANGE_MORE_THAN_50',
      callback: ({ subscription }) =>
        subscription.licenses[licensesRangeFilter] > 50,
    },
    LICENSES_RANGE_BETWEEN_10_AND_50: {
      key: 'LICENSES_RANGE_BETWEEN_10_AND_50',
      callback: ({ subscription }) => {
        const nbLicenses = subscription.licenses[licensesRangeFilter];

        return nbLicenses >= 10 && nbLicenses <= 50;
      },
    },
    LICENSES_RANGE_LESS_THAN_10: {
      key: 'LICENSES_RANGE_LESS_THAN_10',
      callback: ({ subscription }) =>
        subscription.licenses[licensesRangeFilter] < 10,
    },
  };
};

const donutChartResponsiveOptions = [
  {
    breakpoint: 550,
    options: {
      chart: {
        width: '100%',
        height: '250',
      },
      legend: {
        position: 'bottom',
      },
    },
  },
];

const averageScores = (lastAvg, n, currentValue) => {
  return (currentValue + n * lastAvg) / (n + 1);
};

const threeMonthsAfterNow = new Date().setMonth(new Date().getMonth() + 3);

const getClientsData = (clients, licensesRangeFilter) =>
  clients.reduce(
    (sum, { status, scoring, subscription, licensesCounter, protection }) => {
      const currentServiceLevel = status.serviceLevelIDs[0];
      const score = scoring.lastValue;
      const subscriptionRenewalDate = new Date(status.nextRenewalAt);
      const { licenses } = subscription;
      const { malwareProtectionLicenses, webProtectionLicenses } =
        getLicensesByType(
          licenses,
          licensesCounter.licensesSent,
          licensesCounter.licensesUsed
        );
      const nbLicensesRangeFilter = licenses[licensesRangeFilter];

      return {
        totalClients: sum.totalClients + 1,
        malwareLicenses: {
          total: sum.malwareLicenses.total + licenses.malwareProtection,
          sent: sum.malwareLicenses.sent + malwareProtectionLicenses.sent,
          used: sum.malwareLicenses.used + malwareProtectionLicenses.used,
        },
        webLicenses: {
          total: sum.webLicenses.total + licenses.webProtection,
          sent: sum.webLicenses.sent + webProtectionLicenses.sent,
          used: sum.webLicenses.used + webProtectionLicenses.used,
        },
        emailLicenses: {
          nbClientsActiveProtection: protection.email
            ? sum.emailLicenses.nbClientsActiveProtection + 1
            : sum.emailLicenses.nbClientsActiveProtection,
          protectedMailboxes:
            sum.emailLicenses.protectedMailboxes +
            status.protectedMailboxes.quantity,
          totalMailboxes:
            sum.emailLicenses.totalMailboxes + licenses.emailProtection,
          clientsWithExcessProtectedMailboxes:
            licenses.emailProtection < status.protectedMailboxes.quantity
              ? sum.emailLicenses.clientsWithExcessProtectedMailboxes + 1
              : sum.emailLicenses.clientsWithExcessProtectedMailboxes,
        },
        purchasedLicenses: {
          lessThan10:
            nbLicensesRangeFilter < 10
              ? sum.purchasedLicenses.lessThan10 + 1
              : sum.purchasedLicenses.lessThan10,
          between10And50:
            nbLicensesRangeFilter >= 10 && nbLicensesRangeFilter <= 50
              ? sum.purchasedLicenses.between10And50 + 1
              : sum.purchasedLicenses.between10And50,
          moreThan50:
            nbLicensesRangeFilter > 50
              ? sum.purchasedLicenses.moreThan50 + 1
              : sum.purchasedLicenses.moreThan50,
        },
        subscriptionStatus: {
          expired:
            status.active < new Date() ||
            currentServiceLevel === SUBSCRIPTIONS_PRODUCTS.DEFAULT
              ? sum.subscriptionStatus.expired + 1
              : sum.subscriptionStatus.expired,
          lessThan3Months:
            currentServiceLevel === SUBSCRIPTIONS_PRODUCTS.PAYING &&
            subscriptionRenewalDate <= threeMonthsAfterNow
              ? sum.subscriptionStatus.lessThan3Months + 1
              : sum.subscriptionStatus.lessThan3Months,
          moreThan3Months:
            currentServiceLevel === SUBSCRIPTIONS_PRODUCTS.PAYING &&
            subscriptionRenewalDate > threeMonthsAfterNow
              ? sum.subscriptionStatus.moreThan3Months + 1
              : sum.subscriptionStatus.moreThan3Months,
        },
        avgScore: {
          avg: score
            ? averageScores(sum.avgScore.avg, sum.avgScore.n, score)
            : sum.avgScore.avg,
          n: score ? sum.avgScore.n + 1 : sum.avgScore.n,
        },
        protectionLevel: {
          unknown:
            score === undefined || score === null || score > 10 || score < 0
              ? sum.protectionLevel.unknown + 1
              : sum.protectionLevel.unknown,
          critical:
            score !== null && score >= 0 && score < 2.5
              ? sum.protectionLevel.critical + 1
              : sum.protectionLevel.critical,
          low:
            score >= 2.5 && score < 5
              ? sum.protectionLevel.low + 1
              : sum.protectionLevel.low,
          medium:
            score >= 5 && score < 7.5
              ? sum.protectionLevel.medium + 1
              : sum.protectionLevel.medium,
          high:
            score >= 7.5 && score <= 10
              ? sum.protectionLevel.high + 1
              : sum.protectionLevel.high,
        },
      };
    },
    {
      totalClients: 0,
      malwareLicenses: {
        total: 0,
        sent: 0,
        used: 0,
      },
      webLicenses: {
        total: 0,
        sent: 0,
        used: 0,
      },
      emailLicenses: {
        nbClientsActiveProtection: 0,
        protectedMailboxes: 0,
        totalMailboxes: 0,
        clientsWithExcessProtectedMailboxes: 0,
      },
      purchasedLicenses: {
        lessThan10: 0,
        between10And50: 0,
        moreThan50: 0,
      },
      subscriptionStatus: {
        expired: 0,
        lessThan3Months: 0,
        moreThan3Months: 0,
      },
      avgScore: {
        avg: 0,
        n: 0,
      },
      protectionLevel: {
        unknown: 0,
        critical: 0,
        low: 0,
        medium: 0,
        high: 0,
      },
    }
  );

const PartnerDashboard = () => {
  const subscription = useSelector((redux) => redux.subscription);
  const client = useSelector((redux) => redux.client);
  const user = useSelector((redux) => redux.user);
  const { lang } = useTranslation();
  const i18n = useTranslation();
  const navigate = useNavigate();

  const { licenses, licensesSent, licensesUsed } = useLicenses();
  const { malwareProtectionLicenses, webProtectionLicenses } =
    getLicensesByType(licenses, licensesSent, licensesUsed);
  const {
    emailProtection = 0,
    malwareProtection = 0,
    webProtection = 0,
  } = licenses;

  const { partnerClients, loading } = usePartnerClients();

  const [filter, setFilter] = useState(null);
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: 'score',
    order: 'descend',
  });
  const [licensesRangeFilter, setLicensesRangeFilter] = useState(
    PROTECTION_TYPES.MALWARE
  );

  useEffect(() => {
    const sortedFields = {
      [WIDGET.MALWARE_PROTECTION]: 'malwareProtection',
      [WIDGET.WEB_PROTECTION]: 'webProtection',
      [WIDGET.MAILBOX_PROTECTION]: 'emailProtection',
      [WIDGET.PROTECTION_LEVEL]: 'score',
      [WIDGET.SUBSCRIPTION_STATE]: 'expiredAt',
      [WIDGET.LICENSES_RANGE]: licensesRangeFilter,
    };
    const DEFAULT_FIELD = sortedFields[WIDGET.PROTECTION_LEVEL];

    setSortedInfo({
      columnKey: filter?.field ? sortedFields[filter?.field] : DEFAULT_FIELD,
      order: 'descend',
    });
  }, [filter?.field, licensesRangeFilter]);

  useEffect(() => {
    const metadata = {
      language: lang,
      name: `${user?.firstName} ${user?.lastName}`,
      email: user?.email,
      country: process.env.REACT_APP_ENVIRONMENT,
      userName: `${user?.firstName} ${user?.lastName}`,
      userEmail: user?.email,
      userEmailDomain: client?.atEmail,
      userDetails: `partner: ${client?.partner}`,
      companyName: client?.company?.name,
      companyCif: client?.company?.cif,
      companyWebsite: client?.company?.website,
      subscriptionType: subscription?.product || '-',
      subscriptionStartDate:
        subscription?.startTime?.toDate().toLocaleDateString('es-ES') || '-',
      subscriptionEndDate:
        subscription?.endTime?.toDate().toLocaleDateString('es-ES') || '-',
      subscriptionLicenses: `Total licencias dispositivo:${malwareProtection} Total licencias navegación:${webProtection} Total licencias seguridad correo:${emailProtection}`,
      subscriptionSentLicenses: `Total licencias enviadas dispositivo:${malwareProtectionLicenses.sent} Total licencias enviadas navegación:${webProtectionLicenses.sent}`,
      subscriptionInstalledLicenses: `Total licencias instaladas dispositivo:${malwareProtectionLicenses.used} Total licencias instaladas navegación:${webProtectionLicenses.used}`,
      hash: 'f88628668a083c77dd68e5e43b05f1adf7bcce95e8ed381d3daf8c2f7dc15857',
    };

    if (window.Tawk_API && window.Tawk_API.onLoaded) {
      if (window.Tawk_API) {
        window.Tawk_API.setAttributes(metadata, (error) => {
          if (error) console.log('Tawk_API.setAttributes error', error);
        });
      }
    }
  }, [client, subscription, lang]);

  const OPERATOR_FILTER = getOperatorFilterConfig(licensesRangeFilter);

  const partnerClientsFiltered = filter?.operator
    ? partnerClients.filter(OPERATOR_FILTER[filter.operator].callback)
    : partnerClients;

  const allClientsData = getClientsData(partnerClients, licensesRangeFilter);
  const filteredClientsData = getClientsData(
    partnerClientsFiltered,
    licensesRangeFilter
  );

  const getClientsByField = (field) =>
    filter?.field === field ? allClientsData : filteredClientsData;

  const {
    total: totalMalwareLicenses,
    sent: sentMalwareLicenses,
    used: usedMalwareLicenses,
  } = getClientsByField(WIDGET.MALWARE_PROTECTION).malwareLicenses;
  const malwareLicenses = {
    total: totalMalwareLicenses,
    used: usedMalwareLicenses,
    sentNotInstalled: sentMalwareLicenses - usedMalwareLicenses,
    availableToSend: totalMalwareLicenses - sentMalwareLicenses,
  };

  const {
    total: totalWebLicenses,
    sent: sentWebLicenses,
    used: usedWebLicenses,
  } = getClientsByField(WIDGET.WEB_PROTECTION).webLicenses;
  const webLicenses = {
    total: totalWebLicenses,
    used: usedWebLicenses,
    sentNotInstalled: sentWebLicenses - usedWebLicenses,
    availableToSend: totalWebLicenses - sentWebLicenses,
  };

  const { emailLicenses, totalClients: totalClientsMailboxProtection } =
    getClientsByField(WIDGET.MAILBOX_PROTECTION);

  const {
    nbClientsActiveProtection,
    totalMailboxes,
    protectedMailboxes,
    clientsWithExcessProtectedMailboxes,
  } = emailLicenses;

  const {
    unknown: unknownProtectionLevel,
    critical: criticalProtectionLevel,
    low: lowProtectionLevel,
    medium: mediumProtectionLevel,
    high: highProtectionLevel,
  } = getClientsByField(WIDGET.PROTECTION_LEVEL).protectionLevel;

  const {
    expired: expiredSubscriptionStatus,
    lessThan3Months: lessThan3MonthsSubscriptionStatus,
    moreThan3Months: moreThan3MonthsSubscriptionStatus,
  } = getClientsByField(WIDGET.SUBSCRIPTION_STATE).subscriptionStatus;

  const {
    lessThan10: lessThan10PurchasedLicenses,
    between10And50: between10And50PurchasedLicenses,
    moreThan50: moreThan50PurchasedLicenses,
  } = getClientsByField(WIDGET.LICENSES_RANGE).purchasedLicenses;

  const nClients = partnerClientsFiltered.length;

  const chartValuesFormatter = (value) => {
    return value % 1 !== 0 ? value.toFixed(1) : value.toFixed(0);
  };

  const updateFilter = (newFilter) => {
    setFilter((prevValue) =>
      prevValue?.operator === newFilter.operator ? null : newFilter
    );
  };

  const CHART_OPTIONS_MALWARE_PROTECTION = {
    series: malwareLicenses.total
      ? [
          malwareLicenses.used,
          malwareLicenses.sentNotInstalled,
          malwareLicenses.availableToSend,
        ]
      : [1],
    options: getDonutChartOptions({
      labels: malwareLicenses.total
        ? [
            i18n.t('controlPanel.widgetSubscription.licensesInstalled'),
            i18n.t('controlPanel.widgetSubscription.licensesSent'),
            i18n.t('controlPanel.widgetSubscription.lisensesAvailable'),
          ]
        : [i18n.t('controlPanel.widgetSubscription.lisensesAvailable')],
      colors: malwareLicenses.total
        ? ['var(--green)', 'var(--light-green2)', 'var(--light-grey-3)']
        : ['var(--light-grey-3)'],
      dataLabelsColors: malwareLicenses.total
        ? ['var(--light-grey-4)', 'var(--light-grey-4)', 'var(--bluish-grey)']
        : ['var(--bluish-grey)'],
      dataLabelsEnable: !!malwareLicenses.total,
      legendWidth: 200,
      responsive: donutChartResponsiveOptions,
      dataPointSelectionEvent: (event, chartContext, config) => {
        const OPERATORS = {
          0: OPERATOR_FILTER.MALWARE_PROTECTION_INSTALLED_LICENSES.key,
          1: OPERATOR_FILTER.MALWARE_PROTECTION_SENT_NOT_INSTALLED_LICENSES.key,
          2: OPERATOR_FILTER.MALWARE_PROTECTION_AVAILABLE_TO_SEND.key,
        };

        const auxFilter = {
          field: WIDGET.MALWARE_PROTECTION,
          operator: OPERATORS[config.dataPointIndex],
        };

        updateFilter(auxFilter);
      },
    }),
  };

  const CHART_OPTIONS_WEB_PROTECTION = {
    series: webLicenses.total
      ? [
          webLicenses.used,
          webLicenses.sentNotInstalled,
          webLicenses.availableToSend,
        ]
      : [1],
    options: getDonutChartOptions({
      labels: webLicenses.total
        ? [
            i18n.t('controlPanel.widgetSubscription.licensesInstalled'),
            i18n.t('controlPanel.widgetSubscription.licensesSent'),
            i18n.t('controlPanel.widgetSubscription.lisensesAvailable'),
          ]
        : [i18n.t('controlPanel.widgetSubscription.lisensesAvailable')],
      colors: webLicenses.total
        ? ['var(--green)', 'var(--light-green2)', 'var(--light-grey-3)']
        : ['var(--light-grey-3)'],
      dataLabelsColors: webLicenses.total
        ? ['var(--light-grey-4)', 'var(--light-grey-4)', 'var(--bluish-grey)']
        : ['var(--bluish-grey)'],
      dataLabelsEnable: !!webLicenses.total,
      legendWidth: 200,
      responsive: donutChartResponsiveOptions,
      dataPointSelectionEvent: (event, chartContext, config) => {
        const OPERATORS = {
          0: OPERATOR_FILTER.WEB_PROTECTION_INSTALLED_LICENSES.key,
          1: OPERATOR_FILTER.WEB_PROTECTION_SENT_NOT_INSTALLED_LICENSES.key,
          2: OPERATOR_FILTER.WEB_PROTECTION_AVAILABLE_TO_SEND.key,
        };

        const auxFilter = {
          field: WIDGET.WEB_PROTECTION,
          operator: OPERATORS[config.dataPointIndex],
        };

        updateFilter(auxFilter);
      },
    }),
  };

  const CHART_OPTIONS_PROTECTION_LEVEL = {
    series:
      partnerClientsFiltered.length > 0
        ? [
            unknownProtectionLevel,
            criticalProtectionLevel,
            lowProtectionLevel,
            mediumProtectionLevel,
            highProtectionLevel,
          ]
        : [1],
    options: getDonutChartOptions({
      labels:
        partnerClientsFiltered.length > 0
          ? [
              i18n.t('partner.dashboard.protectionLevelChart.labels.unknown'),
              i18n.t('partner.dashboard.protectionLevelChart.labels.critical'),
              i18n.t('partner.dashboard.protectionLevelChart.labels.low'),
              i18n.t('partner.dashboard.protectionLevelChart.labels.medium'),
              i18n.t('partner.dashboard.protectionLevelChart.labels.high'),
            ]
          : [i18n.t('partner.dashboard.protectionLevelChart.labels.unknown')],
      colors:
        partnerClientsFiltered.length > 0
          ? [
              'var(--light-grey-3)',
              'var(--red)',
              'var(--light-red)',
              'var(--orange)',
              'var(--green)',
            ]
          : ['var(--light-grey-3)'],
      dataLabelsColors: [
        'var(--bluish-grey)',
        'var(--white)',
        'var(--bluish-grey)',
        'var(--white)',
        'var(--white)',
      ],
      dataLabelsEnable: partnerClientsFiltered.length > 0,
      legendWidth: 200,
      responsive: donutChartResponsiveOptions,
      dataPointSelectionEvent: (event, chartContext, config) => {
        const OPERATORS = {
          0: OPERATOR_FILTER.PROTECTION_LEVEL_UNKNOWN.key,
          1: OPERATOR_FILTER.PROTECTION_LEVEL_CRITICAL.key,
          2: OPERATOR_FILTER.PROTECTION_LEVEL_LOW.key,
          3: OPERATOR_FILTER.PROTECTION_LEVEL_MEDIUM.key,
          4: OPERATOR_FILTER.PROTECTION_LEVEL_HIGH.key,
        };

        const auxFilter = {
          field: WIDGET.PROTECTION_LEVEL,
          operator: OPERATORS[config.dataPointIndex],
        };

        updateFilter(auxFilter);
      },
    }),
  };

  const CHART_OPTIONS_SUBSCRIPTION_STATE = {
    series: [
      {
        data: [
          expiredSubscriptionStatus,
          lessThan3MonthsSubscriptionStatus,
          moreThan3MonthsSubscriptionStatus,
        ],
      },
    ],
    options: getBarChartOptions({
      categories: [
        i18n.t(
          'partner.dashboard.subscriptionExpirationChart.categories.expired'
        ),
        i18n.t(
          'partner.dashboard.subscriptionExpirationChart.categories.lessThan3Months'
        ),
        i18n.t(
          'partner.dashboard.subscriptionExpirationChart.categories.moreThan3Months'
        ),
      ],
      colors: ['var(--partner-primary)'],
      tooltipFormatter: () => `${i18n.t('common.clients')}:`,
      valuesFormatter: (value) => chartValuesFormatter(value),
      dataPointSelectionEvent: (event, chartContext, config) => {
        const OPERATORS = {
          0: OPERATOR_FILTER.SUBSCRIPTION_STATE_EXPIRED.key,
          1: OPERATOR_FILTER.SUBSCRIPTION_STATE_LESS_THAN_3_MONTHS.key,
          2: OPERATOR_FILTER.SUBSCRIPTION_STATE_MORE_THAN_3_MONTHS.key,
        };

        const auxFilter = {
          field: WIDGET.SUBSCRIPTION_STATE,
          operator: OPERATORS[config.dataPointIndex],
        };

        updateFilter(auxFilter);
      },
    }),
  };

  const CHART_OPTIONS_LICENSES_RANGE = {
    series: [
      {
        data: [
          moreThan50PurchasedLicenses,
          between10And50PurchasedLicenses,
          lessThan10PurchasedLicenses,
        ],
      },
    ],
    options: getBarChartOptions({
      categories: [
        i18n.t(
          'partner.dashboard.purchasedLicensesRangeChart.categories.moreThan50'
        ),
        i18n.t(
          'partner.dashboard.purchasedLicensesRangeChart.categories.between10And50'
        ),
        i18n.t(
          'partner.dashboard.purchasedLicensesRangeChart.categories.lessThan10'
        ),
      ],
      colors: ['var(--partner-primary)'],
      tooltipFormatter: () => `${i18n.t('common.clients')}:`,
      valuesFormatter: (value) => chartValuesFormatter(value),
      dataPointSelectionEvent: (event, chartContext, config) => {
        const OPERATORS = {
          0: OPERATOR_FILTER.LICENSES_RANGE_MORE_THAN_50.key,
          1: OPERATOR_FILTER.LICENSES_RANGE_BETWEEN_10_AND_50.key,
          2: OPERATOR_FILTER.LICENSES_RANGE_LESS_THAN_10.key,
        };

        const auxFilter = {
          field: WIDGET.LICENSES_RANGE,
          operator: OPERATORS[config.dataPointIndex],
        };

        updateFilter(auxFilter);
      },
    }),
  };

  const getFilteredClientsColumns = () => {
    const sortDirections = ['ascend', 'descend', 'ascend'];
    const filterField = filter?.field;

    const getSortOrder = (columnKey) => {
      return sortedInfo.columnKey === columnKey ? sortedInfo.order : null;
    };

    const getLicensesColumnConfig = () => {
      const isRangeFilter = filterField === WIDGET.LICENSES_RANGE;

      if (
        filterField === WIDGET.MALWARE_PROTECTION ||
        (isRangeFilter && licensesRangeFilter === PROTECTION_TYPES.MALWARE)
      ) {
        return {
          columnKey: PROTECTION_TYPES.MALWARE,
          title: i18n.t('profile.billingHistory.malwareLicenses'),
          sorter: (a, b) => a.malwareProtection - b.malwareProtection,
        };
      }

      if (
        filterField === WIDGET.WEB_PROTECTION ||
        (isRangeFilter && licensesRangeFilter === PROTECTION_TYPES.WEB)
      ) {
        return {
          columnKey: PROTECTION_TYPES.WEB,
          title: i18n.t('profile.billingHistory.webLicenses'),
          sorter: (a, b) => a.webProtection - b.webProtection,
        };
      }

      return {
        columnKey: PROTECTION_TYPES.EMAIL,
        title: i18n.t('profile.billingHistory.mailboxLicenses'),
        sorter: (a, b) => a.emailProtection - b.emailProtection,
      };
    };

    const renderCallback = (text) => (
      <span style={{ color: 'var(--bluish-grey)' }}>{text}</span>
    );

    const filteredClientsColumns = [
      {
        title: i18n.t('partner.dashboard.clientsTable.client'),
        dataIndex: 'name',
        key: 'name',
        sortDirections,
        sorter: ({ nameToSort: aNameToSort }, { nameToSort: bNameToSort }) => {
          if (!aNameToSort) return 1;
          if (!bNameToSort) return -1;

          return aNameToSort.localeCompare(bNameToSort);
        },
        sortOrder: getSortOrder('name'),
      },
    ];

    if (!filterField || filterField === WIDGET.PROTECTION_LEVEL) {
      filteredClientsColumns.push({
        title: i18n.t('partner.dashboard.clientsTable.score'),
        dataIndex: 'score',
        key: 'score',
        render: renderCallback,
        sortDirections,
        sorter: (a, b) => a.scoreToSort - b.scoreToSort,
        sortOrder: getSortOrder('score'),
      });
    }

    if (
      [
        WIDGET.LICENSES_RANGE,
        WIDGET.MALWARE_PROTECTION,
        WIDGET.WEB_PROTECTION,
        WIDGET.MAILBOX_PROTECTION,
      ].includes(filterField)
    ) {
      const { columnKey, title, sorter } = getLicensesColumnConfig();

      filteredClientsColumns.push({
        title,
        dataIndex: columnKey,
        key: columnKey,
        render: renderCallback,
        sortDirections,
        sorter,
        sortOrder: getSortOrder(columnKey),
      });
    }

    if (filterField === WIDGET.SUBSCRIPTION_STATE) {
      filteredClientsColumns.push({
        title: i18n.t('partner.dashboard.clientsTable.expiration'),
        dataIndex: 'expiredAt',
        key: 'expiredAt',
        render: renderCallback,
        sortDirections,
        sorter: (a, b) => a.expiredAtToSort - b.expiredAtToSort,
        sortOrder: getSortOrder('expiredAt'),
      });
    }

    return filteredClientsColumns;
  };

  const filteredClientsTableData = partnerClientsFiltered.map(
    ({ id, company, scoring, status, subscription: clientSubscription }) => {
      const subscriptionStatus = getSubscriptionStatus(status);
      const isSubscriptionExpired =
        subscriptionStatus === PARTNER_CLIENT_SUBSCRIPTIONS_STATUS.expired;
      const renewalDate = new Date(status.nextRenewalAt);

      return {
        id,
        name: company.name || '-',
        nameToSort: company.name,
        score: Math.round(scoring.lastValue * 10) / 10 || '-',
        scoreToSort: scoring.lastValue || 0,
        malwareProtection: clientSubscription.licenses.malwareProtection,
        webProtection: clientSubscription.licenses.webProtection,
        emailProtection: clientSubscription.licenses.emailProtection,
        expiredAt: !isSubscriptionExpired ? getDateFormat(renewalDate) : '-',
        expiredAtToSort: !isSubscriptionExpired ? renewalDate : 0,
      };
    }
  );

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handleResetFilter = () => {
    setFilter(null);
  };

  const handleCardHeaderFilter = (field) => () => {
    // Evitamos que estando filtrando por el gráfico, al hacer click en el header de la card, se elimine el filtro
    if (filter?.field !== field) {
      setFilter({
        field,
      });
    }
  };

  const handleMailboxProtectionFilter = (operator) => {
    updateFilter({
      field: WIDGET.MAILBOX_PROTECTION,
      operator,
    });
  };

  return (
    <SCPartnerDashboard>
      {!loading && (
        <div className="filter-container">
          {filter?.operator ? (
            <>
              <FontAwesomeIcon
                icon={faBarsFilter}
                fontSize="16px"
                color="var(--bluish-grey)"
              />
              <span className="filtering-by">
                {i18n.t('partner.dashboard.filter.filteringBy')}
              </span>
              <span className="filter-operator-tag">
                {i18n.t(`partner.dashboard.filter.values.${filter?.operator}`)}
                <button
                  className="close-button"
                  type="button"
                  aria-label="Reset filter button"
                  onClick={handleResetFilter}>
                  <div className="icon-wrapper">
                    <FontAwesomeIcon icon={faXmark} size="xs" />
                  </div>
                </button>
              </span>
            </>
          ) : (
            <div className="filter-info">
              <FontAwesomeIcon
                icon={faCircleInfo}
                fontSize="16px"
                color="var(--bluish-grey)"
              />
              <p>{i18n.t('partner.dashboard.filter.emptyMessage')}</p>
            </div>
          )}
        </div>
      )}

      <div className="charts-table-container">
        <KPICard
          value={nClients}
          title={i18n.t('common.clients')}
          icon={faUserGroup}
          iconBackgroundColor="var(--partner-primary-light)"
          loading={loading}
        />

        <KPICard
          value={Math.round(filteredClientsData.avgScore.avg * 10) / 10}
          title={i18n.t('partner.dashboard.averageScore')}
          icon={faGauge}
          iconBackgroundColor="var(--partner-primary-light)"
          loading={loading}
        />

        {/* Protección de dispositivos */}
        <SectionCard
          headerTitle={i18n.t(
            'controlPanel.protectionLicenses.deviceProtectionTitle'
          )}
          headerIcon={faLaptopMobile}
          headerButtonOnClick={handleCardHeaderFilter(
            WIDGET.MALWARE_PROTECTION
          )}
          isHeaderSelected={filter?.field === WIDGET.MALWARE_PROTECTION}>
          {!loading ? (
            <ReactApexChart
              options={CHART_OPTIONS_MALWARE_PROTECTION.options}
              series={CHART_OPTIONS_MALWARE_PROTECTION.series}
              type="donut"
              height={175}
            />
          ) : (
            <div className="donut-chart-skeleton">
              <ChartSkeleton />
            </div>
          )}
        </SectionCard>

        {/* Protección de navegación */}
        <SectionCard
          headerTitle={i18n.t(
            'controlPanel.protectionLicenses.webProtectionTitle'
          )}
          headerIcon={faCompass}
          headerButtonOnClick={handleCardHeaderFilter(WIDGET.WEB_PROTECTION)}
          isHeaderSelected={filter?.field === WIDGET.WEB_PROTECTION}>
          {!loading ? (
            <ReactApexChart
              options={CHART_OPTIONS_WEB_PROTECTION.options}
              series={CHART_OPTIONS_WEB_PROTECTION.series}
              type="donut"
              height={175}
            />
          ) : (
            <div className="donut-chart-skeleton">
              <ChartSkeleton />
            </div>
          )}
        </SectionCard>

        {/* Protección de buzones */}
        <SectionCard
          headerIcon={faEnvelopesBulk}
          headerTitle={i18n.t('controlPanel.mailboxProtection.title')}
          headerButtonOnClick={handleCardHeaderFilter(
            WIDGET.MAILBOX_PROTECTION
          )}
          isHeaderSelected={filter?.field === WIDGET.MAILBOX_PROTECTION}>
          <div className="mailbox-protection-kpi-container">
            <button
              type="button"
              aria-label="filter by active mailbox protection"
              className="kpi-filter-button"
              onClick={() => {
                handleMailboxProtectionFilter(
                  OPERATOR_FILTER.MAILBOX_PROTECTION_ACTIVE.key
                );
              }}>
              <KPI
                title={i18n.t(
                  'partner.dashboard.mailboxProtection.clientsMailboxActive'
                )}
                icon={faEnvelopeCircleCheck}
                value={nbClientsActiveProtection}
                total={totalClientsMailboxProtection}
                loading={loading}
              />
            </button>

            <button
              type="button"
              aria-label="filter by mailbox licenses"
              className="kpi-filter-button"
              onClick={() => {
                handleMailboxProtectionFilter(
                  OPERATOR_FILTER.MAILBOX_PROTECTION_PROTECTED.key
                );
              }}>
              <KPI
                title={i18n.t(
                  'partner.dashboard.mailboxProtection.protectedMailboxes'
                )}
                icon={faMailboxFlagUp}
                value={protectedMailboxes}
                total={totalMailboxes}
                loading={loading}
              />
            </button>
            {clientsWithExcessProtectedMailboxes > 0 && (
              <button
                type="button"
                aria-label="filtrar por clientes protegiendo más buzones que licencias tienen contratadas"
                className="excess-licenses-button"
                onClick={() => {
                  handleMailboxProtectionFilter(
                    OPERATOR_FILTER.MAILBOX_PROTECTION_EXCESS.key
                  );
                }}>
                {i18n.t('partner.dashboard.mailboxProtection.excessLicenses')}
              </button>
            )}
          </div>
        </SectionCard>

        <SectionCard
          headerTitle={i18n.t('partner.dashboard.protectionLevelChart.title')}
          headerIcon={faGauge}
          headerButtonOnClick={handleCardHeaderFilter(WIDGET.PROTECTION_LEVEL)}
          isHeaderSelected={filter?.field === WIDGET.PROTECTION_LEVEL}>
          {!loading ? (
            <ReactApexChart
              options={CHART_OPTIONS_PROTECTION_LEVEL.options}
              series={CHART_OPTIONS_PROTECTION_LEVEL.series}
              type="donut"
              height={175}
            />
          ) : (
            <div className="donut-chart-skeleton">
              <ChartSkeleton />
            </div>
          )}
        </SectionCard>

        <SectionCard
          headerTitle={i18n.t(
            'partner.dashboard.subscriptionExpirationChart.title'
          )}
          headerIcon={faCalendarClock}
          headerButtonOnClick={handleCardHeaderFilter(
            WIDGET.SUBSCRIPTION_STATE
          )}
          isHeaderSelected={filter?.field === WIDGET.SUBSCRIPTION_STATE}>
          {!loading ? (
            <div className="range-chart-container">
              <ReactApexChart
                options={CHART_OPTIONS_SUBSCRIPTION_STATE.options}
                series={CHART_OPTIONS_SUBSCRIPTION_STATE.series}
                type="bar"
                width="100%"
                height="200px"
              />
            </div>
          ) : (
            <div className="bar-chart-skeleton">
              <ChartSkeleton />
            </div>
          )}
        </SectionCard>

        <SectionCard
          headerTitle={i18n.t(
            'partner.dashboard.purchasedLicensesRangeChart.title'
          )}
          headerIcon={faChartSimple}
          headerButtonOnClick={handleCardHeaderFilter(WIDGET.LICENSES_RANGE)}
          isHeaderSelected={filter?.field === WIDGET.LICENSES_RANGE}>
          {!loading ? (
            <div className="range-chart-container">
              <Select
                value={licensesRangeFilter}
                style={{ width: 150 }}
                onChange={setLicensesRangeFilter}
                options={[
                  {
                    value: PROTECTION_TYPES.MALWARE,
                    label: i18n.t(
                      'partner.dashboard.purchasedLicensesRangeChart.licensesRangeFilter.malware'
                    ),
                  },
                  {
                    value: PROTECTION_TYPES.WEB,
                    label: i18n.t(
                      'partner.dashboard.purchasedLicensesRangeChart.licensesRangeFilter.web'
                    ),
                  },
                  {
                    value: PROTECTION_TYPES.EMAIL,
                    label: i18n.t(
                      'partner.dashboard.purchasedLicensesRangeChart.licensesRangeFilter.email'
                    ),
                  },
                ]}
              />

              <ReactApexChart
                options={CHART_OPTIONS_LICENSES_RANGE.options}
                series={CHART_OPTIONS_LICENSES_RANGE.series}
                type="bar"
                width="100%"
                height="200px"
              />
            </div>
          ) : (
            <div className="bar-chart-skeleton">
              <ChartSkeleton />
            </div>
          )}
        </SectionCard>

        <SectionCard className="clients-table" hasPadding={false}>
          <Table
            columns={getFilteredClientsColumns()}
            dataSource={filteredClientsTableData}
            pagination={false}
            scroll={{ y: 690 }}
            rowKey={(record) => record.id}
            onRow={(record) => {
              return {
                onClick: () =>
                  navigate(`/partner/clients/control-panel/${record.id}`),
              };
            }}
            rowClassName={() => 'cursor-pointer'}
            onChange={handleChange}
            locale={{
              ...(loading && {
                emptyText: <Skeleton active />,
              }),
            }}
          />
        </SectionCard>
      </div>
    </SCPartnerDashboard>
  );
};

export default PartnerDashboard;
