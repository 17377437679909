import React from 'react';
import { Form, Input, DatePicker } from 'antd';
import SCCustomCard from './CustomCard.style';

const SubscriptionCard = () => {
  return (
    <SCCustomCard title="Suscripción" size="small" bordered>
      <div className="content-grid-6-col">
        <Form.Item
          label={
            <span>
              <strong>Email cliente</strong>
            </span>
          }
          name={['subscription', 'clientEmail']}
          className="column-span-6">
          <Input disabled size="small" placeholder="N/A" />
        </Form.Item>

        <Form.Item
          label={
            <span>
              <strong>Lic. Dispositivos</strong>
            </span>
          }
          name={['subscription', 'licenses', 'malwareProtection']}
          className="column-span-2">
          <Input disabled size="small" placeholder="N/A" />
        </Form.Item>

        <Form.Item
          label={
            <span>
              <strong>Lic. Navegación</strong>
            </span>
          }
          name={['subscription', 'licenses', 'webProtection']}
          className="column-span-2">
          <Input disabled size="small" placeholder="N/A" />
        </Form.Item>

        <Form.Item
          label={
            <span>
              <strong>Lic. Buzones</strong>
            </span>
          }
          name={['subscription', 'licenses', 'emailProtection']}
          className="column-span-2">
          <Input disabled size="small" placeholder="N/A" />
        </Form.Item>

        {/* Creamos una fila para Fecha Inicio y Fecha Fin */}
        <Form.Item
          label={
            <span>
              <strong>Fecha inicio</strong>
            </span>
          }
          name={['subscription', 'startTime']}
          className="column-span-3">
          <DatePicker
            format="DD/MM/YYYY"
            disabled
            size="small"
            placeholder="N/A"
          />
        </Form.Item>

        <Form.Item
          label={
            <span>
              <strong>Fecha fin</strong>
            </span>
          }
          name={['subscription', 'endTime']}
          className="column-span-3">
          <DatePicker
            format="DD/MM/YYYY"
            disabled
            size="small"
            placeholder="N/A"
          />
        </Form.Item>
      </div>
    </SCCustomCard>
  );
};

export default SubscriptionCard;
