import React from 'react';
import { Card, Tag } from 'antd';
import SCDetailView from './DetailView.style';

const DetailView = ({ data }) => {
  return (
    <SCDetailView>
      <Card title="Contacto" size="small" bordered>
        <div className="content-grid">
          <span>
            <strong>Nombre: </strong>
            {data.name}
          </span>
          <span>
            <strong>Cargo: </strong>
            {data.position}
          </span>
          <span>
            <strong>Email: </strong>
            {data.email}
          </span>
          <span>
            <strong>Teléfono: </strong>
            {data.phone}
          </span>
          <span>
            <strong>Fecha creación: </strong>
            {new Date(data.createdAt).toLocaleDateString()}
          </span>
          <span>
            <strong>Partner: </strong>
            {data.eventName}
          </span>
          <span>
            <strong>Cláusula contacto: </strong>
            <Tag
              color={data.agreements.contactAllowed ? 'green' : 'red'}
              style={{ verticalAlign: 'middle' }}>
              {data.agreements.contactAllowed ? 'Sí' : 'No'}
            </Tag>
          </span>
        </div>
      </Card>
      <Card title="Empresa" size="small" bordered>
        <div className="content-grid">
          <span>
            <strong>Nombre: </strong>
            {data.company.name}
          </span>
          <span>
            <strong>Razón social: </strong>
            {data.company.legalName}
          </span>
          <span>
            <strong>CIF: </strong>
            {data.company.cif}
          </span>
          <span>
            <strong>Nº empleados: </strong>
            {data.company.numberEmployees}
          </span>
          <span>
            <strong>Dominio web: </strong>
            {data.company.website}
          </span>
          <span>
            <strong>Dominio correo: </strong>
            {data.atEmail ? `@${data.atEmail}` : ''}
          </span>
          {data.eventName === 'santander' && (
            <span>
              <strong>Email gestor: </strong>
              {data.emailManager}
            </span>
          )}
        </div>
      </Card>
      <Card title="Proceso de venta" size="small" bordered>
        <div className="content-grid">
          <span>
            <strong>Propietario: </strong>
            {data.leadOwner}
          </span>
          <span>
            <strong>Estado: </strong>
            {data.stepDescription}
          </span>
          <span className="full-width full-text">
            <strong>Notas: </strong>
            {data.notes}
          </span>
        </div>
      </Card>
      <Card title="Suscripción" size="small" bordered>
        <div className="content-grid-triple-col">
          <span className="column-span-3">
            <strong>Email cliente: </strong>
            {data?.subscription?.clientEmail
              ? data?.subscription?.clientEmail
              : 'N/A'}
          </span>
          <span>
            <strong>Lic. Dispositivos: </strong>
            {data?.subscription?.licenses?.malwareProtection ?? 'N/A'}
          </span>
          <span>
            <strong>Lic. Navegación: </strong>
            {data?.subscription?.licenses?.webProtection ?? 'N/A'}
          </span>
          <span>
            <strong>Lic. Buzones: </strong>
            {data?.subscription?.licenses?.emailProtection ?? 'N/A'}
          </span>
          <span>
            <strong>Fecha inicio: </strong>
            {data?.subscription?.startTime
              ? new Date(data.subscription?.startTime).toLocaleDateString()
              : 'N/A'}
          </span>
          <span>
            <strong>Fecha fin: </strong>
            {data?.subscription?.endTime
              ? new Date(data.subscription?.endTime).toLocaleDateString()
              : 'N/A'}
          </span>
        </div>
      </Card>
    </SCDetailView>
  );
};

export default DetailView;
