import React from 'react';
import { faCompass, faLaptopMobile } from '@fortawesome/pro-regular-svg-icons';
import SCPartnerClientControlPanelSummary from './PartnerClientControlPanelSummary.style';
import usePartnerClient from '../../../hooks/usePartnerClient/usePartnerClient';
import MySubscriptionCard from '../PartnerClientMySubscriptionCard/MySubscriptionCard';
import ProtectionLicensesCard from '../DeviceSecurityCard/ProtectionLicensesCard';
import BreachNotificationCard from '../BreachNotificationCard/BreachNotificationCard';
import EmailSecurityCard from '../EmailSecurityCard/EmailSecurityCard';
import WebsiteSecurityCard from '../WebsiteSecurityCard/WebsiteSecurityCard';
import DomainImpersonationCard from '../DomainImpersonationCard/DomainImpersonationCard';
import SupplierSecurityCard from '../SupplierSecurityCard/SupplierSecurityCard';
import PhishingTrainingCard from '../PhishingTrainingCard/PhishingTrainingCard';
import PartnerClientControlPanelHeader from '../../molecules/PartnerClientControlPanelHeader/PartnerClientControlPanelHeader';
import usePartnerClientLicensesCounter from '../../../hooks/usePartnerClientLicensesCounter/usePartnerClientLicensesCounter';
import usePartnerClientSubscription from '../../../hooks/usePartnerClientSubscription/usePartnerClientSubscription';
import { getLicensesByType } from '../../../utils/functions/licenses';
import PurchasedLicenses from '../PurchasedLicenses/PurchasedLicenses';
import { useTranslation } from '../../../i18n';
import MailboxProtectionCard from '../MailboxProtectionCard/MailboxProtectionCard';

const PartnerClientControlPanelSummary = ({
  clientId,
  fetchPartnerClients,
  className,
}) => {
  const i18n = useTranslation();

  const {
    partnerClient,
    loading: partnerClientLoading,
    fetchPartnerClient,
  } = usePartnerClient({
    clientId,
  });
  const {
    licensesCounter,
    loading: licensesCounterLoading,
    fetchPartnerClientLicensesCounter,
  } = usePartnerClientLicensesCounter({ clientId });
  const {
    subscription,
    loading: subscriptionLoading,
    fetchPartnerClientSubscription,
  } = usePartnerClientSubscription({ clientId });

  const { licenses } = subscription;
  const { licensesSent, licensesUsed } = licensesCounter;

  const { malwareProtectionLicenses, webProtectionLicenses } =
    getLicensesByType(licenses, licensesSent, licensesUsed);

  const fetchPartnerClientsData = () => {
    fetchPartnerClients();
    fetchPartnerClient();
    fetchPartnerClientLicensesCounter();
    fetchPartnerClientSubscription();
  };

  return (
    <SCPartnerClientControlPanelSummary className={className}>
      <div className="section-header-container">
        <PartnerClientControlPanelHeader
          partnerClient={{ ...partnerClient, subscription }}
          fetchPartnerClientsData={fetchPartnerClientsData}
          loading={
            partnerClientLoading ||
            licensesCounterLoading ||
            subscriptionLoading
          }
          hasPadding
        />
      </div>

      <div className="control-panel-summary-widgets">
        <MySubscriptionCard
          subscriptionProduct={partnerClient?.status?.serviceLevelIDs[0]}
          subscriptionExpirationDate={new Date(partnerClient?.status?.active)}
          nextRenewalAt={new Date(partnerClient?.status?.nextRenewalAt)}
          nextPaymentAt={new Date(partnerClient?.status?.nextPaymentAt)}
          loading={partnerClientLoading}
        />

        <PurchasedLicenses licenses={licenses} loading={subscriptionLoading} />

        <ProtectionLicensesCard
          headerIcon={faLaptopMobile}
          headerTitle={i18n.t(
            'controlPanel.protectionLicenses.deviceProtectionTitle'
          )}
          data={{
            total: licenses?.malwareProtection,
            ...malwareProtectionLicenses,
          }}
          loading={licensesCounterLoading || subscriptionLoading}
        />

        <ProtectionLicensesCard
          headerIcon={faCompass}
          headerTitle={i18n.t(
            'controlPanel.protectionLicenses.webProtectionTitle'
          )}
          data={{
            total: licenses?.webProtection,
            ...webProtectionLicenses,
          }}
          loading={licensesCounterLoading || subscriptionLoading}
        />

        <MailboxProtectionCard
          data={{
            isProtected: partnerClient?.protection?.email,
            protectedMailboxes:
              partnerClient?.status?.protectedMailboxes?.quantity,
            totalMailboxes: licenses?.emailProtection,
            lastUpdateAt: new Date(
              partnerClient?.status?.protectedMailboxes?.updatedAt
            ),
          }}
          loading={partnerClientLoading || subscriptionLoading}
        />

        <EmailSecurityCard
          data={partnerClient?.status?.emailSecurity}
          atEmail={partnerClient?.atEmail}
          loading={partnerClientLoading}
        />

        <WebsiteSecurityCard
          data={{
            mainMonitoredDomain: partnerClient?.monitoredDomains?.[0],
            ...partnerClient?.status?.webSecurity,
          }}
          loading={partnerClientLoading}
        />

        <DomainImpersonationCard
          monitoredDomainsLength={partnerClient?.monitoredDomains?.length}
          data={partnerClient?.status?.webSpoofing}
          loading={partnerClientLoading}
        />

        <BreachNotificationCard
          data={partnerClient?.status?.dataLeaks}
          loading={partnerClientLoading}
        />

        <PhishingTrainingCard
          data={partnerClient?.status?.phishingTraining}
          loading={partnerClientLoading}
        />

        <SupplierSecurityCard
          data={partnerClient?.status?.suppliersSecurity}
          loading={partnerClientLoading}
        />
      </div>
    </SCPartnerClientControlPanelSummary>
  );
};

export default PartnerClientControlPanelSummary;
