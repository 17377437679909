export const isEmptyObject = (object) => {
  return Object.keys(object).length === 0;
};

export const tryParseJSONObject = (jsonString) => {
  try {
    const o = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === 'object') {
      return o;
    }
  } catch (e) {}

  return null;
};

export const sumObjectValues = (object = {}) =>
  Object.values(object).reduce((acc, currentValue) => acc + currentValue, 0);
