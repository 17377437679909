import styled from '@emotion/styled';

const SCLabelDetailGroup = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-between;

  .label-details {
    display: flex;
    flex-direction: column;
    gap: 25px;
    max-height: 175px;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 14px;
    }

    ::-webkit-scrollbar-thumb {
      border-width: 0px 4px 0px 4px;
      border-color: rgba(0, 0, 0, 0);
      border-style: solid;
      background-clip: padding-box;
      border-radius: 6px;
    }
  }
`;

export default SCLabelDetailGroup;
