export const SUBSCRIPTIONS_PRODUCTS = {
  DEFAULT: 'default',
  PAYING: 'paying',
  TRIAL_PROTECTION: 'trialProtection',
  TRIAL: 'trial',
};

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'ACTIVE',
  CANCELED: 'CANCELED',
  ENDED: 'ENDED',
};
