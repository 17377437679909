import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SectionLayout from '../../components/layouts/SectionLayout/SectionLayout';
import SCInstallProtectionsSection from './InstallProtectionsSection.style';
import { useTranslation } from '../../i18n';
import InfoBanner from '../../components/molecules/InfoBanner/InfoBanner';
import { finishTutorial } from '../../redux/actions/client.actions';
import InstallProtections from '../../components/organisms/InstallProtections/InstallProtections';
import { showPopUp } from '../../redux/actions/popUp.actions';
import SectionHeader from '../../components/molecules/SectionHeader/SectionHeader';
import AnyDoubts from '../../components/molecules/AnyDoubts/AnyDoubts';

const InstallProtectionsSection = () => {
  const client = useSelector((redux) => redux.client);
  const dispatch = useDispatch();
  const i18n = useTranslation();

  useEffect(() => {
    if (!client?.finishedTutorials?.deviceSecurity) {
      dispatch(showPopUp('tutorial', 'deviceSecurity'));
    }
  }, []);

  return (
    <SectionLayout>
      <SCInstallProtectionsSection>
        <div className="section-organism">
          <SectionHeader />
        </div>

        {!client?.finishedTutorials?.installProtectionsInfo && (
          <div className="section-organism">
            <InfoBanner
              type="warn"
              title={i18n.t(
                'deviceSecurity.installProtectionsInfoBanner.title'
              )}
              text={[
                i18n.t(
                  'deviceSecurity.installProtectionsInfoBanner.paragraph1'
                ),
              ]}
              onClickButton={() =>
                dispatch(finishTutorial('installProtectionsInfo'))
              }
              buttonText={i18n.t(
                'deviceSecurity.installProtectionsInfoBanner.button'
              )}
            />
          </div>
        )}

        <div className="section-organism">
          <InstallProtections />
        </div>

        <AnyDoubts hideChatButton />
      </SCInstallProtectionsSection>
    </SectionLayout>
  );
};

export default InstallProtectionsSection;
