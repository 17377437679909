import React from 'react';
import moment from 'moment';
import SCManagedThreatsSection from './ManagedThreatsSection.style';
import SectionLayout from '../../components/layouts/SectionLayout/SectionLayout';
import SectionHeader from '../../components/molecules/SectionHeader/SectionHeader';
import ThreatsNeutralizedDevices from '../../components/organisms/ThreatsNeutralizedDevices/ThreatsNeutralizedDevices';
import ThreatsNeutralizedMail from '../../components/organisms/ThreatsNeutralizedMail/ThreatsNeutralizedMail';
import ThreatsNeutralizedNavigation from '../../components/organisms/ThreatsNeutralizedNavigation/ThreatsNeutralizedNavigation';
import useManagedThreatCounters from '../../hooks/useManagedThreatCounters/useManagedThreatCounters';
import DatePickerApp from '../../components/atoms/DatePicker/DatePicker';
import InfoBanner from '../../components/molecules/InfoBanner/InfoBanner';
import { useTranslation } from '../../i18n';
import { LOCALES } from '../../utils/locale';
import useInternationalization from '../../hooks/Internationalization/useInternationalization';

const ManagedThreatsSection = () => {
  const i18n = useTranslation();
  const { filterBetweeenDates, setFilterBetweeenDates, counters } =
    useManagedThreatCounters();
  const { currentLocale } = useInternationalization();

  return (
    <SectionLayout>
      <SCManagedThreatsSection>
        <div className="section-organism">
          <SectionHeader />
        </div>

        {currentLocale !== LOCALES.es ? (
          <InfoBanner
            type="warn"
            text={[i18n.t('common.upcoming-functionality')]}
          />
        ) : (
          <>
            <div className="section-organism">
              <DatePickerApp
                isRangePicker
                defaultValue={filterBetweeenDates}
                disabledDatesAfter={moment(new Date())}
                onChange={(e) =>
                  setFilterBetweeenDates(
                    e && e.length === 2
                      ? e.map((date, index) => {
                          let auxDate = date;
                          auxDate = auxDate
                            .set('hour', index === 0 ? 0 : 23)
                            .set('minute', index === 0 ? 0 : 59)
                            .set('second', index === 0 ? 0 : 59);
                          return auxDate.toDate();
                        })
                      : []
                  )
                }
              />
            </div>

            <div className="section-organism multi-column">
              <ThreatsNeutralizedDevices counters={counters} />
              <ThreatsNeutralizedMail counters={counters} />
            </div>

            <div className="section-organism half-wide">
              <ThreatsNeutralizedNavigation counters={counters} />
            </div>
          </>
        )}
      </SCManagedThreatsSection>
    </SectionLayout>
  );
};

export default ManagedThreatsSection;
