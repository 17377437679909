import { firestore } from '../../firebase';
import { GET_SUBSCRIPTION } from './types';

const setSubscriptionListener = (clientId, subscriptionId, dispatch) => {
  firestore
    .collection('clients')
    .doc(clientId)
    .collection('subscriptions')
    .doc(subscriptionId)
    .onSnapshot((doc) => {
      if (doc.exists) {
        dispatch({
          type: GET_SUBSCRIPTION,
          payload: doc.data(),
        });
      }
    });
};

export default setSubscriptionListener;
