import styled from '@emotion/styled';

const SCPartnerClients = styled.div`
  display: grid;
  //515px es la anchura que tienen los widget en el Panel de Control de clientes.
  grid-template-columns: ${({ showFull }) =>
    showFull ? 'none' : 'auto 515px'};
  gap: 30px;

  .table-container {
    display: flex;
    height: fit-content;
    position: sticky;
    top: 97.5px;

    .input-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .selected-row {
      background-color: #e6eaeeb5;
    }

    .bold-red {
      color: var(--red);
      font-weight: 600;
    }
  }

  .ant-table-column-sorter-down.active,
  .ant-table-column-sorter-up.active {
    color: var(--partner-primary);
  }

  @media (max-width: 1400px) {
    grid-template-columns: none;

    /* h3 {
      position: static;
      top: auto;
    } */

    .table-container {
      width: 100%;
      position: static;
    }

    .partner-clients-summary {
      width: 100%;
      max-width: none;
    }
  }
`;

export default SCPartnerClients;
