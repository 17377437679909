import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SCPossibleImpersonationsSection from './possibleImpersonationsSection.style';
import SectionLayout from '../../components/layouts/SectionLayout/SectionLayout';
import { showPopUp } from '../../redux/actions/popUp.actions';
import Tool4StepsTutorial from '../../components/organisms/Tool4StepsTutorial/Tool4StepsTutorial';
import PossibleImpersonationFilterCard from '../../components/organisms/PossibleImpersonationFilterCard/PossibleImpersonationFilterCard';
import AnyDoubts from '../../components/molecules/AnyDoubts/AnyDoubts';
import SectionHeader from '../../components/molecules/SectionHeader/SectionHeader';

const PossibleImpersonationSection = () => {
  const dispatch = useDispatch();
  const client = useSelector((redux) => redux.client);

  useEffect(() => {
    if (client && !client.finishedTutorials?.impersonation) {
      dispatch(showPopUp('tutorial', 'impersonation'));
    }
  }, []);

  return (
    <SectionLayout>
      <SCPossibleImpersonationsSection>
        <div className="section-organism">
          <SectionHeader />
        </div>

        <div className="section-organism">
          <Tool4StepsTutorial />
        </div>

        <div className="section-organism">
          <PossibleImpersonationFilterCard />
        </div>

        <AnyDoubts
          tutorialOnClick={() =>
            dispatch(showPopUp('tutorial', 'impersonation'))
          }
        />
      </SCPossibleImpersonationsSection>
    </SectionLayout>
  );
};

export default PossibleImpersonationSection;
