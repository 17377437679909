import useLicenses from '../../../hooks/licenses/useLicenses';
import handleUnauthorizedAccess from '../../../utils/restrictedAccessBehaviour/restrictedAccessBehaviour';

function HasLicensesGuard({ children }) {
  const { licenses } = useLicenses();

  const allowAccess =
    licenses.malwareProtection > 0 ||
    licenses.webProtection > 0 ||
    licenses.emailProtection > 0;

  return allowAccess ? children : handleUnauthorizedAccess();
}

export default HasLicensesGuard;
