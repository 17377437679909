import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from '../../i18n';
import { showPopUp } from '../../redux/actions/popUp.actions';
import ActionTable from '../../components/organisms/ActionTable/ActionTable';
import ActionsProgress from '../../components/organisms/ActionsProgress/ActionsProgress';
import SCActionSection from './actionSection.styles';
import SectionLayout from '../../components/layouts/SectionLayout/SectionLayout';
import AnyDoubts from '../../components/molecules/AnyDoubts/AnyDoubts';
import {
  getCompletedActions,
  getIncompletedActions,
} from '../../utils/functions/actions';

const ActionSection = () => {
  const client = useSelector((redux) => redux.client);
  const completedActions = getCompletedActions(client.status.actions);
  const incompletedActions = getIncompletedActions(client.status.actions);
  const i18n = useTranslation();
  const dispatch = useDispatch();

  const [incompleteActionSearch, setIncompleteActionSearch] = useState('');
  const [completedActionSearch, setCompletedActionSearch] = useState('');

  useEffect(() => {
    if (!client?.finishedTutorials?.actions) {
      dispatch(showPopUp('tutorial', 'actions'));
    }
  }, []);

  const incompletedActionsFiltered = incompletedActions.filter((action) =>
    i18n
      .t(`action-center.actions.${action.type}.title`)
      .toLowerCase()
      .includes(incompleteActionSearch.toLowerCase())
  );

  const completedActionsFiltered = completedActions.filter((action) =>
    i18n
      .t(`action-center.actions.${action.type}.title`)
      .toLowerCase()
      .includes(completedActionSearch.toLowerCase())
  );

  return (
    <SectionLayout>
      <SCActionSection>
        <div className="section-organism">
          <ActionsProgress title={i18n.t('action-center.yourProgress')} />
        </div>

        <div className="section-organism">
          <ActionTable
            actions={incompletedActionsFiltered}
            title={i18n.t(`action-center.pendingActions`)}
            onFilter={setIncompleteActionSearch}
            actionSearch={incompleteActionSearch}
          />
        </div>

        <div className="section-organism">
          <ActionTable
            actions={completedActionsFiltered}
            title={i18n.t(`action-center.completedActions`)}
            onFilter={setCompletedActionSearch}
            actionSearch={completedActionSearch}
            completed
          />
        </div>

        <AnyDoubts
          tutorialOnClick={() => dispatch(showPopUp('tutorial', 'actions'))}
        />
      </SCActionSection>
    </SectionLayout>
  );
};

export default ActionSection;
