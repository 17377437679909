import styled from '@emotion/styled';

const SCKPICard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-between;
`;

export default SCKPICard;
