import { useSelector } from 'react-redux';

function useLicenses() {
  const subscription = useSelector((redux) => redux.subscription);
  const licensesCounter = useSelector((redux) => redux.licensesCounter);

  const { licenses = {} } = subscription;
  const {
    licensesSent = {},
    licensesUsed = {},
    licensesMemberSent = {},
    licensesMemberUsed = {},
  } = licensesCounter;

  return {
    licenses,
    licensesSent,
    licensesUsed,
    licensesMemberSent,
    licensesMemberUsed,
  };
}

export default useLicenses;
