import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from 'antd';
import ReactApexChart from 'react-apexcharts';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from '../../../i18n';
import { getDonutChartOptions } from '../../../utils/chartFactory/chartFactory';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import EmptyCardContent from '../../molecules/EmptyCardContent/EmptyCardContent';
import SCProtectionLicensesCard from './ProtectionLicensesCard.style';
import Icon from '../../atoms/Icon/Icon';

const ProtectionLicensesCard = ({
  data = {},
  headerIcon,
  headerTitle,
  isHeaderClickable,
  loading = false,
}) => {
  const i18n = useTranslation();
  const navigate = useNavigate();

  const {
    total = 0,
    used = 0,
    sentNotInstalled = 0,
    availableToSend = 0,
  } = data;

  const CHART_OPTIONS_DEVICE_PROTECTION = {
    series: [used, sentNotInstalled, availableToSend],
    options: getDonutChartOptions({
      labels: [
        i18n.t('controlPanel.widgetSubscription.licensesInstalled'),
        i18n.t('controlPanel.widgetSubscription.licensesSent'),
        i18n.t('controlPanel.widgetSubscription.lisensesAvailable'),
      ],
      colors: ['var(--green)', 'var(--light-green2)', 'var(--light-grey-3)'],
      dataLabelsColors: [
        'var(--light-grey-4)',
        'var(--light-grey-4)',
        'var(--bluish-grey)',
      ],
    }),
  };

  return (
    <SCProtectionLicensesCard>
      <SectionCard
        headerIcon={headerIcon}
        headerTitle={headerTitle}
        headerButtonOnClick={
          isHeaderClickable
            ? () => navigate('/client/device-security')
            : undefined
        }>
        {!loading ? (
          <div className="chart-date-container">
            {total > 0 ? (
              <>
                <div className="chart-alert">
                  <Icon
                    iconName={faInfoCircle}
                    color="var(--bluish-grey)"
                    size="medium"
                  />
                  <p>
                    {i18n.t(
                      'controlPanel.protectionLicenses.purchasedLicenses',
                      {
                        licenses: total,
                      }
                    )}
                  </p>
                </div>
                <ReactApexChart
                  type="donut"
                  series={CHART_OPTIONS_DEVICE_PROTECTION.series}
                  options={CHART_OPTIONS_DEVICE_PROTECTION.options}
                  width="450"
                  className="apex-chart-container"
                />
              </>
            ) : (
              <EmptyCardContent
                text={i18n.t(
                  'controlPanel.protectionLicenses.notPurchasedProtection'
                )}
              />
            )}
          </div>
        ) : (
          <Skeleton active paragraph={{ rows: 5 }} />
        )}
      </SectionCard>
    </SCProtectionLicensesCard>
  );
};

export default ProtectionLicensesCard;
