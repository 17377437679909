import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, InputNumber } from 'antd';
import styled from '@emotion/styled';
import { useTranslation } from '../../../i18n';
import Button from '../../atoms/Button/Button';
import { showPopUp } from '../../../redux/actions/popUp.actions';

import SpinnerText from '../../atoms/SpinnerText/SpinnerText';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import { REGULAR_EXPRESSIONS } from '../../../utils/constants/regularExpressions';
import { isNonCountryEnvironment } from '../../../utils/locale';
import getCompanyIDExample from '../../../utils/internationalizationModules/companyIdLabels/companyIdLabels';
import {
  isValidCompanyID,
  isValidPersonID,
} from '../../../utils/internationalizationModules/internationalizationHelper';
import useInternationalization from '../../../hooks/Internationalization/useInternationalization';

const DEFAULT_NB_LICENSES = 1;

const SCAddClientFromPartner = styled.div`
  .modal-buttons {
    margin-top: 40px;

    > .ant-form-item {
      margin-bottom: 10px;
    }
  }

  .add-client-from-partner-form-inputs {
    display: grid;
    gap: 0px 10px;
    grid-auto-flow: row;
    grid-template-areas:
      'companyName companyName companyName cif cif cif'
      'website website website atEmail atEmail atEmail'
      'firstName firstName firstName lastName lastName lastName'
      'email email email email email email'
      'malwareProtection malwareProtection webProtection webProtection emailProtection emailProtection';

    .companyName {
      grid-area: companyName;
    }

    .cif {
      grid-area: cif;
    }

    .website {
      grid-area: website;
    }

    .atEmail {
      grid-area: atEmail;
    }

    .firstName {
      grid-area: firstName;
    }

    .lastName {
      grid-area: lastName;
    }

    .email {
      grid-area: email;
    }

    .malwareProtection {
      grid-area: malwareProtection;
    }

    .webProtection {
      grid-area: webProtection;
    }

    .emailProtection {
      grid-area: emailProtection;
    }

    @media (width < 720px) {
      grid-template-areas:
        'companyName cif'
        'website website'
        'atEmail atEmail'
        'firstName lastName'
        'email email'
        'malwareProtection malwareProtection'
        'webProtection webProtection'
        'emailProtection emailProtection';
    }
  }
`;

const AddClientFromPartner = ({ handleCreateClient }) => {
  const [loading, setLoading] = useState(false);
  const { currentLocale } = useInternationalization();
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onValuesChange = (changedValues) => {
    if (typeof changedValues?.website === 'string') {
      form.setFieldValue('atEmail', changedValues?.website);
      form.validateFields(['atEmail']);
    }
  };

  const saveClient = async (formValues) => {
    setLoading(true);

    await handleCreateClient(formValues);

    setLoading(false);
  };

  const isButtonDisabled = () => {
    const fieldValues = form.getFieldsValue();

    const { malwareProtection, webProtection, emailProtection } =
      fieldValues.licenses ?? {};

    const inputsAreEmpty = Object.values(fieldValues).some((data) => !data);

    const hasError = form.getFieldsError().some(({ errors }) => errors.length);

    const hasLicenses = malwareProtection + webProtection + emailProtection > 0;

    return inputsAreEmpty || hasError || loading || !hasLicenses;
  };

  const LICENSES_INPUTS = [
    {
      label: i18n.t('partner.addClientPopUp.malwareLicenses'),
      name: ['licenses', 'malwareProtection'],
      className: 'malwareProtection',
    },
    {
      label: i18n.t('partner.addClientPopUp.webLicenses'),
      name: ['licenses', 'webProtection'],
      className: 'webProtection',
    },
    {
      label: i18n.t('partner.addClientPopUp.mailboxLicenses'),
      name: ['licenses', 'emailProtection'],
      className: 'emailProtection',
    },
  ];

  return (
    <ModalLayout>
      <SCAddClientFromPartner>
        <>
          <h1>{i18n.t('partner.table.newClient')}</h1>
          <div className="modal-card-content">
            <Form
              id="add-client-from-partner-form"
              name="add-client-from-partner-form"
              form={form}
              onFinish={saveClient}
              layout="vertical"
              onValuesChange={onValuesChange}
              initialValues={{
                licenses: {
                  malwareProtection: DEFAULT_NB_LICENSES,
                  webProtection: DEFAULT_NB_LICENSES,
                  emailProtection: DEFAULT_NB_LICENSES,
                },
              }}
              onFinishFailed={() => {
                console.log('Failed form');
              }}>
              <div className="add-client-from-partner-form-inputs">
                <Form.Item
                  label={i18n.t('partner.addClientPopUp.companyName')}
                  name="companyName"
                  className="companyName"
                  rules={[
                    {
                      required: true,
                      message: i18n.t('welcome.formErrors.common.required'),
                    },
                  ]}>
                  <Input
                    size="large"
                    type="text"
                    placeholder={i18n.t('partner.addClientPopUp.companyName')}
                  />
                </Form.Item>

                {!isNonCountryEnvironment && (
                  <Form.Item
                    label={getCompanyIDExample().name}
                    name="cif"
                    className="cif"
                    normalize={(value) => value.trim()}
                    rules={[
                      {
                        required: true,
                        message: i18n.t('welcome.formErrors.common.required'),
                      },
                      {
                        message: i18n.t(
                          `setup.company.errorCompanyID.${currentLocale}`
                        ),
                        validator: (_, value) =>
                          !value ||
                          isValidCompanyID(value, currentLocale) ||
                          isValidPersonID(value, currentLocale)
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error(
                                  i18n.t(
                                    `setup.company.errorCompanyID.${currentLocale}`
                                  )
                                )
                              ),
                      },
                    ]}>
                    <Input
                      size="large"
                      type="text"
                      placeholder={getCompanyIDExample().example}
                    />
                  </Form.Item>
                )}

                <Form.Item
                  label={i18n.t('partner.addClientPopUp.website')}
                  name="website"
                  className="website"
                  normalize={(value) => value.trim().toLowerCase()}
                  rules={[
                    {
                      required: true,
                      message: i18n.t('welcome.formErrors.common.required'),
                    },
                    {
                      message: i18n.t('setup.company.website.errorWebFormat'),
                      pattern: REGULAR_EXPRESSIONS.DOMAIN,
                    },
                    {
                      validator: (_, value) =>
                        !value.startsWith('www.')
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error(
                                i18n.t('setup.company.website.errorStartWWW')
                              )
                            ),
                    },
                  ]}>
                  <Input
                    size="large"
                    type="text"
                    addonBefore="www."
                    placeholder={i18n.t('welcome.websiteExample')}
                  />
                </Form.Item>

                <Form.Item
                  label={i18n.t('partner.addClientPopUp.atEmail')}
                  name="atEmail"
                  className="atEmail"
                  normalize={(value) => value.trim().toLowerCase()}
                  rules={[
                    {
                      required: true,
                      message: i18n.t('welcome.formErrors.common.required'),
                    },
                    {
                      message: i18n.t(
                        'welcome.formErrors.companyEmailDomain.reDoesntMatch'
                      ),
                      pattern: REGULAR_EXPRESSIONS.DOMAIN,
                    },
                    {
                      validator: (_, value) =>
                        !value.startsWith('www.')
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error(
                                i18n.t(
                                  'setup.company.website.errorStartWWWAtEmail'
                                )
                              )
                            ),
                    },
                  ]}>
                  <Input
                    size="large"
                    type="text"
                    addonBefore="@"
                    placeholder={i18n.t('welcome.websiteExample')}
                  />
                </Form.Item>

                <Form.Item
                  label={i18n.t('partner.addClientPopUp.firstName')}
                  name="firstName"
                  className="firstName"
                  rules={[
                    {
                      required: true,
                      message: i18n.t('welcome.formErrors.common.required'),
                    },
                  ]}>
                  <Input
                    size="large"
                    type="text"
                    placeholder={i18n.t('partner.addClientPopUp.firstName')}
                  />
                </Form.Item>

                <Form.Item
                  label={i18n.t('partner.addClientPopUp.lastName')}
                  name="lastName"
                  className="lastName"
                  rules={[
                    {
                      required: true,
                      message: i18n.t('welcome.formErrors.common.required'),
                    },
                  ]}>
                  <Input
                    size="large"
                    type="text"
                    placeholder={i18n.t('partner.addClientPopUp.lastName')}
                  />
                </Form.Item>

                <Form.Item
                  label={i18n.t('partner.addClientPopUp.email')}
                  name="email"
                  className="email"
                  normalize={(value) => value.trim().toLowerCase()}
                  rules={[
                    {
                      required: true,
                      message: i18n.t('welcome.formErrors.common.required'),
                    },
                    {
                      message: i18n.t('errors.wrongEmailFormatShort'),
                      pattern: REGULAR_EXPRESSIONS.EMAIL,
                    },
                    {
                      validator: (_, value) =>
                        !value.startsWith('www.')
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error(
                                i18n.t(
                                  'setup.company.website.errorStartWWWAtEmail'
                                )
                              )
                            ),
                    },
                  ]}>
                  <Input
                    size="large"
                    type="text"
                    placeholder={i18n.t(
                      'partner.addClientPopUp.emailPlaceholder'
                    )}
                  />
                </Form.Item>

                {LICENSES_INPUTS.map(({ label, name, className }) => (
                  <Form.Item
                    label={label}
                    name={name}
                    className={className}
                    rules={[
                      {
                        required: true,
                        message: i18n.t('welcome.formErrors.common.required'),
                      },
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error(
                                  i18n.t(
                                    'partner.addClientPopUp.minNbLicensesError'
                                  )
                                )
                              ),
                      },
                    ]}>
                    <InputNumber
                      min={0}
                      size="large"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                ))}
              </div>

              <div className="modal-buttons">
                <Form.Item shouldUpdate>
                  {() => (
                    <Button
                      type="submit"
                      size="full"
                      color="bluishGrey"
                      text={
                        loading ? (
                          <SpinnerText text={i18n.t('common.loading')} />
                        ) : (
                          i18n.t('partner.addClientPopUp.createClient')
                        )
                      }
                      onClick={async () => {
                        await form.validateFields();
                      }}
                      disabled={isButtonDisabled()}
                    />
                  )}
                </Form.Item>
                <Button
                  size="full"
                  color="white"
                  text={i18n.t('common.cancel')}
                  onClick={() => dispatch(showPopUp(null))}
                />
              </div>
            </Form>
          </div>
        </>
      </SCAddClientFromPartner>
    </ModalLayout>
  );
};

export default AddClientFromPartner;
