import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faCompass, faLaptopMobile } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from '../../i18n';
import Tool4StepsTutorial from '../../components/organisms/Tool4StepsTutorial/Tool4StepsTutorial';
import DevicesSummary from '../../components/organisms/DevicesSummary/DevicesSummary';
import { showPopUp } from '../../redux/actions/popUp.actions';
import SCDeviceSecuritySection from './DeviceSecuritySection.style';
import SectionLayout from '../../components/layouts/SectionLayout/SectionLayout';
import AnyDoubts from '../../components/molecules/AnyDoubts/AnyDoubts';
import SectionHeader from '../../components/molecules/SectionHeader/SectionHeader';
import ProtectionLicensesCard from '../../components/organisms/DeviceSecurityCard/ProtectionLicensesCard';
import { getLicensesByType } from '../../utils/functions/licenses';
import useLicenses from '../../hooks/licenses/useLicenses';

const DeviceSecuritySection = () => {
  const client = useSelector((redux) => redux.client);
  const dispatch = useDispatch();
  const i18n = useTranslation();

  const { licenses, licensesSent, licensesUsed } = useLicenses();
  const { malwareProtectionLicenses, webProtectionLicenses } =
    getLicensesByType(licenses, licensesSent, licensesUsed);

  useEffect(() => {
    if (!client?.finishedTutorials?.deviceSecurity) {
      dispatch(showPopUp('tutorial', 'deviceSecurity'));
    }
  }, []);

  return (
    <SectionLayout>
      <SCDeviceSecuritySection>
        <div className="section-organism">
          <SectionHeader />
        </div>

        <div className="section-organism">
          <Tool4StepsTutorial />
        </div>

        <div className="section-organism multi-column">
          <ProtectionLicensesCard
            headerIcon={faLaptopMobile}
            headerTitle={i18n.t(
              'controlPanel.protectionLicenses.deviceProtectionTitle'
            )}
            data={{
              total: licenses.malwareProtection,
              ...malwareProtectionLicenses,
            }}
          />
          <ProtectionLicensesCard
            headerIcon={faCompass}
            headerTitle={i18n.t(
              'controlPanel.protectionLicenses.webProtectionTitle'
            )}
            data={{
              total: licenses.webProtection,
              ...webProtectionLicenses,
            }}
          />
        </div>

        <div className="section-organism">
          <DevicesSummary />
        </div>

        <AnyDoubts
          tutorialOnClick={() =>
            dispatch(showPopUp('tutorial', 'deviceSecurity'))
          }
        />
      </SCDeviceSecuritySection>
    </SectionLayout>
  );
};

export default DeviceSecuritySection;
