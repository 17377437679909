import { firestore } from '../../firebase';
import { GET_LICENSES_COUNTER } from './types';

const setLicensesCounterListener = (clientId, dispatch) => {
  firestore
    .collection('licenses_counter')
    .doc(clientId)
    .onSnapshot((doc) => {
      if (doc.exists) {
        dispatch({
          type: GET_LICENSES_COUNTER,
          payload: doc.data(),
        });
      }
    });
};

export default setLicensesCounterListener;
