import { useState } from 'react';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from '../../i18n';
import * as stripeService from '../../services/stripe/stripe.service';

function useStripePayment() {
  const client = useSelector((redux) => redux.client);
  const i18n = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const createStripeSession = async (
    numberOfLicenses,
    priceId,
    taxId,
    currency,
    paymentFrequency
  ) => {
    setIsLoading(true);

    const session = await stripeService.createStripeSession(
      client.id,
      numberOfLicenses,
      i18n.getLanguage(),
      priceId,
      taxId,
      currency,
      paymentFrequency,
      client.customerId
    );

    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

    if (stripe) {
      const { error } = await stripe.redirectToCheckout({
        sessionId: session.data.id,
      });

      if (error) {
        console.log(error);
      }
    }

    setIsLoading(false);
  };

  return {
    isLoading,
    createStripeSession,
  };
}

export default useStripePayment;
