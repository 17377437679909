import { useSelector } from 'react-redux';
import { SUBSCRIPTIONS_PRODUCTS } from '../constants/subscription';
import { PARTNER_TYPES } from '../constants/partners';
import { PAYMENT_PERIODICITY } from '../constants/payments';
import { isNonCountryEnvironment } from '../locale';
import { addYearsToDate, getDiffYears } from './date';
import { isPartnerType } from './partners';

// En desuso
export const getRenewalDate = ({
  subscriptionPeriodicity,
  subscriptionExpirationDate,
  subscriptionStartedAt,
}) => {
  if (subscriptionPeriodicity === PAYMENT_PERIODICITY.NONE) {
    return undefined;
  }

  if (subscriptionPeriodicity === PAYMENT_PERIODICITY.YEARLY) {
    return subscriptionExpirationDate;
  }

  /**
   * Próxima renovación = (Fecha próxima factura - fecha inicio suscripción) + 1 año
   */
  const subscriptionDurationYears = getDiffYears(
    subscriptionExpirationDate,
    subscriptionStartedAt
  );

  return addYearsToDate(subscriptionStartedAt, subscriptionDurationYears + 1);
};

export const hasPaymentAccess = () => {
  const subscription = useSelector((redux) => redux.subscription);

  return (
    subscription?.product !== SUBSCRIPTIONS_PRODUCTS.PAYING &&
    !isNonCountryEnvironment &&
    !isPartnerType(PARTNER_TYPES.MSSP)
  );
};

export const getCurrentSubscriptionDaysLeft = (subscription) => {
  const today = new Date();
  const subscriptionEndTime = subscription?.endTime.toDate();

  const timeDiff = subscriptionEndTime?.getTime() - today.getTime();
  const diffDays = timeDiff / (1000 * 3600 * 24);
  const roundedDays = Math.ceil(diffDays);

  return roundedDays;
};
