import {
  faCircleLocationArrow,
  faCircleQuestion,
  faMessagesQuestion,
} from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useTranslation } from '../../../i18n';
import Button from '../../atoms/Button/Button';

import SCAnyDoubts from './AnyDoubts.style';

const AnyDoubts = ({ tutorialOnClick, hideChatButton = false }) => {
  const i18n = useTranslation();
  const { lang } = i18n;

  const FAQ_URLS = {
    es: 'https://cyberguardian.tawk.help/',
    en: 'https://cyberguardian.tawk.help/en-us/',
    'pt-BR': 'https://cyberguardian.tawk.help/pt-br/',
  };

  return (
    <SCAnyDoubts>
      <h3>{i18n.t('anyDoubts.title')}</h3>
      <div className="buttons-container">
        {Boolean(tutorialOnClick) && (
          <Button
            color="white"
            size="mid"
            onClick={tutorialOnClick}
            icon={faCircleLocationArrow}
            text={i18n.t('anyDoubts.backToWatchTutorial')}
          />
        )}

        <Button
          color="white"
          size="mid"
          onClick={() => window.open(FAQ_URLS[lang], '_blank')}
          icon={faCircleQuestion}
          text={i18n.t('anyDoubts.faq')}
        />

        {!hideChatButton && (
          <Button
            color="white"
            size="mid"
            onClick={() => Tawk_API.toggle()}
            icon={faMessagesQuestion}
            text={i18n.t('anyDoubts.contactSupport')}
          />
        )}
      </div>
    </SCAnyDoubts>
  );
};

export default AnyDoubts;
