import React, { useEffect, useState } from 'react';
import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from 'react-redux';
import {
  SCLicensePlanFeaturesPage,
  SCLicensePlanHeader,
} from './LicensePlanFeaturesPage.style';
import Icon from '../../components/atoms/Icon/Icon';
import Button from '../../components/atoms/Button/Button';
import { useTranslation } from '../../i18n';
import { SUBSCRIPTIONS_PRODUCTS } from '../../utils/constants/subscription';
import usePaymentAccess from '../../hooks/paymentAccess/usePaymentAccess';
import BackButton from '../../components/molecules/BackButton/BackButton';
import { hasPaymentAccess } from '../../utils/functions/subscriptions';
import { getTrialSubscription } from '../../services/subscriptions/subscription.service';

const LicensePlanHeader = ({ title, color }) => {
  return (
    <SCLicensePlanHeader color={color}>
      <p>{title}</p>
    </SCLicensePlanHeader>
  );
};

const LicensePlanCell = ({ cellData }) => {
  if (cellData === false)
    return (
      <Icon
        iconName={faCircleXmark}
        color="var(--bluish-grey)"
        size="semiLarge"
      />
    );

  if (cellData === true) {
    return (
      <Icon iconName={faCircleCheck} color="var(--red)" size="semiLarge" />
    );
  }

  return cellData;
};

const LicensePlanCard = ({ data }) => {
  return (
    <div className="license-plan-card">
      {data.header && (
        <LicensePlanHeader
          title={data.header.title}
          color={data.header.color}
        />
      )}
      <span className="top-detail">{data.topDetail}</span>
      {data.sections.map((section) => (
        <div className="license-plan-section">
          {Object.values(section).map((cellInfo, i) => (
            <span className={i === 0 && 'highlighted'}>
              <LicensePlanCell cellData={cellInfo} />
            </span>
          ))}
        </div>
      ))}
      <span />
      <p className="highlighted">{data.phishing}</p>
      {data.button && <div className="button-container">{data.button}</div>}
    </div>
  );
};

const LicensePlanFeaturesPage = () => {
  const subscription = useSelector((redux) => redux.subscription);
  const client = useSelector((redux) => redux.client);
  const i18n = useTranslation();

  const { handlePaymentAccess } = usePaymentAccess();

  const [hasHadTrialSubscription, setHasHadTrialSubscription] = useState(false);

  useEffect(() => {
    getTrialSubscription(client.id).then((trialSubscription) => {
      setHasHadTrialSubscription(Boolean(trialSubscription));
    });
  }, []);

  // A esta página solo pueden acceder licensees del Santander, así que no hacemos esa comprobación
  const hasHadSantanderTrial =
    subscription?.product === SUBSCRIPTIONS_PRODUCTS.DEFAULT &&
    hasHadTrialSubscription;

  const PlanDescriptionConfig = {
    sections: [
      {
        header: i18n.t('licensePlanFeatures.maxProtection'),
        safeDevices: i18n.t('licensePlanFeatures.safeDevices'),
        safeNavigation: i18n.t('licensePlanFeatures.safeNavigation'),
        safeMail: i18n.t('licensePlanFeatures.safeMailBox'),
      },
      {
        header: i18n.t('licensePlanFeatures.onlineSecurityDiagnostic'),
        webPage: i18n.t('licensePlanFeatures.yourWebPage'),
        mailbox: i18n.t('licensePlanFeatures.yourMailbox'),
        impersonations: i18n.t('licensePlanFeatures.possibleImpersonations'),
        breaches: i18n.t('licensePlanFeatures.possibleLeaks'),
      },
      {
        header: i18n.t('licensePlanFeatures.providerSecurity'),
      },
    ],
    phishing: i18n.t('licensePlanFeatures.employeeAwareness'),
  };

  const FreePlanConfig = {
    header: {
      title: i18n.t('licensePlanFeatures.freeSantanderTrial'),
      color: 'red',
    },
    topDetail: i18n.t('licensePlanFeatures.free7days'),
    sections: [
      {
        header: '',
        safeDevices: i18n.t('licensePlanFeatures.oneDevice'),
        safeNavigation: i18n.t('licensePlanFeatures.oneDevice'),
        safeMail: false,
      },
      {
        header: '',
        webPage: true,
        mailbox: true,
        impersonations: true,
        breaches: i18n.t('licensePlanFeatures.upTo5employees'),
      },
      {
        header: i18n.t('licensePlanFeatures.upTo2suppliers'),
      },
    ],
    phishing: i18n.t('licensePlanFeatures.phishingTrialDescription'),
    button: hasHadSantanderTrial && (
      <p>{i18n.t('licensePlanFeatures.trialFinished')}</p>
    ),
  };

  const AnualPlanConfig = {
    header: {
      title: i18n.t('licensePlanFeatures.anualSubscription'),
      color: 'fullRed',
    },
    topDetail: i18n.t('licensePlanFeatures.priceByLicense'),
    sections: [
      {
        header: '',
        safeDevices: i18n.t('licensePlanFeatures.accordingToLicenses'),
        safeNavigation: i18n.t('licensePlanFeatures.accordingToLicenses'),
        safeMail: i18n.t('licensePlanFeatures.accordingToLicenses'),
      },
      {
        header: '',
        webPage: true,
        mailbox: true,
        impersonations: true,
        breaches: i18n.t('licensePlanFeatures.accordingToLicenses'),
      },
      {
        header: i18n.t('licensePlanFeatures.upTo20suppliers'),
      },
    ],
    phishing: i18n.t('licensePlanFeatures.phishingPayingDescription'),
    button: hasPaymentAccess() && (
      <Button
        color="red"
        text={i18n.t('licensePlanFeatures.contractNow')}
        size="large"
        width="250px"
        onClick={handlePaymentAccess}
      />
    ),
  };

  return (
    <SCLicensePlanFeaturesPage>
      <div className="section-back-button">
        <BackButton />
      </div>
      <div className="license-plan-cards-container">
        <LicensePlanCard data={PlanDescriptionConfig} />
        <LicensePlanCard data={FreePlanConfig} />
        <LicensePlanCard data={AnualPlanConfig} />
      </div>
      <p className="disclaimer">*{i18n.t('licensePlanFeatures.disclaimer')}</p>
    </SCLicensePlanFeaturesPage>
  );
};

export default LicensePlanFeaturesPage;
