import { GET_LICENSES_COUNTER } from '../actions/types';

export default (state = {}, action) => {
  switch (action.type) {
    case GET_LICENSES_COUNTER:
      return action.payload || {};
    default:
      return state;
  }
};
