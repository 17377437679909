import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { InputNumber } from 'antd';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import Button from '../../atoms/Button/Button';
import { useTranslation } from '../../../i18n';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import {
  getSubscriptionStatus,
  PARTNER_CLIENT_SUBSCRIPTIONS_STATUS,
} from '../../../pages/PartnerClients/PartnerClients';
import { getDateFormat } from '../../../utils/format/dataFormat';
import {
  addLicenses,
  extendedSubscription,
} from '../../../services/subscriptions/subscription.service';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';
import { ErrorKey } from '../../../track';
import { ERRORS } from '../../../utils/constants/errors';
import { addYearsToDate } from '../../../utils/functions/date';
import { sumObjectValues } from '../../../utils/functions/objects';

const SCManageSubscription = styled.div`
  .subscription-container {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .subscription-details-container {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .subscription-detail {
        display: flex;
        gap: 10px;
        justify-content: space-between;
      }
    }

    .add-licenses-inputs-container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      > p {
        text-align: left;
      }

      .add-licenses-inputs {
        display: grid;
        grid-template-columns: 1fr 70px;
        align-items: center;
        gap: 20px;

        > p {
          text-align: left;
        }
      }
    }
  }
`;

const STEPS = {
  INITIAL_STEP: 'INITIAL_STEP',
  ADD_LICENSES: 'ADD_LICENSES',
  EXTEND_SUBSCRIPTION: 'EXTEND_SUBSCRIPTION',
};

const ManageSubscription = ({ manageSubscriptionData }) => {
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const [step, setStep] = useState(STEPS.INITIAL_STEP);
  const [licenses, setLicenses] = useState({
    malwareProtection: 0,
    webProtection: 0,
    emailProtection: 0,
  });
  const [loading, setLoading] = useState(false);

  const nbLicenses = sumObjectValues(licenses);

  const { client, fetchPartnerClientsData } = manageSubscriptionData;

  const subscriptionStatus = getSubscriptionStatus(client.status);

  const getExpiredAt = () => {
    const isSubscriptionExpired =
      subscriptionStatus === PARTNER_CLIENT_SUBSCRIPTIONS_STATUS.expired;

    return !isSubscriptionExpired
      ? getDateFormat(new Date(client.status.nextRenewalAt))
      : '-';
  };

  const subscriptionDetails = [
    {
      title: i18n.t('partner.manageSubscriptionPopUp.client'),
      value: client.company.name,
    },
    {
      title: i18n.t('partner.manageSubscriptionPopUp.subscriptionStatus'),
      value: i18n.t(`partner.table.body.${subscriptionStatus}`),
    },
    {
      title: i18n.t('partner.manageSubscriptionPopUp.malwareLicenses'),
      value: client?.subscription?.licenses?.malwareProtection || '-',
    },
    {
      title: i18n.t('partner.manageSubscriptionPopUp.webLicenses'),
      value: client?.subscription?.licenses?.webProtection || '-',
    },
    {
      title: i18n.t('partner.manageSubscriptionPopUp.mailboxLicenses'),
      value: client?.subscription?.licenses?.emailProtection || '-',
    },
    {
      title: i18n.t('controlPanel.widgetSubscription.subscriptionPeriod'),
      value: getExpiredAt(),
    },
  ];

  const getExtendSubscriptionDate = () => {
    const activeSubscriptionDate = new Date(client.status.active);
    const extendSubscriptionDate = addYearsToDate(activeSubscriptionDate, 1);

    return getDateFormat(extendSubscriptionDate);
  };

  const getAddLicensesButtonText = () => {
    if (loading) {
      return <SpinnerText text={i18n.t('common.loading')} />;
    }

    if (!nbLicenses) {
      return i18n.t('partner.manageSubscriptionPopUp.addLicenses');
    }

    if (nbLicenses === 1) {
      return i18n.t('partner.manageSubscriptionPopUp.addOneLicense');
    }

    return i18n.t('partner.manageSubscriptionPopUp.addSomeLicenses', {
      nbLicenses,
    });
  };

  const handleAddLicenses = async () => {
    setLoading(true);

    try {
      await addLicenses({ clientId: client.id, licenses });
      dispatch(showPopUp(null));
      fetchPartnerClientsData();
    } catch (error) {
      ErrorKey('errors.not_found');
    }

    setLoading(false);
  };

  const handleExtendSubscription = async () => {
    setLoading(true);

    try {
      await extendedSubscription({ clientId: client.id });
      dispatch(showPopUp(null));
      fetchPartnerClientsData();
    } catch (error) {
      const { data: errorCode } = error.response;

      const i18nKey =
        errorCode === ERRORS.ALREADY_HAS_RENEWAL
          ? 'errors.clientAlreadyHasRenewal'
          : 'errors.not_found';

      ErrorKey(i18nKey);
    }

    setLoading(false);
  };

  const onChangeLicensesInput = (key, value) => {
    setLicenses((prev) => ({
      ...prev,
      [key]: value ?? 0,
    }));
  };

  const LICENSES_INPUTS = [
    {
      name: i18n.t('partner.manageSubscriptionPopUp.malwareLicenses'),
      key: 'malwareProtection',
    },
    {
      name: i18n.t('partner.manageSubscriptionPopUp.webLicenses'),
      key: 'webProtection',
    },
    {
      name: i18n.t('partner.manageSubscriptionPopUp.mailboxLicenses'),
      key: 'emailProtection',
    },
  ];

  return (
    <ModalLayout>
      <SCManageSubscription>
        <h1>{i18n.t('partner.manageSubscriptionPopUp.manageSubscription')}</h1>

        <div className="modal-card-content subscription-container">
          <div className="subscription-details-container">
            {subscriptionDetails.map(({ title, value }) => (
              <div className="subscription-detail">
                <p>
                  <strong>{title}</strong>
                </p>

                <p>{value}</p>
              </div>
            ))}
          </div>

          {step === STEPS.ADD_LICENSES && (
            <div className="add-licenses-inputs-container">
              <p>
                <strong>
                  {i18n.t('partner.manageSubscriptionPopUp.addLicenses')}
                </strong>
              </p>
              {LICENSES_INPUTS.map(({ name, key }) => (
                <div className="add-licenses-inputs">
                  <p>{name}</p>
                  <InputNumber
                    value={licenses[key]}
                    min={0}
                    size="large"
                    onChange={(value) => onChangeLicensesInput(key, value)}
                    style={{ width: '100%' }}
                  />
                </div>
              ))}
            </div>
          )}

          {step === STEPS.EXTEND_SUBSCRIPTION && (
            <p>
              {i18n.t(
                'partner.manageSubscriptionPopUp.extendSubscriptionConfirmation',
                {
                  extendSubscriptionDate: getExtendSubscriptionDate(),
                }
              )}
            </p>
          )}
        </div>

        <div className="modal-card-buttons">
          {step === STEPS.INITIAL_STEP && (
            <>
              <Button
                size="full"
                color="bluishGrey"
                text={i18n.t('partner.manageSubscriptionPopUp.addLicenses')}
                onClick={() => {
                  setStep(STEPS.ADD_LICENSES);
                }}
              />

              {subscriptionStatus ===
                PARTNER_CLIENT_SUBSCRIPTIONS_STATUS.paying && (
                <Button
                  size="full"
                  color="bluishGrey"
                  text={i18n.t(
                    'partner.manageSubscriptionPopUp.extendSubscription'
                  )}
                  onClick={() => {
                    setStep(STEPS.EXTEND_SUBSCRIPTION);
                  }}
                />
              )}
            </>
          )}

          {step === STEPS.ADD_LICENSES && (
            <Button
              size="full"
              color="bluishGrey"
              text={getAddLicensesButtonText()}
              onClick={handleAddLicenses}
              disabled={nbLicenses <= 0 || loading}
            />
          )}

          {step === STEPS.EXTEND_SUBSCRIPTION && (
            <Button
              size="full"
              color="bluishGrey"
              text={
                loading ? (
                  <SpinnerText text={i18n.t('common.loading')} />
                ) : (
                  i18n.t('common.confirm')
                )
              }
              onClick={handleExtendSubscription}
              disabled={loading}
            />
          )}

          <Button
            size="full"
            color="white"
            text={
              step === STEPS.INITIAL_STEP
                ? i18n.t('common.close')
                : i18n.t('common.back')
            }
            onClick={() => {
              if (step === STEPS.INITIAL_STEP) {
                dispatch(showPopUp(null));
              } else {
                setStep(STEPS.INITIAL_STEP);
              }
            }}
          />
        </div>
      </SCManageSubscription>
    </ModalLayout>
  );
};

export default ManageSubscription;
