import { PROTECTION_TYPES } from '../constants/licenses';

export const getLicensesByType = (
  licenses = {},
  licensesSent = {},
  licensesUsed = {}
) => {
  const calculateTotalLicenses = (licensesArray) =>
    licensesArray.reduce(
      (total, { malwareProtection, webProtection }) => ({
        malwareProtection: total.malwareProtection + malwareProtection,
        webProtection: total.webProtection + webProtection,
      }),
      { malwareProtection: 0, webProtection: 0 }
    );

  const calculateAvailableLicenses = (type) => {
    const totalSentLicenses = calculateTotalLicenses(
      Object.values(licensesSent)
    );
    const totalUsedLicenses = calculateTotalLicenses(
      Object.values(licensesUsed)
    );

    const total = licenses[type];
    const sent = totalSentLicenses[type];
    const used = totalUsedLicenses[type];

    return {
      sent,
      used,
      sentNotInstalled: sent - used,
      availableToSend: total - sent,
    };
  };

  return {
    malwareProtectionLicenses: calculateAvailableLicenses(
      PROTECTION_TYPES.MALWARE
    ),
    webProtectionLicenses: calculateAvailableLicenses(PROTECTION_TYPES.WEB),
  };
};

export const getTeamMemberTotalLicenses = (licenses = {}) => {
  const licensesArray = Object.values(licenses);

  const totalLicenses = licensesArray.reduce(
    (total, { malwareProtection = 0, webProtection = 0 }) =>
      total + malwareProtection + webProtection,
    0
  );

  return totalLicenses;
};
