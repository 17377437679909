import styled from '@emotion/styled';

const SCMySubscriptionCard = styled.div`
  .subscription-card-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`;

export default SCMySubscriptionCard;
