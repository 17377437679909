import { firestore } from '../../firebase';

const getTeamMemberInstallationTokens = async ({ clientId, teamMemberId }) => {
  const installationTokens = [];

  const installationTokenDocs = await firestore
    .collection('clients')
    .doc(clientId)
    .collection('installation_tokens')
    .where('teamMemberId', '==', teamMemberId)
    .where('isRevoked', '==', false)
    .get();

  installationTokenDocs.forEach((doc) => {
    installationTokens.push(doc.data());
  });

  return installationTokens;
};

export default { getTeamMemberInstallationTokens };
