import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faServer } from '@fortawesome/pro-regular-svg-icons';
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import useLicenses from '../../../hooks/licenses/useLicenses';
import { useTranslation } from '../../../i18n';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import Button from '../../atoms/Button/Button';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import { getLicensesByType } from '../../../utils/functions/licenses';
import QuantityInput from '../../atoms/QuantityInput/QuantityInput';
import { PROTECTION_TYPES } from '../../../utils/constants/licenses';
import deviceProtectionService from '../../../services/deviceProtection/deviceProtection.service';
import Label from '../../atoms/Label/Label';

const SCSendProtectionsEmails = styled.div`
  h2 {
    text-align: center;
    color: var(--black);
    font-family: var(--font1);
    font-size: 25px;
    font-weight: 500;
  }

  .modal-card-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    font-family: var(--font2);
    text-align: left;

    .available-licenses-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    table {
      width: 100%;
      font-size: 16px;

      th,
      td {
        padding-inline: 10px;
      }

      th {
        font-weight: 500;
        text-align: center;
        padding-block: 10px;
      }

      td {
        padding-block: 20px;
      }

      tr > td:first-of-type {
        padding-left: 0;
      }
      tr > td:last-of-type {
        padding-right: 0;
      }

      .send-protections-select {
        width: 100%;

        .ant-select-selector {
          border-radius: 5px !important;
          font-size: 16px !important;
        }
      }
    }

    .add-devices-button {
      outline: none;
      text-align: left;
      background: none;
      padding-block: 5px;
      transition: opacity 0.1s;

      :hover {
        opacity: 0.8;
      }

      :disabled {
        opacity: 0.5;
        cursor: auto;
      }
    }
  }
`;

const LicenseTypeLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .device-icon {
    width: 16px;
  }
`;

const LICENSES_TYPES = {
  DESKTOP_LICENSES: 'desktopLicenses',
  SERVER_LICENSES: 'serverLicenses',
  ANDROID_LICENSES: 'androidLicenses',
  IOS_LICENSES: 'iosLicenses',
};

const LICENSES_TYPES_ORDER = [
  LICENSES_TYPES.DESKTOP_LICENSES,
  LICENSES_TYPES.SERVER_LICENSES,
  LICENSES_TYPES.ANDROID_LICENSES,
  LICENSES_TYPES.IOS_LICENSES,
];

const PROTECTIONS = {
  MALWARE: 'MALWARE',
  WEB: 'WEB',
  FULL: 'FULL',
};

const UPDATE_OPERATIONS = {
  ADD: 'ADD',
  REDUCE: 'REDUCE',
};

const DEFERRED_LIMIT = 100;

const getProtectionSelectValue = (malwareProtection, webProtection) => {
  if (malwareProtection && webProtection) {
    return PROTECTIONS.FULL;
  }

  if (malwareProtection) {
    return PROTECTIONS.MALWARE;
  }

  return PROTECTIONS.WEB;
};

const getProtectionFromProtectionType = (protectionType) => {
  return {
    quantity: 0,
    malwareProtection: [PROTECTIONS.FULL, PROTECTIONS.MALWARE].includes(
      protectionType
    ),
    webProtection: [PROTECTIONS.FULL, PROTECTIONS.WEB].includes(protectionType),
  };
};

const SendProtectionsEmails = ({ selectedTeamMembersEmails }) => {
  const i18n = useTranslation();
  const dispatch = useDispatch();

  const [step, setStep] = useState(0);
  const [sendingEmails, setSendingEmails] = useState(false);

  const { licenses, licensesSent, licensesUsed } = useLicenses();

  const { malwareProtectionLicenses, webProtectionLicenses } =
    getLicensesByType(licenses, licensesSent, licensesUsed);

  const malwareAvailableToSend = malwareProtectionLicenses.availableToSend;
  const webAvailableToSend = webProtectionLicenses.availableToSend;

  const getLicenseDefaultState = (licenseType) => {
    const isDesktop = licenseType === LICENSES_TYPES.DESKTOP_LICENSES;

    return {
      quantity: 0,
      malwareProtection: malwareAvailableToSend > 0,
      webProtection: isDesktop ? webAvailableToSend > 0 : false,
    };
  };

  const [nLicenses, setNLicenses] = useState({
    desktopLicenses: getLicenseDefaultState(LICENSES_TYPES.DESKTOP_LICENSES),
  });

  const getTotalLicensesToSendByProtectionType = (protectionType) => {
    return Object.values(nLicenses)
      .filter((protection) => protection[protectionType])
      .reduce((accumulator, { quantity }) => accumulator + quantity, 0);
  };

  const numTeamMembers = selectedTeamMembersEmails.length;

  const selectedMalwareLicenses = getTotalLicensesToSendByProtectionType(
    PROTECTION_TYPES.MALWARE
  );
  const selectedWebLicenses = getTotalLicensesToSendByProtectionType(
    PROTECTION_TYPES.WEB
  );

  const totalSelectedMalwareLicenses = selectedMalwareLicenses * numTeamMembers;
  const totalSelectedWebLicenses = selectedWebLicenses * numTeamMembers;

  const currentMalwareAvailableToSend =
    malwareAvailableToSend - totalSelectedMalwareLicenses;
  const currentWebAvailableToSend =
    webAvailableToSend - totalSelectedWebLicenses;

  const deferred =
    totalSelectedMalwareLicenses + totalSelectedWebLicenses > DEFERRED_LIMIT;

  const updateLicensesQuantity = (licenseType, operation) => {
    setNLicenses((prevLicenses) => {
      const newLicenses = { ...prevLicenses };

      if (operation === UPDATE_OPERATIONS.ADD) {
        newLicenses[licenseType].quantity += 1;
      } else if (operation === UPDATE_OPERATIONS.REDUCE) {
        newLicenses[licenseType].quantity = Math.max(
          newLicenses[licenseType].quantity - 1,
          0
        );
      }

      return newLicenses;
    });
  };

  const handleQuantityInput = (e, licenseType) => {
    const inputLicenses = e.target.valueAsNumber || 0;
    const { malwareProtection, webProtection } = nLicenses[licenseType];

    const malwareLimit =
      currentMalwareAvailableToSend + nLicenses[licenseType].quantity;
    const webLimit =
      currentWebAvailableToSend + nLicenses[licenseType].quantity;
    const totalLicensesToSend = inputLicenses * numTeamMembers;

    // Puede que haya que comprobar malware y web, sólo malware o sólo web.
    const allowOperation =
      (malwareProtection &&
        webProtection &&
        totalLicensesToSend <= malwareLimit &&
        totalLicensesToSend <= webLimit) ||
      (malwareProtection &&
        !webProtection &&
        totalLicensesToSend <= malwareLimit) ||
      (webProtection && !malwareProtection && totalLicensesToSend <= webLimit);

    if (allowOperation) {
      setNLicenses((prevState) => ({
        ...prevState,
        [licenseType]: {
          ...prevState[licenseType],
          quantity: inputLicenses,
        },
      }));
    }
  };

  const handleSendingEmails = async () => {
    setSendingEmails(true);

    const protectionsToSend = selectedTeamMembersEmails.map((email) => ({
      email,
      ...nLicenses,
    }));

    await deviceProtectionService
      .sendLicenses({ recipients: protectionsToSend, deferred })
      .then(() => {
        // Todo correcto, step 2.
        setStep(2);
      })
      .catch((err) => {
        // Algún error, step 3.
        console.log(err);
        setStep(3);
      });

    setSendingEmails(false);
  };

  const onLicensesTypeChange = (newLicenseType, prevLicenseType) => {
    const clone = Object.fromEntries(
      Object.entries(nLicenses).map(([key, val]) => {
        if (key === prevLicenseType)
          return [newLicenseType, getLicenseDefaultState(newLicenseType)];
        return [key, val];
      })
    );
    setNLicenses(clone);
  };

  const onProtectionsChange = (licenseType) => (protectionType) => {
    setNLicenses({
      ...nLicenses,
      [licenseType]: getProtectionFromProtectionType(protectionType),
    });
  };

  const onClickAddDevice = () => {
    const newLicenseType = LICENSES_TYPES_ORDER.find(
      (licenseType) => !nLicenses[licenseType]
    );
    setNLicenses((prev) => ({
      ...prev,
      [newLicenseType]: getLicenseDefaultState(newLicenseType),
    }));
  };

  const isQuantityInputDisabled = (licenseType) => {
    const { malwareProtection, webProtection } = nLicenses[licenseType];
    let isDisabledMalwareProtection = false;
    let isDisabledWebProtection = false;

    if (malwareProtection) {
      isDisabledMalwareProtection =
        totalSelectedMalwareLicenses + numTeamMembers > malwareAvailableToSend;
    }

    if (webProtection) {
      isDisabledWebProtection =
        totalSelectedWebLicenses + numTeamMembers > webAvailableToSend;
    }

    return isDisabledMalwareProtection || isDisabledWebProtection;
  };

  const getLicensesTypeSelectOptions = (licenseType) => {
    const defaultOptions = [
      {
        value: LICENSES_TYPES.DESKTOP_LICENSES,
        label: (
          <LicenseTypeLabel>
            <FontAwesomeIcon icon={faDesktop} className="device-icon" />
            {i18n.t('deviceSecurity.sentLicensesbyEmployee.desktop')}
          </LicenseTypeLabel>
        ),
      },
      {
        value: LICENSES_TYPES.SERVER_LICENSES,
        label: (
          <LicenseTypeLabel>
            <FontAwesomeIcon icon={faServer} className="device-icon" />
            {i18n.t('deviceSecurity.sentLicensesbyEmployee.server')}
          </LicenseTypeLabel>
        ),
      },
      {
        value: LICENSES_TYPES.ANDROID_LICENSES,
        label: (
          <LicenseTypeLabel>
            <FontAwesomeIcon icon={faAndroid} className="device-icon" />
            {i18n.t('deviceSecurity.sentLicensesbyEmployee.android')}
          </LicenseTypeLabel>
        ),
      },
      {
        value: LICENSES_TYPES.IOS_LICENSES,
        label: (
          <LicenseTypeLabel>
            <FontAwesomeIcon icon={faApple} className="device-icon" />
            {i18n.t('deviceSecurity.sentLicensesbyEmployee.ios')}
          </LicenseTypeLabel>
        ),
      },
    ];

    return defaultOptions.filter(({ value }) => {
      // If the current license type matches the required license type, include it
      if (value === licenseType) {
        return true;
      }

      // Exclude non-desktop licenses if `webAvailableToSend` is zero
      if (
        value !== LICENSES_TYPES.DESKTOP_LICENSES &&
        malwareAvailableToSend === 0
      ) {
        return false;
      }

      // Include if no license count is available for the type, otherwise exclude it
      return !nLicenses[value];
    });
  };

  const getProtectionSelectOptions = () => {
    const protectionSelectOptions = [];

    if (malwareAvailableToSend > 0) {
      protectionSelectOptions.push({
        value: PROTECTIONS.MALWARE,
        label: i18n.t('deviceSecurity.licensesSentByEmployeeModal.malware'),
      });
    }

    if (webAvailableToSend > 0) {
      protectionSelectOptions.push({
        value: PROTECTIONS.WEB,
        label: i18n.t('deviceSecurity.licensesSentByEmployeeModal.web'),
      });
    }

    if (webAvailableToSend > 0 && malwareAvailableToSend > 0) {
      protectionSelectOptions.push({
        value: PROTECTIONS.FULL,
        label: i18n.t('deviceSecurity.licensesSentByEmployeeModal.full'),
      });
    }

    return protectionSelectOptions;
  };

  const STEPS_CONTENT = {
    0: (
      <>
        <h2>{i18n.t('deviceSecurity.selectEmailsModal.sendLicenses')}</h2>

        <div className="modal-card-content">
          <p className="install-protections-text">
            {i18n.t(
              numTeamMembers > 1
                ? 'deviceSecurity.selectEmailsModal.sendLicensesInfo'
                : 'deviceSecurity.selectEmailsModal.sendLicensesInfoSingle',
              {
                selectedEmployees: numTeamMembers,
              }
            )}
          </p>
          <div className="available-licenses-info">
            <p>
              {i18n.t(
                'deviceSecurity.selectEmailsModal.availableMalwareLicenses'
              )}
            </p>
            <Label
              value={currentMalwareAvailableToSend || '0'}
              color={currentMalwareAvailableToSend === 0 ? 'red' : 'lightGrey'}
            />
          </div>
          <div className="available-licenses-info">
            <p>
              {i18n.t('deviceSecurity.selectEmailsModal.availableWebLicenses')}
            </p>
            <Label
              value={currentWebAvailableToSend || '0'}
              color={currentWebAvailableToSend === 0 ? 'red' : 'lightGrey'}
            />
          </div>

          <div>
            <table>
              <thead>
                <tr>
                  <th>
                    {i18n.t('deviceSecurity.selectEmailsModal.deviceType')}
                  </th>
                  <th>
                    {i18n.t('deviceSecurity.selectEmailsModal.protection')}
                  </th>
                  <th>{i18n.t('deviceSecurity.selectEmailsModal.licenses')}</th>
                </tr>
              </thead>

              <tbody>
                {Object.entries(nLicenses).map(([licenseType, protection]) => {
                  return (
                    <tr>
                      <td>
                        <Select
                          value={licenseType}
                          onChange={(value) =>
                            onLicensesTypeChange(value, licenseType)
                          }
                          className="send-protections-select"
                          options={getLicensesTypeSelectOptions(licenseType)}
                        />
                      </td>
                      <td>
                        <Select
                          value={getProtectionSelectValue(
                            protection.malwareProtection,
                            protection.webProtection
                          )}
                          onChange={onProtectionsChange(licenseType)}
                          className="send-protections-select"
                          options={getProtectionSelectOptions()}
                          disabled={
                            licenseType !== LICENSES_TYPES.DESKTOP_LICENSES
                          }
                        />
                      </td>
                      <td>
                        <QuantityInput
                          value={nLicenses[licenseType].quantity}
                          onAdd={() => {
                            updateLicensesQuantity(
                              licenseType,
                              UPDATE_OPERATIONS.ADD
                            );
                          }}
                          onInputChange={(event) => {
                            handleQuantityInput(event, licenseType);
                          }}
                          onReduce={() => {
                            updateLicensesQuantity(
                              licenseType,
                              UPDATE_OPERATIONS.REDUCE
                            );
                          }}
                          disabled={isQuantityInputDisabled(licenseType)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <button
              type="button"
              className="add-devices-button"
              aria-label="Add other device types"
              onClick={onClickAddDevice}
              disabled={
                Object.keys(nLicenses).length === 4 ||
                malwareAvailableToSend === 0
              }>
              + {i18n.t('deviceSecurity.selectEmailsModal.otherDeviceTypes')}
            </button>
          </div>
        </div>

        <div className="modal-card-buttons">
          <Button
            onClick={() => {
              setStep(1);
            }}
            text={i18n.t('deviceSecurity.selectEmailsModal.sendLicenses')}
            size="full"
            color="bluishGrey"
            disabled={
              totalSelectedMalwareLicenses + totalSelectedWebLicenses <= 0
            }
          />

          <Button
            onClick={() => dispatch(showPopUp(null))}
            text={i18n.t('common.cancel')}
            size="full"
            color="white"
          />
        </div>
      </>
    ),
    1: (
      <>
        <h2>{i18n.t('deviceSecurity.selectEmailsModal.confirmSent')}</h2>
        <div className="modal-card-content">
          <p>
            {i18n.t('deviceSecurity.selectEmailsModal.confirmSentParagraph1')}
          </p>
          <p>
            {i18n.t('deviceSecurity.selectEmailsModal.confirmSentParagraph2')}
          </p>
        </div>

        <div className="modal-card-buttons">
          <Button
            onClick={() => handleSendingEmails()}
            disabled={sendingEmails}
            text={
              sendingEmails
                ? i18n.t('deviceSecurity.selectEmailsModal.sending')
                : i18n.t('deviceSecurity.selectEmailsModal.send')
            }
            size="full"
            color="bluishGrey"
          />
          <Button
            onClick={() => {
              setStep(0);
            }}
            text={i18n.t('common.goBack')}
            size="full"
            color="white"
          />
        </div>
      </>
    ),
    2: (
      <>
        <h2>
          {deferred
            ? i18n.t('common.processingRequest')
            : i18n.t('common.processedRequest')}
        </h2>

        <div className="modal-card-content">
          <p>
            {deferred
              ? i18n.t('deviceSecurity.selectEmailsModal.processingRequestText')
              : i18n.t('deviceSecurity.selectEmailsModal.processedRequestText')}
          </p>
        </div>

        <div className="modal-card-buttons">
          <Button
            onClick={() => {
              dispatch(showPopUp(null));
            }}
            text={i18n.t('deviceSecurity.selectEmailsModal.confirm')}
            size="full"
            color="bluishGrey"
          />
        </div>
      </>
    ),
    3: (
      <>
        <h2>{i18n.t('common.error')}</h2>
        <div className="modal-card-content">
          <p>{i18n.t('deviceSecurity.selectEmailsModal.errorParagraph')}</p>
        </div>

        <div className="modal-card-buttons">
          <Button
            onClick={() => {
              dispatch(showPopUp(null));
              Tawk_API.toggle();
            }}
            text={i18n.t('deviceSecurity.selectEmailsModal.contactUs')}
            size="full"
            color="bluishGrey"
          />

          <Button
            onClick={() => {
              dispatch(showPopUp(null));
            }}
            text={i18n.t('common.close')}
            size="full"
            color="white"
          />
        </div>
      </>
    ),
  };

  return (
    <ModalLayout>
      <SCSendProtectionsEmails>{STEPS_CONTENT[step]}</SCSendProtectionsEmails>
    </ModalLayout>
  );
};

export default SendProtectionsEmails;
