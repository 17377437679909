import React from 'react';
import { useSelector } from 'react-redux';
import { faCompass, faLaptopMobile } from '@fortawesome/pro-regular-svg-icons';
import SCControlPanelSection from './ControlPanelSection.style';
import SectionLayout from '../../components/layouts/SectionLayout/SectionLayout';
import MySubscriptionCard from '../../components/organisms/PartnerClientMySubscriptionCard/MySubscriptionCard';
import LICENSEES from '../../utils/constants/licensees';
import ProtectionLicensesCard from '../../components/organisms/DeviceSecurityCard/ProtectionLicensesCard';
import EmailSecurityCard from '../../components/organisms/EmailSecurityCard/EmailSecurityCard';
import WebsiteSecurityCard from '../../components/organisms/WebsiteSecurityCard/WebsiteSecurityCard';
import DomainImpersonationCard from '../../components/organisms/DomainImpersonationCard/DomainImpersonationCard';
import SupplierSecurityCard from '../../components/organisms/SupplierSecurityCard/SupplierSecurityCard';
import PhishingTrainingCard from '../../components/organisms/PhishingTrainingCard/PhishingTrainingCard';
import { hasPaymentAccess } from '../../utils/functions/subscriptions';
import PurchasedLicenses from '../../components/organisms/PurchasedLicenses/PurchasedLicenses';
import useLicenses from '../../hooks/licenses/useLicenses';
import { useTranslation } from '../../i18n';
import BreachNotificationCard from '../../components/organisms/BreachNotificationCard/BreachNotificationCard';
import { getLicensesByType } from '../../utils/functions/licenses';
import MailboxProtectionCard from '../../components/organisms/MailboxProtectionCard/MailboxProtectionCard';

const ControlPanelSection = () => {
  const client = useSelector((redux) => redux.client);
  const i18n = useTranslation();
  const { licenses, licensesSent, licensesUsed } = useLicenses();

  const { malwareProtectionLicenses, webProtectionLicenses } =
    getLicensesByType(licenses, licensesSent, licensesUsed);

  return (
    <SectionLayout>
      <SCControlPanelSection>
        <div className="section-organism multi-column">
          <MySubscriptionCard
            subscriptionProduct={client?.status?.serviceLevelIDs[0]}
            subscriptionExpirationDate={client?.status?.active.toDate()}
            nextRenewalAt={client?.status?.nextRenewalAt?.toDate()}
            nextPaymentAt={client?.status?.nextPaymentAt?.toDate()}
            showLinkToPlans={client.licensee === LICENSEES.santander}
            isHeaderClickable
            showInfoBanner={hasPaymentAccess()}
          />
          <PurchasedLicenses licenses={licenses} isHeaderClickable />
        </div>

        <div className="section-organism multi-column">
          <ProtectionLicensesCard
            headerIcon={faLaptopMobile}
            headerTitle={i18n.t(
              'controlPanel.protectionLicenses.deviceProtectionTitle'
            )}
            data={{
              total: licenses.malwareProtection,
              ...malwareProtectionLicenses,
            }}
            isHeaderClickable
          />
          <ProtectionLicensesCard
            headerIcon={faCompass}
            headerTitle={i18n.t(
              'controlPanel.protectionLicenses.webProtectionTitle'
            )}
            data={{
              total: licenses.webProtection,
              ...webProtectionLicenses,
            }}
            isHeaderClickable
          />
        </div>

        <div className="section-organism multi-column">
          <MailboxProtectionCard
            data={{
              isProtected: client?.protection?.email,
              protectedMailboxes: client?.status?.protectedMailboxes?.quantity,
              totalMailboxes: licenses?.emailProtection,
              lastUpdateAt:
                client?.status?.protectedMailboxes?.updatedAt?.toDate(),
            }}
            isHeaderClickable
          />
          <EmailSecurityCard
            data={client?.status?.emailSecurity}
            atEmail={client?.atEmail}
            isHeaderClickable
          />
        </div>

        <div className="section-organism multi-column">
          <WebsiteSecurityCard
            data={{
              mainMonitoredDomain: client?.monitoredDomains?.[0],
              ...client?.status?.webSecurity,
            }}
            isHeaderClickable
          />
          <DomainImpersonationCard
            monitoredDomainsLength={client?.monitoredDomains?.length}
            data={client?.status?.webSpoofing}
            isHeaderClickable
          />
        </div>

        <div className="section-organism multi-column">
          <BreachNotificationCard
            data={client?.status?.dataLeaks}
            isHeaderClickable
          />

          <PhishingTrainingCard
            data={client?.status?.phishingTraining}
            isHeaderClickable
          />
        </div>

        <div className="section-organism half-wide">
          <SupplierSecurityCard
            data={client?.status?.suppliersSecurity}
            isHeaderClickable
            showEmptyCardButton
          />
        </div>
      </SCControlPanelSection>
    </SectionLayout>
  );
};

export default ControlPanelSection;
