import React from 'react';
import {
  faHeartPulse,
  faLaptop,
  faRectangleTerminal,
} from '@fortawesome/pro-regular-svg-icons';
import { faRadiation } from '@fortawesome/pro-solid-svg-icons';

import ThreatsNeutralized from '../../molecules/ThreatsNeutralized/ThreatsNeutralized';
import { COUNTER_TYPES } from '../../../hooks/useManagedThreatCounters/useManagedThreatCounters';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import { getNumberFormat } from '../../../utils/format/dataFormat';
import useLicenses from '../../../hooks/licenses/useLicenses';
import { isPartnerType } from '../../../utils/functions/partners';
import { PARTNER_TYPES } from '../../../utils/constants/partners';
import EmptyCardContent from '../../molecules/EmptyCardContent/EmptyCardContent';

const ThreatsNeutralizedNavigation = ({ counters }) => {
  const i18n = useTranslation();
  const { webProtection = 0 } = useLicenses().licenses;
  const isPartnerMSSP = isPartnerType(PARTNER_TYPES.MSSP);

  const THREATS_NEUTRALIZED_NAVIGATION = [
    {
      iconName: faRectangleTerminal,
      amount: counters[COUNTER_TYPES.WEB_PROTECTION_CONNECTIONS_ANALYZED],
      description: i18n.t(
        'managed-threats.widgets.navegation-threats-neutralized.labels.webAnalyzed'
      ),
    },
    {
      iconName: faHeartPulse,
      amount: counters[COUNTER_TYPES.WEB_PROTECTION_TOTAL_EVENTS],
      description: i18n.t(
        'managed-threats.widgets.navegation-threats-neutralized.labels.webEventsAnalyzed'
      ),
    },
    {
      iconName: faRadiation,
      amount: counters[COUNTER_TYPES.WEB_PROTECTION_CONNECTIONS_BLOCKED],
      description: i18n.t(
        'managed-threats.widgets.navegation-threats-neutralized.labels.webThreatsAnalyzed'
      ),
    },
  ];

  return (
    <SectionCard
      headerTitle={i18n.t(
        'managed-threats.widgets.navegation-threats-neutralized.title'
      )}
      headerIcon={faLaptop}>
      {webProtection > 0 && (
        <ThreatsNeutralized
          subtitle={i18n.t(
            'managed-threats.widgets.navegation-threats-neutralized.subtitle',
            {
              amount: getNumberFormat(
                counters[COUNTER_TYPES.WEB_PROTECTION_CONNECTIONS_BLOCKED] ?? 0
              ),
            }
          )}
          threatsCounters={THREATS_NEUTRALIZED_NAVIGATION}
        />
      )}

      {webProtection === 0 && isPartnerMSSP && (
        <EmptyCardContent text={i18n.t('common.noLicensesContactProvider')} />
      )}

      {webProtection === 0 && !isPartnerMSSP && (
        <EmptyCardContent
          text={i18n.t('common.noLicensesContactUs')}
          buttonText={i18n.t('common.contact')}
          buttonOnClick={() => Tawk_API.toggle()}
        />
      )}
    </SectionCard>
  );
};

export default ThreatsNeutralizedNavigation;
