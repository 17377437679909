import styled from '@emotion/styled';

const SCMailboxExtendLicensesWarning = styled.div`
  display: flex;
  gap: 10px;
  color: var(--red);

  > p {
    color: inherit;

    > button {
      background-color: transparent;
      text-decoration: underline;
      font-weight: inherit;
    }
  }
`;

export default SCMailboxExtendLicensesWarning;
