import React from 'react';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import SCMailboxExtendLicensesWarning from './MailboxExtendLicensesWarning.style';
import Icon from '../../atoms/Icon/Icon';
import { useTranslation } from '../../../i18n';

const MailboxExtendLicensesWarning = () => {
  const i18n = useTranslation();

  return (
    <SCMailboxExtendLicensesWarning>
      <Icon iconName={faExclamationCircle} size="medium" />

      <p
        dangerouslySetInnerHTML={{
          __html: i18n.t('mailbox-security.contactUs'),
        }}
      />
    </SCMailboxExtendLicensesWarning>
  );
};

export default MailboxExtendLicensesWarning;
