import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faEnvelope, faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';

import { Select } from 'antd';
import Button from '../../atoms/Button/Button';
import SCInstallProtections from './InstallProtections.style';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import { useTranslation } from '../../../i18n';
import useFilterTable from '../../../hooks/useFilterTable/useFilterTable';
import TableContainer from '../../molecules/TableContainer/TableContainer';
import TagList from '../../molecules/TagList/TagList';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import Icon from '../../atoms/Icon/Icon';
import useLicenses from '../../../hooks/licenses/useLicenses';
import { getTeamMemberTotalLicenses } from '../../../utils/functions/licenses';
import Label from '../../atoms/Label/Label';

const DEVICE_TYPES = {
  DESKTOP: 'desktop',
  SERVER: 'server',
  ANDROID: 'android',
  IOS: 'ios',
};

const InstallProtections = () => {
  const teamMembers = useSelector((redux) => redux.teamMembers);
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const { licensesMemberSent, licensesMemberUsed } = useLicenses();
  const [deviceFilter, setDeviceFilter] = useState([]);
  const [selectedTeamMembersEmails, setSelectedTeamMembersEmails] = useState(
    []
  );

  const filteredTeamMembers =
    deviceFilter.length > 0
      ? teamMembers?.filter(({ hashedEmail }) => {
          const sentLicenses = licensesMemberSent?.[hashedEmail] ?? {};

          return deviceFilter.some((deviceFilterType) => {
            const license = sentLicenses[deviceFilterType] ?? {};

            return license?.malwareProtection > 0 || license?.webProtection > 0;
          });
        })
      : teamMembers;

  const mapperFn = (item) => {
    const sentLicenses = getTeamMemberTotalLicenses(
      licensesMemberSent?.[item.hashedEmail]
    );
    const usedLicenses = getTeamMemberTotalLicenses(
      licensesMemberUsed?.[item.hashedEmail]
    );
    const sentNotInstalledLicenses = sentLicenses - usedLicenses;

    return {
      email: {
        type: 'string',
        value: item.email,
        sortValue: item.email,
      },
      sentLicenses: {
        type: 'component',
        value: <Label value={sentLicenses || '0'} width="100px" />,
        sortValue: sentLicenses,
      },
      usedLicenses: {
        type: 'component',
        value: <Label value={usedLicenses || '0'} width="100px" />,
        sortValue: usedLicenses,
      },
      sentNotInstalledLicenses: {
        type: 'component',
        value: (
          <Label
            value={sentNotInstalledLicenses || '0'}
            width="100px"
            color={sentNotInstalledLicenses ? 'red' : undefined}
          />
        ),
        sortValue: sentNotInstalledLicenses,
      },
      role: {
        type: 'component',
        value: <TagList teamMember={item} cell />,
      },
      onClick: sentNotInstalledLicenses
        ? () => {
            dispatch(
              showPopUp('licensesSentbyEmployee', {
                hashedEmail: item.hashedEmail,
                email: item.email,
              })
            );
          }
        : undefined,
    };
  };

  const PAGE_SIZE = 10;

  const defaultSortDirection = 'desc';
  const sortOptions = {
    activeSortedColumnName: i18n.t(
      'deviceSecurity.selectEmailsModal.sentLicenses'
    ),
    activeSortedColumnDirection: defaultSortDirection,
    activeSortedColumnIndex: 1,
    onSort: (data) => {
      setSort(data);
    },
  };

  const { setSort, setSearch, setSearchTags, rows, length } = useFilterTable(
    PAGE_SIZE,
    mapperFn,
    filteredTeamMembers,
    ['email'],
    sortOptions
  );

  const columns = [
    {
      name: i18n.t('deviceSecurity.selectEmailsModal.email'),
      options: {
        stickyOnScroll: true,
        stickyOffset: 40,
        ofuscateFS: true,
      },
    },
    {
      name: i18n.t('deviceSecurity.selectEmailsModal.sentLicenses'),
    },
    {
      name: i18n.t('deviceSecurity.selectEmailsModal.usedLicenses'),
    },
    {
      name: i18n.t('deviceSecurity.selectEmailsModal.sentNotInstalledLicenses'),
    },
    {
      name: i18n.t(`manageEmailList.tags`),
      options: {
        avoidSort: true,
      },
    },
  ];

  return (
    <SCInstallProtections>
      <SectionCard
        headerIcon={faUserPlus}
        headerTitle={i18n.t('deviceSecurity.selectEmailsModal.chooseUsers')}>
        <TableContainer
          onChangeValue={setSearch}
          columns={columns}
          rows={rows}
          emptyMessage={i18n.t('manageEmailList.emptyMessage')}
          onChangeFilterTag={setSearchTags}
          hasHorizontalScroll
          totalCount={length}
          pageSize={PAGE_SIZE}
          sortOptions={sortOptions}
          hasRowSelect
          rowIdentifier="email"
          rowHasBeenSelected={(selectedRows) => {
            setSelectedTeamMembersEmails(
              selectedRows.map((row) => row.email.value)
            );
          }}
          customBottomElements={
            <div className="selected-row-actions">
              <div className="selected-row-actions-info">
                <Icon iconName={faCircleInfo} color="var(--bluish-grey)" />

                <p>
                  {selectedTeamMembersEmails.length === 0
                    ? i18n.t('deviceSecurity.selectEmployees')
                    : i18n.t('deviceSecurity.selectedEmployees', {
                        numberOfUsers: `${selectedTeamMembersEmails.length}`,
                      })}
                </p>
              </div>

              {selectedTeamMembersEmails.length > 0 && (
                <Button
                  onClick={() =>
                    dispatch(
                      showPopUp('installProtections', selectedTeamMembersEmails)
                    )
                  }
                  icon={faEnvelope}
                  text={i18n.t('deviceSecurity.selectEmailsModal.sendLicenses')}
                  color="white"
                  size="customMid"
                />
              )}
            </div>
          }
          customSideElements={
            <Select
              mode="multiple"
              allowClear
              style={{ flex: 1, minWidth: '225px' }}
              placeholder={i18n.t(
                'deviceSecurity.sentLicensesbyEmployee.filterPlaceholder'
              )}
              defaultValue={[]}
              onChange={setDeviceFilter}
              options={[
                {
                  value: DEVICE_TYPES.DESKTOP,
                  label: i18n.t(
                    'deviceSecurity.sentLicensesbyEmployee.desktop'
                  ),
                },
                {
                  value: DEVICE_TYPES.SERVER,
                  label: i18n.t('deviceSecurity.sentLicensesbyEmployee.server'),
                },
                {
                  value: DEVICE_TYPES.ANDROID,
                  label: i18n.t(
                    'deviceSecurity.sentLicensesbyEmployee.android'
                  ),
                },
                {
                  value: DEVICE_TYPES.IOS,
                  label: i18n.t('deviceSecurity.sentLicensesbyEmployee.ios'),
                },
              ]}
            />
          }
        />
      </SectionCard>
    </SCInstallProtections>
  );
};
export default InstallProtections;
