import React from 'react';
import { useDispatch } from 'react-redux';
import {
  faChartColumn,
  faPowerOff,
  faUserGroup,
} from '@fortawesome/pro-regular-svg-icons';
import { Outlet, Link, useLocation, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as CyberGuardianLogo } from '../../../img/cyberguardian/cyberguardian-logo-white.svg';
import { ReactComponent as CyberGuardianLogoSimple } from '../../../img/cyberguardian/cyberGuardianWhiteSimple.svg';
import SCPartnerLayout from './PartnerLayout.style';
import ContactLegalInformation from '../../molecules/ContactLegalInformation/ContactLegalInformation';
import { signOut } from '../../../redux/actions/auth.actions';
import { useTranslation } from '../../../i18n';

const PartnerLayout = () => {
  const dispatch = useDispatch();
  const i18n = useTranslation();
  const location = useLocation();
  const params = useParams();
  const { pathname } = location;

  const basePath = Object.values(params).reduce(
    (path, param) => path.replace(`/${param}`, ''),
    pathname
  );

  const PAGES_CONFIG = {
    '/partner': {
      to: '/partner',
      title: 'Dashboard',
      icon: faChartColumn,
      isStickyHeader: true,
    },
    '/partner/clients': {
      title: i18n.t('common.clients'),
      to: '/partner/clients',
      icon: faUserGroup,
      isStickyHeader: true,
    },
    '/partner/clients/control-panel': {
      title: i18n.t('common.clients'),
      icon: faUserGroup,
      size: 'var(--section-width-xl)',
    },
  };

  const { isStickyHeader, size } = PAGES_CONFIG[basePath];

  const isCurrentRoute = (route) =>
    pathname.split('/', 3).toString() === route.split('/').toString();

  return (
    <SCPartnerLayout isStickyHeader={isStickyHeader} size={size}>
      <div className="page-content">
        <div className="partner-menu">
          <Link className="logo-container" to="/partner">
            <CyberGuardianLogo className="cyberGuardian-logo" />
            <CyberGuardianLogoSimple className="cyberGuardian-simple-logo" />
            <h3>Partners</h3>
          </Link>

          <div className="navigation-container">
            {Object.values(PAGES_CONFIG).map(
              (route) =>
                route.to && (
                  <Link
                    className={`partner-menu-button${
                      isCurrentRoute(route.to) ? ' selected' : ''
                    }`}
                    to={route.to}>
                    <FontAwesomeIcon
                      icon={route.icon}
                      fontSize="18px"
                      color={
                        isCurrentRoute(route.to)
                          ? 'var(--partner-primary)'
                          : 'inherit'
                      }
                    />
                    <span>{route.title}</span>
                  </Link>
                )
            )}
          </div>

          <button
            className="sign-out-button"
            type="button"
            onClick={() => {
              dispatch(signOut());
            }}>
            <FontAwesomeIcon icon={faPowerOff} />
            <span>{i18n.t('common.signOut')}</span>
          </button>
        </div>

        <div className="partner-dashboard">
          {PAGES_CONFIG[pathname]?.title && (
            <header>
              <div className="header-container">
                <h3>{PAGES_CONFIG[pathname]?.title}</h3>
              </div>
            </header>
          )}

          <div className="dashboard-wrapper">
            <div className="dashboard-content">
              <Outlet />
            </div>
          </div>

          <ContactLegalInformation
            color="transparent"
            size="sectionXL"
            separator
          />
        </div>
      </div>
    </SCPartnerLayout>
  );
};

export default PartnerLayout;
