import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Skeleton } from 'antd';
import {
  faCirclePlus,
  faMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons';
import SkeletonButton from 'antd/es/skeleton/Button';
import { useDispatch } from 'react-redux';
import Label from '../../components/atoms/Label/Label';
import { getDateFormat } from '../../utils/format/dataFormat';
import {
  getSecurityColor,
  getSecurityText,
} from '../../utils/functions/securitySeverity';
import { useTranslation } from '../../i18n';
import SCPartnerClients from './PartnerClients.style';
import { SUBSCRIPTIONS_PRODUCTS } from '../../utils/constants/subscription';
import usePartnerClients from '../../hooks/usePartnerClients/usePartnerClients';
import PartnerClientControlPanelSummary from '../../components/organisms/PartnerClientControlPanelSummary/PartnerClientControlPanelSummary';
import SectionCard from '../../components/molecules/SectionCard/SectionCard';
import Input from '../../components/atoms/Input/Input';
import getCompanyIDExample from '../../utils/internationalizationModules/companyIdLabels/companyIdLabels';
import { isNonCountryEnvironment } from '../../utils/locale';
import Button from '../../components/atoms/Button/Button';
import { isPartnerType } from '../../utils/functions/partners';
import { PARTNER_TYPES } from '../../utils/constants/partners';
import { showPopUp } from '../../redux/actions/popUp.actions';
import partnerClientsService from '../../services/partnerClients/partnerClients.service';
import { ERRORS } from '../../utils/constants/errors';
import { ErrorKey } from '../../track';

export const PARTNER_CLIENT_SUBSCRIPTIONS_STATUS = {
  expired: 'EXPIRED',
  paying: 'ACTIVE',
  trial: 'TRIAL',
};

export const getSubscriptionStatus = (partnerClientStatus) => {
  if (
    partnerClientStatus.active < new Date() ||
    partnerClientStatus.serviceLevelIDs[0] === SUBSCRIPTIONS_PRODUCTS.DEFAULT
  ) {
    return PARTNER_CLIENT_SUBSCRIPTIONS_STATUS.expired;
  }

  return (
    PARTNER_CLIENT_SUBSCRIPTIONS_STATUS[
      partnerClientStatus.serviceLevelIDs[0]
    ] ?? PARTNER_CLIENT_SUBSCRIPTIONS_STATUS.expired
  );
};

const PartnerClients = () => {
  const navigate = useNavigate();
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const { partnerClients, loading, fetchPartnerClients } = usePartnerClients();
  const urlParams = new URLSearchParams(window.location.search);
  const clientIdFromUrl = urlParams.get('id');
  const [inputFilter, setInputFilter] = useState();

  const orderedPartnerClients = partnerClients.sort(
    (a, b) =>
      b.subscription.licenses.malwareProtection -
      a.subscription.licenses.malwareProtection
  );

  const filteredPartnerClients = inputFilter
    ? orderedPartnerClients.filter(({ company }) =>
        [company.name, company.cif].some((item) =>
          item.toLowerCase().includes(inputFilter.toLowerCase())
        )
      )
    : orderedPartnerClients;

  /**
   * Siempre debe haber un clientIdFromUrl. Cuando se renderice la página debemos mostrar el panel
   * del primer clientIdFromUrl de la tabla.
   * 'clientIdFromUrl' debe estar en las dependencias por si, estando ya en la vista 'Clientes', se
   * pulsa el botón 'Clientes' del menú de navegación.
   */
  useEffect(() => {
    if (orderedPartnerClients.length > 0 && !clientIdFromUrl) {
      navigate(`?id=${orderedPartnerClients[0].id}`);
    }
  }, [orderedPartnerClients.length, clientIdFromUrl]);

  const getValueLabel = (score) => {
    if (!score) {
      return i18n.t('websiteSecurity.unknown');
    }

    const formattedScore = Math.round(score * 10) / 10;
    const securityText = getSecurityText(score);

    return `${i18n.t(`websiteSecurity.${securityText}`)} (${formattedScore})`;
  };

  const sortDirections = ['ascend', 'descend', 'ascend'];

  const columns = [
    {
      title: i18n.t('partner.table.headers.name'),
      key: 'companyName',
      dataIndex: 'companyName',
      sortDirections,
      sorter: (
        { companyName: aCompanyName },
        { companyName: bCompanyName }
      ) => {
        return aCompanyName.localeCompare(bCompanyName);
      },
      render: (text) => text || '-',
    },
    // Si es un entorno de país, se introduce la columna 'companyCif'
    ...(!isNonCountryEnvironment
      ? [
          {
            title: getCompanyIDExample().name,
            key: 'companyCif',
            dataIndex: 'companyCif',
            sortDirections,
            sorter: (
              { companyCif: aCompanyCif },
              { companyCif: bCompanyCif }
            ) => {
              return aCompanyCif.localeCompare(bCompanyCif);
            },
            render: (text) => text || '-',
          },
        ]
      : []),
    {
      title: i18n.t('partner.table.headers.score'),
      key: 'score',
      dataIndex: 'score',
      width: 132,
      sortDirections,
      sorter: (a, b) => a.score - b.score,
      render: (text) => (
        <Label value={getValueLabel(text)} color={getSecurityColor(text)} />
      ),
    },
    {
      title: i18n.t('partner.table.headers.subscriptionStatus'),
      key: 'subscriptionStatus',
      dataIndex: 'subscriptionStatus',
      sortDirections,
      sorter: (
        { subscriptionStatus: subscriptionStatusA },
        { subscriptionStatus: subscriptionStatusB }
      ) => {
        const subscriptionStatusAText =
          getSubscriptionStatus(subscriptionStatusA);
        const subscriptionStatusBText =
          getSubscriptionStatus(subscriptionStatusB);

        return i18n
          .t(`partner.table.body.${subscriptionStatusAText}`)
          .localeCompare(
            i18n.t(`partner.table.body.${subscriptionStatusBText}`)
          );
      },
      render: (text) => {
        const subscriptionStatus = getSubscriptionStatus(text);

        const isSubscriptionExpired =
          subscriptionStatus === PARTNER_CLIENT_SUBSCRIPTIONS_STATUS.expired;

        return (
          <p
            style={{
              fontSize: '14px',
              color: isSubscriptionExpired && 'var(--red)',
              fontWeight: isSubscriptionExpired && '600',
            }}>
            {i18n.t(`partner.table.body.${subscriptionStatus}`)}
          </p>
        );
      },
    },
    {
      title: i18n.t('profile.billingHistory.malwareLicenses'),
      key: 'malwareLicenses',
      dataIndex: 'malwareLicenses',
      sortDirections,
      sorter: (a, b) => a.malwareLicenses - b.malwareLicenses,
    },
    {
      title: i18n.t('profile.billingHistory.webLicenses'),
      key: 'webLicenses',
      dataIndex: 'webLicenses',
      sortDirections,
      sorter: (a, b) => a.webLicenses - b.webLicenses,
    },
    {
      title: i18n.t('profile.billingHistory.mailboxLicenses'),
      key: 'mailboxLicenses',
      dataIndex: 'mailboxLicenses',
      sortDirections,
      sorter: (a, b) => a.mailboxLicenses - b.mailboxLicenses,
    },
    {
      title: i18n.t('controlPanel.widgetSubscription.subscriptionPeriod'),
      key: 'expiredAt',
      dataIndex: 'expiredAt',
      sortDirections,
      sorter: (
        { subscriptionStatus: subscriptionStatusA },
        { subscriptionStatus: subscriptionStatusB }
      ) => {
        const subscriptionStatusAText =
          getSubscriptionStatus(subscriptionStatusA);
        const subscriptionStatusBText =
          getSubscriptionStatus(subscriptionStatusB);

        const subscriptionStatusATimestamp =
          subscriptionStatusAText ===
          PARTNER_CLIENT_SUBSCRIPTIONS_STATUS.expired
            ? 0
            : subscriptionStatusA.active;
        const subscriptionStatusBTimestamp =
          subscriptionStatusBText ===
          PARTNER_CLIENT_SUBSCRIPTIONS_STATUS.expired
            ? 0
            : subscriptionStatusB.active;

        return subscriptionStatusATimestamp - subscriptionStatusBTimestamp;
      },
      render: (text, record) => {
        const subscriptionStatus = getSubscriptionStatus(
          record.subscriptionStatus
        );

        const isSubscriptionExpired =
          subscriptionStatus === PARTNER_CLIENT_SUBSCRIPTIONS_STATUS.expired;

        return !isSubscriptionExpired ? getDateFormat(new Date(text)) : '-';
      },
    },
  ];

  const data = filteredPartnerClients.map(
    ({ id, company, scoring, status, subscription }) => ({
      id,
      companyName: company.name,
      companyCif: company.cif,
      score: scoring.lastValue,
      subscriptionStatus: status,
      malwareLicenses: subscription.licenses.malwareProtection,
      webLicenses: subscription.licenses.webProtection,
      mailboxLicenses: subscription.licenses.emailProtection,
      expiredAt: status.nextRenewalAt,
    })
  );

  const handleInputFilter = (inputValue) => {
    setInputFilter(inputValue);
  };

  const getRowClassName = ({ id }) => {
    return `${id === clientIdFromUrl ? 'selected-row' : ''} cursor-pointer`;
  };

  const handleCreateClient = async (formValues) => {
    try {
      await partnerClientsService.addClientFromPartner(formValues);
      dispatch(showPopUp(null));
      fetchPartnerClients();
    } catch (error) {
      const { data: errorCode } = error.response;

      // invalid_domain = cuando el website o el atEmail no coinciden con la regexp
      // invalid_email = cuando el email no coincide con la regexp
      // client_already_exist = si ya existe un cliente con ese CIF
      // member_already_exist = si ya existe un team member con ese email
      const i18nKeys = {
        [ERRORS.INVALID_DOMAIN]: 'websiteSecurity.add.errorDomainFormat',
        [ERRORS.INVALID_EMAIL]: 'errors.wrongEmailFormatShort',
        [ERRORS.CLIENT_ALREADY_EXIST]: 'setup.company.genericError',
        [ERRORS.MEMBER_ALREADY_EXIST]: 'errors.memberAlreadyExist',
      };

      const i18nKey = i18nKeys[errorCode] ?? 'errors.not_found';

      ErrorKey(i18nKey);
    }
  };

  const displayControlPanel = loading || partnerClients.length > 0;
  const isPartnerMSSP = isPartnerType(PARTNER_TYPES.MSSP);

  return (
    <SCPartnerClients showFull={!displayControlPanel}>
      <SectionCard className="table-container">
        <div className="input-container">
          {loading ? (
            <SkeletonButton active style={{ width: '210px' }} />
          ) : (
            <>
              <Input
                inputType="text"
                inputPlaceholder={`${i18n.t('common.search')}...`}
                onChangeValue={handleInputFilter}
                icon={faMagnifyingGlass}
                size="short"
                design="round"
              />
              {isPartnerMSSP && (
                <Button
                  icon={faCirclePlus}
                  text={i18n.t('partner.table.newClient')}
                  color="bluishGrey"
                  size="small"
                  onClick={() =>
                    dispatch(
                      showPopUp('addClientFromPartner', handleCreateClient)
                    )
                  }
                />
              )}
            </>
          )}
        </div>

        <Table
          pagination={false}
          scroll={{ y: 560, x: 950 }}
          onRow={(record) => {
            return {
              onClick: () => navigate(`?id=${record.id}`),
            };
          }}
          rowClassName={getRowClassName}
          columns={columns}
          dataSource={data}
          locale={{
            ...(loading && {
              emptyText: <Skeleton active />,
            }),
          }}
        />
      </SectionCard>

      {displayControlPanel && (
        <PartnerClientControlPanelSummary
          clientId={clientIdFromUrl}
          fetchPartnerClients={fetchPartnerClients}
          className="partner-clients-summary"
        />
      )}
    </SCPartnerClients>
  );
};

export default PartnerClients;
